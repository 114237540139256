import { useNavigate } from "react-router-dom";
import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const {
      setAlert,
      setIsLoading,
      setUserData,
      setAdminData,
      setAdminPageCount,
   } = useStoreSelector([
      "setAlert",
      "setIsLoading",
      "setUserData",
      "setAdminData",
      "setAdminPageCount",
   ]);
   const login = async (
      email: string,
      password: string,
      setThePassword: Function
   ) => {
      if (!email) {
         return setAlert({
            type: "error",
            title: "Missing field",
            message: "Please input a valid email",
            confirmName: "Ok",
         } as AppAlertData);
      }
      if (!password) {
         return setAlert({
            type: "error",
            title: "Missing field",
            message: "Please input a valid password",
            confirmName: "Ok",
         } as AppAlertData);
      }
      setThePassword((e: any) => ({ ...e, isLoading: true }));
      let sendRequest = await helpers
         .sendRequest({
            url: APIendPoint.admin.login,
            method: "POST",
            json: { email, password },
         })
         .catch((e) => e);

      setThePassword((e: any) => ({ ...e, isLoading: false }));
      if (sendRequest) {
         if (sendRequest.status_code === 201) {
            typeof setThePassword === "function" &&
               setThePassword({ status: true, data: {}, isLoading: false });
            return;
         } else if (sendRequest.status_code === 200) {
            setUserData(sendRequest.data.data);
            localStorage.setItem("__mtoken", sendRequest.data.data.token);
            localStorage.setItem("__mauth_id", sendRequest.data.data.auth_id);
            navigate("/dashboard");
         } else {
            helpers.processAPIError(sendRequest, setAlert);
            return;
         }
      }
   };

   const getprofile = async () => {
      let token = localStorage.getItem("__mtoken");
      let authId = localStorage.getItem("__mauth_id");
      if (!token) {
         navigate("/");
         return;
      }
      let sendRequest = await helpers
         .sendRequest({
            url: APIendPoint.admin.profile + "/operations/admin-user/" + authId,
            method: "GET",
            token: token,
         })
         .catch((e) => e);
      // console.log(sendRequest)
      if (sendRequest && sendRequest.status_code !== 200) {
         localStorage.removeItem("__mtoken");
         navigate("/");
         return;
         // return helpers.showErrorMessage(sendRequest);
      }
      setUserData({ ...sendRequest.data.data, token: token });
   };

   const fetchAdminList = async (page = 1) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/admin-user?page=${page}&item_per_page=10`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }

      setAdminData(sendRequest.data?.data);
   };

   const fetchAdminCount = async (
      page = 1,
      startDate = "",
      endDate = "",
      q = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/admin-user?component=count&page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }

      //  console.log(sendRequest.data);
      setAdminPageCount(sendRequest.data.data);
   };

   type DropdownItems = {
      name?: string;
   };

   const createAdmin = async (
      data: {
         name: string;
         email?: string;
         phoneNumber?: string;
         gender?: string;
      },
      role?: Array<DropdownItems>,
      onPress?: Function
   ) => {
      if (!data?.name) {
         return helpers.showToast("error", "admin name is required");
      }
      if (!data?.email) {
         return helpers.showToast("error", "admin email is required");
      }
      if (!data?.phoneNumber) {
         return helpers.showToast("error", "admin mobile is required");
      }
      if (!data?.gender) {
         return helpers.showToast("error", "admin gender is required");
      }
      if (role?.length === 0) {
         return helpers.showToast(
            "error",
            "please select a privillage for this user"
         );
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/admin-user`,
            method: "POST",
            json: {
               name: data?.name,
               email: data?.email,
               phone_number: data?.phoneNumber,
               gender: data?.gender,
               role_list: role,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      fetchAdminList();
      helpers.showToast("success", "Admin was created successful");
      onPress && onPress();
   };
   const updateAdmin = async (
      data: {
         name: string;
         email?: string;
         phoneNumber?: string;
         gender?: string;
      },
      role?: Array<DropdownItems>,
      onPress?: Function,
      adminID?: string
   ) => {
      if (!data?.name) {
         return helpers.showToast("error", "admin name is required");
      }
      if (!data?.email) {
         return helpers.showToast("error", "admin email is required");
      }
      if (!data?.phoneNumber) {
         return helpers.showToast("error", "admin mobile is required");
      }
      if (!data?.gender) {
         return helpers.showToast("error", "admin gender is required");
      }
      if (role?.length === 0) {
         return helpers.showToast(
            "error",
            "please select a privillage for this user"
         );
      }
      //  return;
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/admin-user/${adminID}`,
            method: "PUT",
            json: {
               name: data?.name,
               email: data?.email,
               phone_number: data?.phoneNumber,
               gender: data?.gender,
               role_list: role,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      fetchAdminList();
      helpers.showToast("success", "Admin was updated successful");
      onPress && onPress();
   };
   const deleteAdmin = async (adminID?: string, onPress?: Function) => {
      //  return;
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/admin-user/${adminID}`,
            method: "DELETE",
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      fetchAdminList();
      helpers.showToast("success", "Admin was deleted successful");
      onPress && onPress();
   };

   const resetNewPassword = async (
      email: string,
      password: string,
      confirmPassword: string,
      onPress?: Function
   ) => {
      if (!email) {
         return helpers.showToast("error", "email was not captured");
      }
      if (!password) {
         return helpers.showToast("error", "password is required");
      }
      if (confirmPassword != password) {
         return helpers.showToast(
            "error",
            "password and confirm password do not match"
         );
      }

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.pwdReset}/reset-password`,
            method: "POST",
            json: {
               email: email,
               password: password,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      helpers.showToast("success", "Password was changed successfully");
      onPress && onPress();
   };
   return {
      login,
      getprofile,
      fetchAdminList,
      fetchAdminCount,
      createAdmin,
      resetNewPassword,
      updateAdmin,
      deleteAdmin,
   };
};
