import { KeyValueObject } from "../typings/general";

export const Status = {
  live: "live",
  Upcoming: "Upcoming",
  Completed: "Completed",
  Cancelled: "Cancelled",
};

export const SessionStatus: KeyValueObject[] = [
  { name: "live", value: Status.live },
  { name: "Upcoming", value: Status.Upcoming },
  { name: "Completed", value: Status.Completed },
  { name: "Cancelled", value: Status.Cancelled },
];

export const SessionProfileSubValues = {
  Session: "Session Details",
  Assessment: "Assessment",
  Treatment: "Treatment Plan",
  Tools: "Tools",
  ProgressNote: "Note",
};

export const SessionProfileSubScreen: KeyValueObject[] = [
  { name: "Session Details", value: SessionProfileSubValues.Session },
  { name: "Assessment", value: SessionProfileSubValues.Assessment },
  { name: "Treatment Plan", value: SessionProfileSubValues.Treatment },
  { name: "Progress Note", value: SessionProfileSubValues.ProgressNote },
  { name: "Tools", value: SessionProfileSubValues.Tools },
];
