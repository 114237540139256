import react from 'react'
import AppText from "./AppText"

interface CardProps {
   cardTitle: string,
   cardNumber: number | string
}

export default (props: CardProps) => {
   return (
      <div className="col-2 col-17">
         <div className='dash-card'>
            <div className='line09'>
               <p className='line05'><AppText fontSize={'13px'} color="#8F9BB3" fontWeight="400">{props.cardTitle}</AppText></p>
               <p><AppText fontSize={'20px'} color="#222B45" fontWeight="700">{props.cardNumber}</AppText></p>
            </div>
         </div>
      </div>
   )
}