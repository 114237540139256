import { Routes, RouteObject } from "react-router-dom"
import MemberIndex from "../pages/members/MemberIndex"
import MemberDetails from "../pages/members/MemberDetails"



export const MemberRoute: RouteObject = {
   path: "/members",
   children: [
      {
         path: "",
         element: <MemberIndex type="individual" />
      },
      {
         path: "business",
         element: <MemberIndex type="business" />
      },
      {
         path: "/members/:ID",
         element: <MemberDetails />
      },
   ]
}