import { useState, useEffect } from "react"
import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import { DoctorAppointment } from "../../constants/mockData";
import { useNavigate, useParams } from "react-router-dom";
import helpers from "../../utils/helpers";
import useProviderFunction from "../../hooks/useProviderFunction";
import { useStoreSelector } from "../../store/useStore";
import SessionModal from "../../components/SessionModal";
import Pagination from "../../components/Pagination";
import { ObjectPayload } from "../../typings/general";

export default (props: any) => {
   console.log(props)
   const Navigate = useNavigate()
   const params = useParams();
   let accountType = props?.accountType ? props?.accountType : 'individual'
   const [activePage, setActivePage] = useState(1)
   const [sessionModal, setSessionModal] = useState<ObjectPayload>({ status: false, data: {} })
   const { fetchSingleProviderSession, fetchProviderSessionCount } = useProviderFunction()
   const { singleProviderSession, isLoading, singleProviderSessionCount } = useStoreSelector(["isLoading", "singleProviderSession", "singleProviderSessionCount"])
   useEffect(() => {
      fetchSingleProviderSession(params?.ID as any, accountType)
      fetchProviderSessionCount(params?.ID as any, accountType)
   }, [])
   return (
      <div>
         <div className="bg-white">
            <div className="myTableHeight">
               <AppTable data={singleProviderSession} isLoading={isLoading} headers={["Patient", "Date", "Time", "Mode of Comm.", "Booking ID", "Action"]} tableTitle="">
                  {(singleProviderSession || []).map((item: any, i: any) => (
                     <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => Navigate(`/sessions/${item?.booking_id}`)}>
                        <td className="tdclass4 tableGrid">{item?.member_data?.name}</td>
                        <td className="tdclass1">{new Date(item.createdAt).toDateString()}</td>
                        <td className="tdclass1">{helpers.get24HourTo12Hours((item.createdAt))}</td>
                        <td className="tdclass3">{item.comm_mode}</td>
                        <td className="tdclass4">{item?.booking_ref}</td>
                        <td className="tdclass3">{<span className={`marginBottom profileActive ${item?.status === 1 ? "userActive" : item?.status === 2 ? "userReview" : "userPending"}`}>{item?.status === 1 ? "Active" : item?.status === 2 ? "Suspended" : "Pending"}</span>}</td>
                     </tr>
                  ))}
               </AppTable>
            </div>
            <Pagination activePage={activePage} totalPage={singleProviderSessionCount?.total} onPress={(nextPage) => {
               fetchProviderSessionCount(params?.ID as any, accountType, sessionModal?.data?.status, nextPage)
               setActivePage(nextPage)
            }} />
         </div>
         {sessionModal.status &&
            <SessionModal data={sessionModal.data} onPress={() => setSessionModal({ status: false, data: {} })} />
         }
      </div>
   )
}