import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const {
      setIsLoading,
      setSessionData,
      setSessionPageCount,
      setSingleSessionData,
      setSingleSessionAssData,
      setSingleSessionTools,
      setSingleSessionTreatment,
      setSingleSessionNote,
   } = useStoreSelector([
      "setIsLoading",
      "setSessionData",
      "setSessionPageCount",
      "setSingleSessionData",
      "setSingleSessionAssData",
      "setSingleSessionTools",
      "setSingleSessionTreatment",
      "setSingleSessionNote",
   ]);

   const fetchSession = async (
      status = "",
      startDate = "",
      endDate = "",
      q = "",
      page = 1
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings?status=${status}&start_date=${startDate}&end_date=${endDate}&q=${q}&page=${page}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }

      setSessionData(sendRequest.data?.data);
   };

   const fetchSessionCount = async (
      status = "",
      page = 1,
      startDate = "",
      endDate = "",
      q = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings?component=count&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }

      //  console.log(sendRequest.data);
      setSessionPageCount(sendRequest.data.data);
   };

   const fetchSingleSession = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings/${id}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      // console.log(sendRequest)
      setSingleSessionData(sendRequest.data.data);
   };
   const fetchSingleSessionAssessment = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings/booking-assessment?booking_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      // console.log(sendRequest.data, 'assessment');
      setSingleSessionAssData(sendRequest.data.data);
   };
   const fetchSingleSessionTreatment = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings/booking-treatment-plan?booking_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      //  console.log(sendRequest.data.data);
      setSingleSessionTreatment(sendRequest.data.data);
   };
   const fetchSingleSessionTools = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings/booking-tool?booking_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      //  console.log(sendRequest.data);
      setSingleSessionTools(sendRequest.data.data);
   };
   const fetchSingleSessionNote = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings/booking-progress-note?booking_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      //  console.log(sendRequest.data);
      setSingleSessionNote(sendRequest.data.data);
   };
   return {
      fetchSession,
      fetchSessionCount,
      fetchSingleSession,
      fetchSingleSessionAssessment,
      fetchSingleSessionTreatment,
      fetchSingleSessionTools,
      fetchSingleSessionNote,
   };
};
