import { BrowserRouter, createBrowserRouter, RouterProvider } from "react-router-dom"
import { MemberRoute } from "./member-route"
import { DashboardRoute } from "./dashboard-route"
import { ProviderRoute } from "./provider-route"
import { SessionRoute } from "./session-route"
import { ServiceRoute } from "./services-route"
import { LoginRoute } from "./login-route"
import { FeedbackRoute } from "./feedback-route"
import { TransactionRoute } from "./transaction-route"
import { SupportRoute } from "./support-route"
import { ManagementRoute } from "./management-route"
import { SettingsRoute } from "./settings-route"


const ComponentRouter = [
   MemberRoute,
   FeedbackRoute,
   DashboardRoute,
   ProviderRoute,
   SessionRoute,
   ServiceRoute,
   TransactionRoute,
   SupportRoute,
   ManagementRoute,
   SettingsRoute,
   LoginRoute
]


export default () => {

   const appRouter = createBrowserRouter(ComponentRouter)
   return (
      <RouterProvider router={appRouter} fallbackElement={<div>Not found</div>} />
   )
}