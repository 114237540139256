import { Routes, RouteObject } from "react-router-dom"
import ServiceIndex from "../pages/services/ServiceIndex"


export const ServiceRoute: RouteObject = {
   path: "/services",
   children: [
      {
         path: "/services",
         element: <ServiceIndex />
      },
   ]
}