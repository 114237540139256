import AppText from "./AppText"
import AppDropdown from "./AppDropdown"
import { useState } from "react"

const search: string = require('../assets/icons/search.svg').default
const arrowdown: string = require('../assets/icons/arrowdown.svg').default
const frame: string = require('../assets/icons/frame.svg').default

export type DropdownState = {
   show: boolean;
   onFilterClick?: Function;
   onSelect?: ((e: any) => void) | undefined;
   title1?: string;
   title2?: string;
   data1?: Array<any>;
   data2?: Array<any>;
   myDefault?: string
   onSearch?: ((e: any) => void) | undefined;
}
export default (props: DropdownState) => {
   let deff = props?.myDefault ? props?.myDefault : ""
   const [comState, setComState] = useState({ startDate: '', endDate: '', data1: '', data2: deff, q: '' })
   return (
      <div className="mb-30">
         <div className="row d-flex align-items-center">
            <div className="col-12 cursor-pointer clickFilterId" onClick={(e) => {
               let clickTarget: any = e
               if (clickTarget?.target?.classList?.contains('clickFilterId')) {
                  typeof props.onFilterClick === 'function' && props.onFilterClick()
               }
            }}>
               <div className="sortedBy floaterRight floaterRight2 relativePosition bgFilterWhite clickFilterId">
                  <div className="sortedBy clickFilterId">
                     <AppText fontSize={'17px'} color="#61646B" fontWeight="600">Filter</AppText>
                     <img className="left12 clickFilterId" src={arrowdown} />
                     <img className="left200 clickFilterId" src={frame} />
                  </div>
                  {props.show &&
                     <AppDropdown myDefault={props?.myDefault} data1={props?.data1} data2={props?.data2} title1={props.title1} title2={props.title2} containerStyle={{ marginTop: '30px' }} onPress={props.onSelect} />

                  }
               </div>
            </div>
         </div>
      </div>
   )
}