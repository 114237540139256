import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppText from "../../components/AppText";
import useProviderFunction from "../../hooks/useProviderFunction";
import { useStoreSelector } from "../../store/useStore";
import Loader from "../../components/Loader";

export default () => {
   const params = useParams();
   const [userStatus, setUserStatus] = useState('');
   const { fetchSingleProvider, updateUserStatus } = useProviderFunction()
   const { singleProviderData } = useStoreSelector(["singleProviderData"])

   useEffect(() => {
      fetchSingleProvider(params?.ID as any)
   }, [])

   // console.log(singleProviderData)
   return (
      <div className="myTable w-100 mb-4">
         {singleProviderData?.auth_id === params?.ID ?
            <div className="bookedTable">
               <div className="borderBottomProfile">
                  <div className="row mt-2">
                     <div className="col-sm-6 mb-4">
                        <div className="greenBackground">
                           <div className="d-flex borderRight alignCenter">
                              <div>
                                 <AppText fontSize={'12px'} color="#19191B">Completed <br />Appointments</AppText>
                              </div>
                              <div className="ml50"><AppText fontSize={'32px'} color="#000" fontWeight="500">{singleProviderData?.completed_session}</AppText></div>
                           </div>
                           <div className="d-flex alignCenter">
                              <div>
                                 <AppText fontSize={'12px'} color="#19191B">Rating</AppText>
                              </div>
                              <div className="ml50"><AppText fontSize={'32px'} color="#000" fontWeight="500">{singleProviderData?.rating}</AppText></div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="goRightALittle">
                           <div className="profileSelect newSelect2 d-flex">
                              <div>
                                 <AppText>Change Status</AppText>
                                 <select className="mt-2" onChange={(e) => setUserStatus(e.target.value)}>
                                    <option value="">Select</option>
                                    {singleProviderData?.status === 1 ?
                                       <React.Fragment>
                                          <option value="2">Suspend</option>
                                       </React.Fragment> :
                                       <React.Fragment>
                                          <option value="1">Approve</option>
                                          <option value="3">Under Review</option>
                                       </React.Fragment>
                                    }
                                 </select>
                                 <button className="profileFilterBtn profileBTN" type="button" onClick={(e) => updateUserStatus(singleProviderData?.auth_id, userStatus)}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Apply</AppText></button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="mt27 borderBottomProfile">
                  <AppText fontSize={'18px'} color="#000" fontWeight="500">Personal Information</AppText>
                  <div className="mt20 with60">
                     <div className="d-flex spaceRounded">
                        <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Name</AppText>
                        <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleProviderData?.name}</AppText></div>
                     </div>
                     <div className="d-flex spaceRounded">
                        <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Gender</AppText>
                        <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleProviderData?.gender}</AppText></div>
                     </div>
                     <div className="d-flex spaceRounded">
                        <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Email</AppText>
                        <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleProviderData?.email}</AppText></div>
                     </div>
                     <div className="d-flex spaceRounded">
                        <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Phone</AppText>
                        <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleProviderData?.phone_number}</AppText></div>
                     </div>
                     <div className="d-flex spaceRounded">
                        <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Referral Code</AppText>
                        <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleProviderData?.referral_code}</AppText></div>
                     </div>
                     <div className="d-flex spaceRounded">
                        <AppText fontSize={'16px'} fontWeight="500" color="#61646B">State of Residence</AppText>
                        <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleProviderData?.residence_state}</AppText></div>
                     </div>

                     <div className="d-flex spaceRounded">
                        <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Address</AppText>
                        <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleProviderData?.residence_address}</AppText></div>
                     </div>
                  </div>
               </div>
               <div className="mt27">
                  <AppText fontSize={'18px'} color="#000" fontWeight="500">Bio</AppText>
                  <div className="mt20">
                     <div className="d-flex spaceRounded">
                        <AppText fontSize={'16px'} fontWeight="400" color="#000">{singleProviderData?.bio}</AppText>
                     </div>
                  </div>
               </div>
            </div> :
            <div className="text-center">
               <Loader position="relative" />
            </div>
         }
      </div>
   )
}