import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import { useEffect, useState } from "react";
import { ProviderAPIData } from "../../constants/mockData";
import { useParams } from "react-router-dom";
import AppText from "../../components/AppText";
import { AppAlertData } from "../../store/storeslice/alert-slice";
import { useStoreSelector } from "../../store/useStore";
import AppLayout from "../../components/AppLayout";
import SettingsPopUp from "../../components/SettingsPopUp";
import useSettingFunction from "../../hooks/useSettingFunction";
import helpers from "../../utils/helpers";
import SettingPopUpEdit from "../../components/SettingPopUpEdit";
const userpic: string = require('../../assets/icons/userpic.svg').default
const chaticon: string = require('../../assets/icons/chaticon.svg').default
const chatsubmit: string = require('../../assets/icons/chatsubmit.svg').default
const serviceedit: string = require('../../assets/icons/serviceedit.svg').default
const servicedelete: string = require('../../assets/icons/servicedelete.svg').default
const serviceplus: string = require('../../assets/icons/serviceplus.svg').default
const servicearrowdown: string = require('../../assets/icons/servicearrowdown.svg').default
const trans: string = require('../../assets/icons/arrownew.svg').default

export default () => {
   const params = useParams();
   // console.log(params.ID)
   const [editSettingModal, setEditSettingModal] = useState({ status: false, data: {} })
   const [newSetting, setNewSettings] = useState(false)
   const { setAlert, settingData, isLoading } = useStoreSelector(["setAlert", "settingData", "isLoading"])
   const { getSettings, deleteSetting } = useSettingFunction()
   useEffect(() => {
      getSettings()
   }, [])
   return (
      <AppLayout active="settings">
         <div className="">
            <div className="mb-4 d-flex">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Settings</AppText>
               <button className="loginBtn newServiceBtn" type="button" onClick={(e) => setNewSettings(true)}>
                  <AppText color="#fff" fontWeight="500" fontSize={'17px'} letterSpacing={'0.2px'}>Create Setting</AppText></button>
            </div>
            <div className="myTable w-100 mb-4 mt-4">
               <AppTable data={settingData} isLoading={isLoading} headers={["Title", "Amount", "Amount Type", "Action"]} tableTitle="">
                  {(settingData || []).map((item: any, i: any) => (
                     <tr className="table-tr cursor-pointer cursor-hover" key={i}>
                        <td className="tdclass4 tableGrid">{item?.title ? helpers.toUpperCase(item?.title) : helpers.replaceUnderDashToUpper(item?.data_key)}</td>
                        <td className="tdclass4">{helpers.formatMoney(item?.amount || 0)}</td>
                        <td className="tdclass4">{item?.amount_type}</td>
                        <td className="d-flex">
                           <div className="cursor-pointer" onClick={() => setEditSettingModal({ status: true, data: item })}>
                              <img src={serviceedit} />
                           </div>
                           <div className="cursor-pointer" style={{ marginLeft: '15px' }}>
                              <img src={servicedelete} onClick={(e) => {
                                 e.preventDefault()
                                 setAlert({
                                    type: "info",
                                    title: "Confirmation",
                                    message: "Are you sure you want to delete?",
                                    onCancel: () => { },
                                    onConfirm: () => { deleteSetting(item?._id) }
                                 } as AppAlertData)
                              }} />
                           </div>
                        </td>
                     </tr>
                  ))}
               </AppTable>
            </div>
            {newSetting &&
               <SettingsPopUp onPress={() => setNewSettings(false)} />
            }

            {editSettingModal.status &&
               <SettingPopUpEdit data={editSettingModal.data} onPress={() => setEditSettingModal({ status: false, data: {} })} />
            }
         </div>
      </AppLayout>
   )
}