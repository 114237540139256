import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface DashboardSliceState {
  dashboardData?: ObjectPayload;
  setDashboardData?: (value: ObjectPayload) => void;
  dashboardSessionChart?: ObjectPayload;
  setDashboardSessionChart?: (value: ObjectPayload) => void;
  dashboardWeeklyChart?: ObjectPayload;
  setDashboardWeeklyChart?: (value: ObjectPayload) => void;
  dashboardUserChart?: ObjectPayload;
  setDashboardUserChart?: (value: ObjectPayload) => void;
}

export const dashboardStoreSlice: StoreStateCreator<DashboardSliceState> = (
  set,
  get
) => ({
  dashboardData: {},
  setDashboardData: (value: ObjectPayload) => set({ dashboardData: value }),
  dashboardSessionChart: [],
  setDashboardSessionChart: (value: ObjectPayload) =>
    set({ dashboardSessionChart: value }),
  dashboardWeeklyChart: [],
  setDashboardWeeklyChart: (value: ObjectPayload) =>
    set({ dashboardWeeklyChart: value }),
  dashboardUserChart: [],
  setDashboardUserChart: (value: ObjectPayload) =>
    set({ dashboardUserChart: value }),
});
