import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import AppText from './AppText';
import { ObjectPayload } from '../typings/general';
import useSpecialtyFunction from '../hooks/useSpecialtyFunction';
const closebtn: string = require('../assets/icons/closebtn.svg').default


type DropdownItems = {
   name?: string;
   value?: any;
   onPress?: Function;
}
interface DropdownOptions {
   onPress?: ((e: DropdownItems) => void) | undefined;
   containerStyle?: CSSProperties;
   title1?: string;
   title2?: string;
   data1?: Array<DropdownItems>;
   data2?: Array<DropdownItems>;
}

// const DropdownRow = ({ name, onPress }: DropdownItems) => {
//    return (

//       <option>{name}</option>
//    )
// }
export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
export default (props: DataState) => {
   const [comState, setComState] = useState({ serviceCatId: props?.data?.service_category_id, data: { name: '', description: '', goals: '', method: '', outcome: '' } })
   const { createServices } = useSpecialtyFunction()
   return (
      <div style={{
         position: 'absolute', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)', width: '100%', height: '100%', zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '400px', ...props.containerStyle }}>
            {/* <div style={{ backgroundColor: '#fff', borderRadius: '20px', padding: '20px', width: '250px', boxShadow: '2px 1px 2px #eee', ...props.containerStyle }}> */}
            <div className='bg-white dropdownMainNew max600'>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className='popupText1 mb-2 d-flex spaceRounded'>
                        <AppText marginBottom={'13px'} marginTop={'10px'} fontSize={'24px'} color='#19191B'>Create Service</AppText>
                        <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-25px' }} className="fa fa-times m-color-red cursor-pointer cursor-hover" aria-hidden="true"></i>
                     </div>
                     <form method="post">
                        <div className="containerForm">
                           <div className='row'>
                              <div className='col-12'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Name of Service</AppText>
                                    <input className='my-form-control' type="text" placeholder="e.g: Family Counselling" onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, name: e.target.value } }))
                                    }} />
                                 </div>
                              </div>
                              <div className='col-6'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Description</AppText>
                                    <textarea className='textareaclass' placeholder='Note' onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, description: e.target.value } }))
                                    }}></textarea>
                                 </div>
                              </div>
                              <div className='col-6'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Goals</AppText>
                                    <textarea className='textareaclass' onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, goals: e.target.value } }))
                                    }} placeholder='Note'></textarea>
                                 </div>
                              </div>
                              <div className='col-6'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Methodologies</AppText>
                                    <textarea className='textareaclass' onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, method: e.target.value } }))
                                    }} placeholder='Note'></textarea>
                                 </div>
                              </div>
                              <div className='col-6'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Potential Outcomes</AppText>
                                    <textarea className='textareaclass' onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, outcome: e.target.value } }))
                                    }} placeholder='Note'></textarea>
                                 </div>
                              </div>
                           </div>
                           <button className="loginBtn mt-4 with60 mlr-auto" type="button" onClick={(e) => {
                              e.preventDefault()
                              createServices(comState?.data, props?.data?.service_category_id, props.onPress)
                           }}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Create</AppText></button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}