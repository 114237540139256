import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
  const {
    setIsLoading,
    setTransactionDepositData,
    setTransactionWithdrawalData,
    setTransactionData,
    setTransactionPageCount,
  } = useStoreSelector([
    "setIsLoading",
    "setTransactionDepositData",
    "setTransactionWithdrawalData",
    "setTransactionData",
    "setTransactionPageCount",
  ]);

  const fetchTransactionPageCount = async (
    type = "fund",
    page = 1,
    startDate = "",
    endDate = ""
  ) => {
    let sendRequest = await helpers
      .sendRequest({
        url: `${APIendPoint.session.getwallet}/wallet?component=count&transaction_type=${type}&page=${page}&start_date=${startDate}&end_date=${endDate}`,
        method: "GET",
      })
      .catch((e) => e);
    if (sendRequest && sendRequest.status_code !== 200) {
      return helpers.showErrorMessage(sendRequest);
    }
    setTransactionPageCount(sendRequest.data?.data);
  };
  const fetchTransaction = async (
    type = "fund",
    page = 1,
    startDate = "",
    endDate = ""
  ) => {
    setIsLoading(true);
    let sendRequest = await helpers
      .sendRequest({
        url: `${APIendPoint.session.getwallet}/wallet?component=count-status&transaction_type=${type}&page=${page}&start_date=${startDate}&end_date=${endDate}`,
        method: "GET",
      })
      .catch((e) => e);
    setIsLoading(false);
    if (sendRequest && sendRequest.status_code !== 200) {
      return helpers.showErrorMessage(sendRequest);
    }

    setTransactionData(sendRequest.data?.data);
  };
  const fetchDepositTransaction = async (
    type = "fund",
    page = 1,
    startDate = "",
    endDate = ""
  ) => {
    setIsLoading(true);
    let sendRequest = await helpers
      .sendRequest({
        url: `${APIendPoint.session.getwallet}/wallet?transaction_type=${type}&page=${page}&start_date=${startDate}&end_date=${endDate}`,
        method: "GET",
      })
      .catch((e) => e);
    setIsLoading(false);
    if (sendRequest && sendRequest.status_code !== 200) {
      return helpers.showErrorMessage(sendRequest);
    }
    fetchTransaction(type);

    setTransactionDepositData(sendRequest.data?.data);
  };
  const fetchWithdrawalTransaction = async () => {
    setIsLoading(true);
    let sendRequest = await helpers
      .sendRequest({
        url: `${APIendPoint.session.getwallet}/wallet?transaction_type=withdrawal`,
        method: "GET",
      })
      .catch((e) => e);
    setIsLoading(false);
    if (sendRequest && sendRequest.status_code !== 200) {
      return helpers.showErrorMessage(sendRequest);
    }

    setTransactionWithdrawalData(sendRequest.data?.data);
  };

  return {
    fetchDepositTransaction,
    fetchWithdrawalTransaction,
    fetchTransaction,
    fetchTransactionPageCount,
  };
};
