import React, { useState } from 'react'

import { Properties as CSSProperties } from 'csstype'
import AppText from './AppText';
import { useStoreSelector } from '../store/useStore';
import { ObjectPayload } from '../typings/general';
const questionmark: string = require('../assets/icons/questionmark.svg').default


type DropdownItems = {
   name?: string;
   value?: any;
   onPress?: Function;
}
interface DropdownOptions {
   onPress?: ((e: DropdownItems) => void) | undefined;
   containerStyle?: CSSProperties;
   alert?: ObjectPayload;
   setAlert: Function;
}
export const ChildComponent = (props: DropdownOptions) => {
   return (
      // <div style={{ borderRadius: '20px', padding: '20px', width: '250px', ...props.containerStyle }}>
      <div className='dropdownLi dropdownMain max400'>
         <div className="filterForm w-100">
            <div className='popupClass'>
               <div className='img'>
                  <img src={props.alert?.type === 'error' ? questionmark : props.alert?.type === 'success' ? questionmark : questionmark} />
               </div>
               <div className='popupText mb-2'>
                  <AppText marginBottom={'13px'} marginTop={'27px'}>{props.alert?.title || 'Notification'}</AppText>
                  <AppText>{props.alert?.message || 'Notification'}</AppText>
               </div>
               <button className="w-100 loginBtn mt-4 popColor1 text-white" type="button" onClick={() => {
                  props.setAlert({ message: '' })
                  typeof props.alert?.onConfirm === 'function' && props.alert?.onConfirm()
               }}>{props.alert?.confirmName || 'Yes'}</button>
               {typeof props.alert?.onCancel === 'function' &&
                  <button className="w-100 loginBtn mt-3 popColor2 text-pop" type="button" onClick={() => {
                     props.setAlert({ message: '' })
                     typeof props.alert?.onCancel === 'function' && props.alert?.onCancel()
                  }}>{props.alert?.cancelName || 'Cancel'}</button>
               }
            </div>
         </div>
      </div>
      // </div>
   )
}

export default (props: DropdownOptions) => {
   const { alert, setAlert, isLoading } = useStoreSelector(["setAlert", "alert", "isLoading"])
   return (
      <div style={{
         position: 'fixed',
         display: alert?.message ? 'flex' : 'none',
         justifyContent: 'center', alignItems: 'flex-start', width: '100%', height: '100%', zIndex: '999', backgroundColor: 'rgba(0,0,0,.2)', left: 0, top: 0
      }}>

         {alert?.message &&
            <ChildComponent alert={alert} setAlert={setAlert} />
         }



      </div>
   )
}