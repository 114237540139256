import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface SpecialtySliceState {
  specialtyData?: ObjectPayload;
  setSpecialtyData?: (value: ObjectPayload) => void;
}

export const specialtyStoreSlice: StoreStateCreator<SpecialtySliceState> = (
  set,
  get
) => ({
  specialtyData: [],
  setSpecialtyData: (value: ObjectPayload) => set({ specialtyData: value }),
});
