import { useState } from "react";
import AppText from "./AppText";
import { KeyValueObject } from "../typings/general";

interface PageNavigation {
   titles: KeyValueObject[];
   onPress?: ((e: KeyValueObject) => void) | undefined;
   defaultActive?: string
}
export default (props: PageNavigation) => {
   const [activeTab, setActiveTab] = useState(props.defaultActive || '')
   return (
      <div className="innerNavigation">
         <ul>
            {props.titles.map((item, i) => <li key={i} className={`cursor-pointer ${activeTab === item.value ? 'backGroundActive' : 'backgroundNormal'}`}
               onClick={() => {
                  setActiveTab(item.value)
                  typeof props.onPress === 'function' && props.onPress(item)
               }
               }>
               <AppText fontSize={'16px'} fontWeight="500">{item.name}</AppText></li>)}
         </ul>
      </div>
   )
}