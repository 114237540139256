export const ProviderAPIData = [
  {
    name: "Increase Nkanta",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 0,
    ID: "64738RTE71",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Clinton Tuoyo",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 1,
    ID: "64738RTE72",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Anita Ogu",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 2,
    ID: "64738RTE70",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Emiko Uyiri",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 0,
    ID: "64738RTE73",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Aliko Dangote",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 2,
    ID: "64738RTE74",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Davido Wizkid",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 1,
    ID: "64738RTE75",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Adekunle Gold",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 0,
    ID: "64738RTE76",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Aliyu Adamu",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 1,
    ID: "64738RTE77",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Ahmed Buhari",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 0,
    ID: "64738RTE78",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Danjuma Stephen",
    createdAt: "2023-11-01T08:00:00.000Z",
    specialty: "Behavioural Therapist",
    status: 2,
    ID: "64738RTE79",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
];

export const MemberData = [
  {
    name: "Ebeneza Stephen",
    createdAt: "2023-11-01T08:00:00.000Z",
    sessions: 2,
    ID: "64738RTE76",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Danjuma Aliyu",
    createdAt: "2023-11-01T08:00:00.000Z",
    sessions: 3,
    ID: "64738RTE77",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Helen Stephen",
    createdAt: "2023-11-01T08:00:00.000Z",
    sessions: 1,
    ID: "64738RTE78",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
  {
    name: "Haruna Kelvin",
    createdAt: "2023-11-01T08:00:00.000Z",
    sessions: 6,
    ID: "64738RTE79",
    gender: "male",
    email: "clintoneyituoyo@gmail.com",
    phone: "07061967265",
  },
];

export const DoctorAppointment = [
  {
    name: "Clinton Tuoyo",
    createdAt: "2023-11-01T16:00:00.000Z",
    modeOfcomm: "video",
    status: "Upcoming",
    userId: "64738RTE72",
  },
  {
    name: "Clinton Tuoyo",
    createdAt: "2023-11-03T08:00:00.000Z",
    modeOfcomm: "audio",
    status: "Upcoming",
    userId: "64738RTE72",
  },
  {
    name: "Clinton Tuoyo",
    createdAt: "2023-11-04T09:00:00.000Z",
    modeOfcomm: "video",
    status: "Completed",
    userId: "64738RTE72",
  },
  {
    name: "Clinton Tuoyo",
    createdAt: "2023-10-01T13:00:00.000Z",
    modeOfcomm: "video",
    status: "Completed",
    userId: "64738RTE72",
  },
  {
    name: "Clinton Tuoyo",
    createdAt: "2023-11-03T12:00:00.000Z",
    modeOfcomm: "audio",
    status: "Completed",
    userId: "64738RTE72",
  },
  {
    name: "Clinton Tuoyo",
    createdAt: "2023-11-04T09:00:00.000Z",
    modeOfcomm: "video",
    status: "Cancelled",
    userId: "64738RTE72",
  },
  {
    name: "Clinton Tuoyo",
    createdAt: "2023-10-01T14:00:00.000Z",
    modeOfcomm: "video",
    status: "Upcoming",
    userId: "64738RTE72",
  },
];
