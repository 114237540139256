import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
  const {
    setDashboardData,
    setDashboardSessionChart,
    setDashboardWeeklyChart,
    setDashboardUserChart,
  } = useStoreSelector([
    "setDashboardData",
    "setDashboardSessionChart",
    "setDashboardWeeklyChart",
    "setDashboardUserChart",
  ]);
  const DashboardNumberCount = async () => {
    let sendRequest = await helpers
      .sendRequest({
        url: `${APIendPoint.dashboard.getdashboardusercount}/data-stat?component=dashboard-user-count`,
        method: "GET",
      })
      .catch((e) => e);
    if (sendRequest && sendRequest.status_code !== 200) {
      return helpers.showErrorMessage(sendRequest);
    }
    setDashboardData(sendRequest.data.data);
  };
  const DashboardSessionChart = async (year: any, month: any) => {
    let sendRequest = await helpers
      .sendRequest({
        url: `${APIendPoint.dashboard.getdashboardusercount}/data-stat?component=dashboard-booking-count&month=${month}&year=${year}`,
        method: "GET",
      })
      .catch((e) => e);
    if (sendRequest && sendRequest.status_code !== 200) {
      return helpers.showErrorMessage(sendRequest);
    }
    setDashboardSessionChart(sendRequest.data.data);
  };
  const DashboardWeeklyChart = async () => {
    let sendRequest = await helpers
      .sendRequest({
        url: `${APIendPoint.dashboard.getdashboardusercount}/data-stat?component=dashboard-booking-weekly`,
        method: "GET",
      })
      .catch((e) => e);
    if (sendRequest && sendRequest.status_code !== 200) {
      return helpers.showErrorMessage(sendRequest);
    }
    setDashboardWeeklyChart(sendRequest.data.data);
  };

  const DashboardUserChart = async () => {
    let sendRequest = await helpers
      .sendRequest({
        url: `${APIendPoint.dashboard.getdashboardbooking}/bookings?item_per_page=10&status=1`,
        method: "GET",
      })
      .catch((e) => e);
    if (sendRequest && sendRequest.status_code !== 200) {
      return helpers.showErrorMessage(sendRequest);
    }
    setDashboardUserChart(sendRequest.data.data);
  };
  return {
    DashboardNumberCount,
    DashboardSessionChart,
    DashboardWeeklyChart,
    DashboardUserChart,
  };
};
