import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import { useEffect, useState } from "react";
import { ProviderAPIData } from "../../constants/mockData";
import { useParams } from "react-router-dom";
import AppText from "../../components/AppText";
import useMemberFunction from "../../hooks/useMemberFunction";
import { useStoreSelector } from "../../store/useStore";
import helpers from "../../utils/helpers";

export default () => {
   const params = useParams();
   // console.log(params.ID)
   const [userStatus, setUserStatus] = useState('');
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, data: Array<any> }>({ isLoading: false, data: [] })
   useEffect(() => {
      setDataArray(state => ({ ...state, isLoading: false, data: ProviderAPIData.filter(e => e.ID === params.ID) }))
   }, [])
   const userData = dataArray.data[0]

   const { fetchSingleMember, updateUserStatus } = useMemberFunction()
   const { singleMemberData } = useStoreSelector(['singleMemberData'])
   useEffect(() => {
      fetchSingleMember(params?.ID as any)
   }, [])
   const username = singleMemberData?.name?.split(' ')
   return (
      <div className="myTable w-100 mb-4">
         <div className="bookedTable">
            <div className="borderBottomProfile">
               <div className="table-text go15mp"><span className={`marginBottom profileActive ${singleMemberData?.status === 1 ? "userActive" : singleMemberData?.status === 2 ? "userReview" : "userPending"}`}>{singleMemberData?.status === 1 ? "Active" : singleMemberData?.status === 2 ? "Suspended" : "Pending"}</span></div>
               <div className="row mt-2">
                  <div className="col-lg-6 col-12 mb-4">
                     <div className="greenBackground">
                        <div className="d-flex borderRight alignCenter">
                           <div>
                              <AppText fontSize={'12px'} color="#19191B">Completed <br />Appointments</AppText>
                           </div>
                           <div className="ml50"><AppText fontSize={'32px'} color="#000" fontWeight="500">{singleMemberData?.completed_session}</AppText></div>
                        </div>
                        <div className="d-flex alignCenter">
                           <div>
                              <AppText fontSize={'12px'} color="#19191B">Rating</AppText>
                           </div>
                           <div className="ml50"><AppText fontSize={'32px'} color="#000" fontWeight="500">{singleMemberData?.rating}</AppText></div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <div className="goRightALittle">
                        <div className="profileSelect newSelect2 d-flex">
                           <div>
                              <AppText>Change Status</AppText>
                              <select className="mt-2" onChange={(e) => setUserStatus(e.target.value)}>
                                 <option value="">Select</option>
                                 <option value="1">Approve</option>
                                 <option value="2">Suspend</option>
                              </select>
                              <button className="profileFilterBtn profileBTN" type="button" onClick={(e) => updateUserStatus(singleMemberData?.auth_id, userStatus)}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Apply</AppText></button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="mt27 borderBottomProfile">
               <AppText fontSize={'18px'} color="#000" fontWeight="500">Personal Information</AppText>
               <div className="mt20">
                  <div className="d-flex spaceRounded">
                     <AppText fontSize={'16px'} fontWeight="500" color="#61646B">First Name</AppText>
                     <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{username ? username[0] : ''}</AppText></div>
                  </div>
                  <div className="d-flex spaceRounded">
                     <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Last Name</AppText>
                     <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{username ? username[1] : ''}</AppText></div>
                  </div>
                  <div className="d-flex spaceRounded">
                     <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Gender</AppText>
                     <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleMemberData?.gender}</AppText></div>
                  </div>
                  <div className="d-flex spaceRounded">
                     <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Email</AppText>
                     <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleMemberData?.email}</AppText></div>
                  </div>
                  <div className="d-flex spaceRounded">
                     <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Phone</AppText>
                     <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleMemberData?.phone_number}</AppText></div>
                  </div>
                  <div className="d-flex spaceRounded">
                     <AppText fontSize={'16px'} fontWeight="500" color="#61646B">State of Residence</AppText>
                     <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleMemberData?.residence_state}</AppText></div>
                  </div>
                  <div className="d-flex spaceRounded">
                     <AppText fontSize={'16px'} fontWeight="500" color="#61646B">Address</AppText>
                     <div className="goML80"><AppText fontSize={'16px'} fontWeight="400" color="#000">{singleMemberData?.residence_address}</AppText></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}