import { Routes, RouteObject } from "react-router-dom"
import ProviderIndex from "../pages/providers/ProviderIndex"
import ProviderDetails from "../pages/providers/ProviderDetails"


export const ProviderRoute: RouteObject = {
   path: "/providers",
   children: [
      {
         path: "",
         element: <ProviderIndex type="individual" />
      },
      {
         path: "business",
         element: <ProviderIndex type="business" />
      },
      {
         path: "/providers/:ID",
         element: <ProviderDetails />
      },
   ]
}