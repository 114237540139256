import { useParams } from "react-router-dom";
import { useEffect } from "react"
import HeaderCard from "./HeaderCard"
import useMemberFunction from "../hooks/useMemberFunction";
import { useStoreSelector } from "../store/useStore";
import helpers from "../utils/helpers";

export default () => {
   const params = useParams();
   const { fetchSingleMemberCounter } = useMemberFunction()
   const { singleMemberCount } = useStoreSelector(["singleMemberCount"])
   useEffect(() => {
      fetchSingleMemberCounter(params?.ID as any)
   }, [])
   return (
      <div className="pageHeaderCards mb-4">
         <div className="row">
            <HeaderCard cardTitle="Wallet Balance" cardNumber={helpers.getNairaSign() + helpers.formatMoney(singleMemberCount?.wallet_balance || 0.00)} />
            <HeaderCard cardTitle="Total Spend" cardNumber={helpers.getNairaSign() + helpers.formatMoney(singleMemberCount?.total_spend || 0.00)} />
            <HeaderCard cardTitle="Total Sessions" cardNumber={helpers.formatMoney(singleMemberCount?.session_total || 0.00)} />
            <HeaderCard cardTitle="Completed Sessions" cardNumber={helpers.formatMoney(singleMemberCount?.session_completed || 0.00)} />
         </div>
      </div>
   )
}