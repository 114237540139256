import { KeyValueObject } from "../typings/general";

export const MemberProfileSubValues = {
   profile: "profile",
   sessions: "sessions",
   //   support: "support",
   transaction: "transaction",
   //   settings: "settings",
};

export const MemberProfileSubScreen: KeyValueObject[] = [
   { name: "Profile", value: MemberProfileSubValues.profile },
   { name: "Sessions", value: MemberProfileSubValues.sessions },
   //   { name: "Support", value: MemberProfileSubValues.support },
   { name: "Transaction", value: MemberProfileSubValues.transaction },
   //   { name: "Settings", value: MemberProfileSubValues.settings },
];

export const Status = {
   Upcoming: "Upcoming",
   Completed: "Completed",
   Cancelled: "Cancelled",
};
export const MemberStatus: KeyValueObject[] = [
   { name: "Upcoming", value: Status.Upcoming },
   { name: "Completed", value: Status.Completed },
   { name: "Cancelled", value: Status.Cancelled },
];

export const ActiveStatus = {
   Active: "Active",
   Pending: "Pending",
   Under_Review: "Under Review",
};

export const ActiveMemberStatus: KeyValueObject[] = [
   { name: "Active", value: ActiveStatus.Active },
   { name: "Pending", value: ActiveStatus.Pending },
   { name: "Under Review", value: ActiveStatus.Under_Review },
];
