import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const {
      setIsLoading,
      setProviderData,
      setSingleProviderData,
      setSingleProviderCount,
      setSingleProviderSession,
      setSingleProviderTransaction,
      setProviderTransactionCount,
      setSingleProviderSessionCount,
      setSingleProviderTransactionCount,
      setAlert,
   } = useStoreSelector([
      "setIsLoading",
      "setProviderData",
      "setSingleProviderData",
      "setSingleProviderCount",
      "setSingleProviderSession",
      "setSingleProviderTransaction",
      "setProviderTransactionCount",
      "setSingleProviderSessionCount",
      "setSingleProviderTransactionCount",
      "setAlert",
   ]);

   const updateUserStatus = async (id: string, status: string) => {
      //  setIsLoading(true);

      if (!status) {
         return setAlert({
            type: "error",
            title: "Missing field",
            message: "Please select a status",
            confirmName: "Ok",
         } as AppAlertData);
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider}/account-status`,
            method: "POST",
            json: { user_type: "provider", auth_id: id, status: status },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      fetchSingleProvider(id);
      helpers.showToast("success", "Updated successful");
   };
   const fetchProviderCount = async (
      type: string,
      status = 1,
      page = 1,
      startDate = "",
      endDate = ""
   ) => {

      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider}?account_type=${type}&component=count&user_type=provider&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}`,
            method: "GET",
         })
         .catch((e) => e);

      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setProviderTransactionCount(sendRequest.data.data);
   };
   const fetchProviders = async (
      type: string,
      status = 1,
      page = 1,
      startDate = "",
      endDate = "",
      q = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider}?account_type=${type}&user_type=provider&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setProviderData(sendRequest.data.data);
   };
   const fetchSingleProvider = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider}/${id}?user_type=provider`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleProviderData(sendRequest.data.data);
   };

   const fetchSingleProviderCounter = async (id: string) => {
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider}/data-stat?component=provider-profile-count&provider_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleProviderCount(sendRequest.data?.data);
   };

   const fetchSingleProviderSession = async (
      id: string,
      type: string,
      status = "",
      page = 1,
      startDate = "",
      endDate = "",
      q = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider2}/bookings?auth_id=${id}&account_type=${type}&user_type=provider&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleProviderSession(sendRequest.data?.data);
   };

   const fetchSingleProviderTransaction = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider}/wallet?auth_id=${id}&user_type=provider`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleProviderTransaction(sendRequest.data?.data);
   };

   const fetchProviderSessionCount = async (
      id: string,
      type: string,
      status = "",
      page = 1,
      startDate = "",
      endDate = ""
   ) => {
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider2}/bookings?component=count&account_type=${type}&user_type=provider&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&auth_id=${id}`,
            method: "GET",
         }).catch((e) => e);

      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleProviderSessionCount(sendRequest.data.data[0]);
   };
   const fetchProviderTransactionCount = async (
      id: string,
      status = "",
      page = 1,
      startDate = "",
      endDate = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.provider.getprovider}/wallet?component=count&user_type=provider&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&auth_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      //  console.log(sendRequest.data);
      setSingleProviderTransactionCount(sendRequest.data.data);
   };

   // const updateStatus = async (
   //    id: string,
   //    onPress?: Function
   //  ) => {
   //    if (!id) {
   //      return helpers.showToast("error", "id was not captured");
   //    }

   //    helpers.showToast("loading", "Loading");
   //    let sendRequest = await helpers
   //      .sendRequest({
   //        url: `${APIendPoint.admin.pwdReset}/reset-password`,
   //        method: "POST",
   //        json: {
   //          id: id,
   //        },
   //      })
   //      .catch((e) => e);
   //    //  setIsLoading(false);
   //    if (sendRequest && sendRequest.status_code !== 200) {
   //      return helpers.showErrorMessage(sendRequest);
   //    }
   //    helpers.showToast("success", "Password was changed successfully");
   //    onPress && onPress();
   //  };
   return {
      fetchProviders,
      fetchSingleProvider,
      fetchSingleProviderCounter,
      fetchSingleProviderSession,
      fetchSingleProviderTransaction,
      fetchProviderCount,
      fetchProviderSessionCount,
      fetchProviderTransactionCount,
      updateUserStatus,
   };
};
