import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import { useEffect, useState } from "react";
import { ProviderAPIData } from "../../constants/mockData";
import { useParams } from "react-router-dom";
import AppText from "../../components/AppText";
const userpic: string = require('../../assets/icons/userpic.svg').default
const chaticon: string = require('../../assets/icons/chaticon.svg').default
const chatsubmit: string = require('../../assets/icons/chatsubmit.svg').default

export default () => {
   const params = useParams();
   // console.log(params.ID)
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, data: Array<any> }>({ isLoading: false, data: [] })
   useEffect(() => {
      setDataArray(state => ({ ...state, isLoading: false, data: ProviderAPIData.filter(e => e.ID === params.ID) }))
   }, [])
   const userData = dataArray.data[0]

   const username = userData?.name?.split(' ')
   // console.log(username)
   return (
      <div className="myTable w-100 mb-4">
         <div className="bookedTable">
            <div className="borderBottomProfileChat">
               <div className="d-flex pd24">
                  <div className='d-flex'>
                     <div className='profile-inner'>
                        <div className='imgContainer2 imgContainerChat'><img src={userpic} /></div>
                        <div className='myProfile line01'>
                           <h1 className="headerH1 mb-n2"><AppText fontSize={'20px'} color="#000" fontWeight="600" letterSpacing={'0.15px'}>David Ojo</AppText></h1>

                           <p> <AppText fontSize={'12px'} color="#000" fontWeight="600" letterSpacing={'0.25px'} opacity={'0.6'}><span className="onlineOrNot"></span>Online</AppText> </p>
                        </div>
                     </div>
                     <AppText marginLeft={'30px'} fontWeight="400" fontSize={'14px'}>Ticket ID: 100283484</AppText>
                  </div>
                  <button className="loginBtn newServiceBtn borderRadis100" type="button"><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Mark as Resolved</AppText></button>
               </div>
            </div>
            <div className="mt-4 borderBottomProfile">
               <div className="pd24">
                  <div className="profileChat">
                     <div className="chatContainer">
                        <div className='imgContainer2 imgContainerChat mr16'><img src={userpic} /></div>
                        <div className="myChat"><AppText fontSize={'12px'} fontWeight="500" color="#61646B">How are you doing</AppText></div>
                     </div>

                     <div className="chatContainer floaterRight">
                        <div className="myChat"><AppText fontSize={'12px'} fontWeight="500" color="#61646B">I am doing great and you?</AppText></div>
                        <div className='imgContainer2 imgContainerChat ml16'><img src={userpic} className="right" /></div>
                     </div>
                  </div>
                  <div className="textareaBox w-100 mt140">
                     <div className="row">
                        <div className="col-1">
                           <div className='uploadIcon mr16'><img src={chaticon} /></div>
                        </div>
                        <div className="col-11">
                           <div className="chatBox">
                              <img className="cursor-pointer" src={chatsubmit} onClick={(e) => { alert('hello') }} />
                              <textarea className="chatTextArea" rows={1} placeholder="Type a message"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}