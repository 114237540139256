import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import InnerNavigation from "../../components/InnerNavigation"
import { useState } from "react"
import Searchfilter, { DropdownState } from "../../components/Searchfilter"
import SupportProviderChat from "./SupportProviderChat"
import SupportMemberChat from "./SupportMemberChat"
import { SupportProfileSubScreen, SupportProfileSubValues } from "../../constants/SupportSubScreen"
import SupportTicket from "../../components/SupportTicket"
import ChatList from "../../components/ChatList"
export default () => {
   const [dropMe, ShowDropMe] = useState<DropdownState>({ show: false })
   const [screenTab, setScreenTab] = useState('')
   const [filter, setFilter] = useState({})
   const screenContent = {
      [SupportProfileSubValues.provider]: SupportProviderChat,
      [SupportProfileSubValues.member]: SupportMemberChat,
      ['default']: SupportMemberChat,
   }
   const ScreenMounted = screenContent[screenTab] || screenContent['default']
   return (
      <AppLayout active="supports">
         <div className="">
            {/* <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Sessions</AppText>
            </div> */}
            <div className="">
               <SupportTicket />
            </div>

            <div className="row mb-4">

               <div className="mb-3">
                  <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Supports</AppText>
               </div>

               <div className="col-12">
                  <div className="bg-white border-radius-20 pt-2">
                     <div className="row">
                        <div className="col-lg-2 col-12">
                           <InnerNavigation defaultActive="member" titles={SupportProfileSubScreen} onPress={(item) => setScreenTab(item.value as any)} />
                        </div>
                        <div className="col-lg-2 col-12">
                           <div>
                              <h1 className="headerH1 mb-n2 pd24"><AppText fontSize={'20px'} color="#000" fontWeight="600" letterSpacing={'0.15px'}>Messages</AppText></h1>
                           </div>
                           <div>
                              <ChatList />
                           </div>
                        </div>
                        <div className="col-lg-8 col-12">
                           <ScreenMounted />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="mb-4">

            </div>
         </div>
      </AppLayout>

   )
}