import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface SettingSliceState {
   settingData?: ObjectPayload;
   setSettingData?: (value: ObjectPayload) => void;

   settingKey?: ObjectPayload;
   setSettingKey?: (value: ObjectPayload) => void;
}

export const settingStoreSlice: StoreStateCreator<SettingSliceState> = (
   set,
   get
) => ({
   settingData: [],
   setSettingData: (value: ObjectPayload) => set({ settingData: value }),

   settingKey: [],
   setSettingKey: (value: ObjectPayload) => set({ settingKey: value }),
});
