let baseUrl = "https://user-service-api.apps.themsmt.com";
let bookingUrl = "https://booking-service-api.apps.themsmt.com";

const APIendPoint = {
   admin: {
      login: baseUrl + "/admin/auths/login",
      pwdReset: baseUrl + "/admin/auths",
      profile: baseUrl + "/admin",
      getAdmin: baseUrl + "/admin/operations",
   },
   member: {
      getmembers: baseUrl + "/admin/users",
      getmembers2: bookingUrl + "/admin",
   },
   provider: {
      getprovider: baseUrl + "/admin/users",
      getprovider2: bookingUrl + "/admin",
   },
   dashboard: {
      getdashboardusercount: baseUrl + "/admin/users",
      getdashboardusercount2: baseUrl + "/admin",
      getdashboardbooking: bookingUrl + "/admin",
   },
   session: {
      getsessions: bookingUrl + "/admin",
      getwallet: baseUrl + "/admin/users",
   },
   specialty: {
      getSpecialty: baseUrl + "/admin",
   },
};

export default APIendPoint;
