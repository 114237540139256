import { Routes, RouteObject } from "react-router-dom"
import ManagementIndex from "../pages/management/ManagementIndex"


export const ManagementRoute: RouteObject = {
   path: "/management",
   children: [
      {
         path: "/management",
         element: <ManagementIndex />
      },
   ]
}