
import helpers from "../../utils/helpers"

const WordTemplate = (assessment: any, Treatment: any, Tools: any, Note: any) => {
   return `<!DOCTYPE html>
         <html>
         <head>
         <style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
         </head>
         <body>
         <div style="margin: auto;">
            <p style="font-family:Nunito,serif;color:#006ab5;font-size:18px;font-weight:600;">Assessment</p>
            ${assessment && assessment.length > 0 ?
         (assessment || []).map((item: any, i: any) => (
            `<p style= "font-family:Nunito,serif;color:#006ab5;font-size:16px;font-weight:500;">${helpers.replaceUnderDashToUpper1(item.data_tab)}</p>
            <table>
            <tr>
               <th>Title</th>
               <th>Answer</th>
            </tr>
            ${item?.data && item.data.length > 0 ?
               (item?.data || []).map((data: any, j: any) => (
                  `
                  <tr>
                     <td>${data?.title}</td>
                     <td>${data?.answer}</td>
                  </tr>
                  `))
               : ""}
               </table>`))
         : ""}
         </div>
         <div style="margin: auto;">
            <p style="font-family:Nunito,serif;color:#006ab5;font-size:18px;font-weight:600;">Treatment</p>
            ${Treatment && Treatment.length > 0 ?
         (Treatment || []).map((item: any, i: any) => (
            `<p style= "font-family:Nunito,serif;color:#006ab5;font-size:16px;font-weight:500;">${helpers.replaceUnderDashToUpper1(item.data_tab)}</p>
                  <table>
                  <tr>
                     <th>Title</th>
                     <th>Answer</th>
                  </tr>
                  ${item?.data && item.data.length > 0 ?
               (item?.data || []).map((data: any, j: any) => (
                  `
                        <tr>
                           <td>${data?.title}</td>
                           <td>${data?.answer}</td>
                        </tr>
                        `))
               : ""}
                     </table>`))
         : ""}
         </div>
         <div style="margin: auto;">
            <p style="font-family:Nunito,serif;color:#006ab5;font-size:18px;font-weight:600;">Tools</p>
            ${Tools && Tools.length > 0 ?
         (Tools || []).map((item: any, i: any) => (
            `<p style= "font-family:Nunito,serif;color:#006ab5;font-size:16px;font-weight:500;">${helpers.replaceUnderDashToUpper1(item.data_tab)}</p>
                  <table>
                  <tr>
                     <th>Title</th>
                     <th>Answer</th>
                  </tr>
                  ${item?.data && item.data.length > 0 ?
               (item?.data || []).map((data: any, j: any) => (
                  `
                        <tr>
                           <td>${data?.title}</td>
                           <td>${data?.answer}</td>
                        </tr>
                        `))
               : ""}
                     </table>`))
         : ""}
         </div>
         <div style="margin: auto;">
            <p style="font-family:Nunito,serif;color:#006ab5;font-size:18px;font-weight:600;">Note</p>
            ${Note && Note.length > 0 ?
         (Note || []).map((item: any, i: any) => (
            `<p style= "font-family:Nunito,serif; color:#006ab5; font-size:16px; font-weight:500;">${helpers.replaceUnderDashToUpper1(item.data_tab)}</p>
                  <table>
                  <tr>
                     <th>Title</th>
                     <th>Answer</th>
                  </tr>
                  ${item?.data && item?.data.length > 0 ?
               (item?.data || []).map((data: any, j: any) => (
                  `
                        <tr>
                           <td>${data?.goal ? data?.goal : ""}</td>
                           <td>${data?.addressed ? data?.addressed : ""}</td>
                        </tr>
                        `))
               : ""}
                     </table>`))
         : ""}
         </div>
      </body>
      </html>`
}
export { WordTemplate };