import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import AppText from './AppText';
import { ObjectPayload } from '../typings/general';
import useSpecialtyFunction from '../hooks/useSpecialtyFunction';
import useAdminFunction from '../hooks/useAdminFunction';
const closebtn: string = require('../assets/icons/closebtn.svg').default
const Password: string = require('../assets/icons/Password.svg').default


type DropdownItems = {
   name?: string;
   value?: any;
   onPress?: Function;
}
interface DropdownOptions {
   onPress?: ((e: DropdownItems) => void) | undefined;
   containerStyle?: CSSProperties;
   title1?: string;
   title2?: string;
   data1?: Array<DropdownItems>;
   data2?: Array<DropdownItems>;
}
export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}

// const DropdownRow = ({ name, onPress }: DropdownItems) => {
//    return (

//       <option>{name}</option>
//    )
// }
export default (props: DataState) => {
   const [passwordShown, setPasswordShown] = useState(false);
   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };
   const [passwordShown2, setPasswordShown2] = useState(false);
   const togglePasswordVisiblity2 = () => {
      setPasswordShown2(passwordShown2 ? false : true);
   };



   const [nData, setNData] = useState({ name: '', password: '', confirmPassword: '' })


   const { resetNewPassword } = useAdminFunction()

   return (
      <div style={{
         position: 'absolute', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.5)', width: '100%', height: '100%', zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '400px', ...props.containerStyle }}>
            {/* <div style={{ backgroundColor: '#fff', borderRadius: '20px', padding: '20px', width: '250px', boxShadow: '2px 1px 2px #eee', ...props.containerStyle }}> */}
            <div className='bg-white dropdownMainNew max400 dropdownMain22' style={{ marginTop: '8px' }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className='popupText1 mb-2 d-flex spaceRounded'>
                        <AppText marginBottom={'13px'} marginTop={'10px'} fontSize={'20px'} color='#19191B'>Change Password</AppText>
                        {/* <AppText marginBottom={'13px'} marginTop={'10px'} fontSize={'24px'} color='#19191B'>Create Specialty
                           <img className='popImg' src={closebtn} /></AppText> */}
                        <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-25px' }} className="fa fa-times m-color-red cursor-pointer cursor-hover" aria-hidden="true"></i>
                     </div>
                     <form method="post">
                        <div className="containerForm containerForm2">
                           <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>You have entered a default password please change it to continue</AppText>
                           <div className="w-100 mb-2 mt-2">
                              <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>New Password</AppText>
                              <input className='my-form-control' type={passwordShown ? "text" : "password"} placeholder="New Password" onChange={(e) => { setNData(state => ({ ...state, password: e.target.value })) }} />
                              <span onClick={togglePasswordVisiblity} className={passwordShown ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}></span>
                           </div>
                           <div className="w-100 mb-2 mt-2">
                              <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Confirm Password</AppText>
                              <input className='my-form-control' type={passwordShown2 ? "text" : "password"} placeholder="New Password" onChange={(e) => { setNData(state => ({ ...state, confirmPassword: e.target.value })) }} />
                              <span onClick={togglePasswordVisiblity2} className={passwordShown2 ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}></span>
                           </div>
                           <button className="loginBtn mt-4" type="button" onClick={(e) => {
                              e.preventDefault()
                              resetNewPassword(props?.data?.email, nData?.password, nData.confirmPassword, props.onPress)
                           }}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Create</AppText></button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}