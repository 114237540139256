import { useState } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import PageHeader from "../../components/PageHeader"
import AppTable from "../../components/AppTable"
import * as SVGIcon from '../../assets/svg'
export default () => {
   return (
      <AppLayout active="feedback">
         <div className="">
            <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Feedback</AppText>
            </div>
            <div className="row mb-4">
               <div className="col-12 myTableHeight">
                  <AppTable data={[]} headers={["Provider", "Member", "How was your experience with this provider>", "Q1", "Q2", "Q3", "Q4"]} tableTitle="Completed">
                     <tr className="table-tr">
                        <td className="tdclass4 tableGrid">Emiko Clinton <span className="tdclass2">ID:64738RTE72</span></td>
                        <td className="tdclass4 tableGrid">Increase Clinton <span className="tdclass2">ID:64738RTE72</span></td>
                        <td className="tdclass1">3.0</td>
                        <td className="tdclass3">Yes</td>
                        <td className="tdclass3">Yes</td>
                        <td className="tdclass3">Yes</td>
                        <td className="tdclass3">No</td>
                     </tr>
                  </AppTable>
               </div>
            </div>

            <div className="mb-4">

            </div>
         </div>
      </AppLayout>

   )
}