import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ObjectPayload } from "../typings/general";
import { AdminSliceState, adminStoreSlice } from "./storeslice/admin-slice";
import { AlertSliceState, alertStoreSlice } from "./storeslice/alert-slice";
import { MemberSliceState, memberStoreSlice } from "./storeslice/member-slice";
import {
   DashboardSliceState,
   dashboardStoreSlice,
} from "./storeslice/dashboard-slice";
import {
   SessionSliceState,
   sessionStoreSlice,
} from "./storeslice/session-slice";
import {
   ProviderSliceState,
   providerStoreSlice,
} from "./storeslice/provider-slice";
import {
   TransactionSliceState,
   transactionStoreSlice,
} from "./storeslice/transaction-slice";
import {
   SpecialtySliceState,
   specialtyStoreSlice,
} from "./storeslice/specialty-slice";
import { SettingSliceState, settingStoreSlice } from "./storeslice/settings.slice";

type StoreState = AdminSliceState &
   AlertSliceState &
   MemberSliceState &
   DashboardSliceState &
   SessionSliceState &
   ProviderSliceState &
   TransactionSliceState &
   SpecialtySliceState & SettingSliceState;
export type StoreStateCreator<MyState> = StateCreator<
   MyState,
   [["zustand/immer", never], never],
   [],
   MyState
>;

export const useBoundStore = create<StoreState>()(
   immer<StoreState>((...a) => ({
      ...adminStoreSlice(...a),
      ...alertStoreSlice(...a),
      ...memberStoreSlice(...a),
      ...dashboardStoreSlice(...a),
      ...sessionStoreSlice(...a),
      ...providerStoreSlice(...a),
      ...transactionStoreSlice(...a),
      ...specialtyStoreSlice(...a),
      ...settingStoreSlice(...a),
   }))
);

export const useStoreSelector = (selector: Array<keyof StoreState>) => {
   return useBoundStore((store) =>
      selector.reduce(
         (acc, el) => ({ ...acc, [el]: store[el] }),
         {} as ObjectPayload
      )
   );
};
