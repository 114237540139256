import useMemberFunction from "../hooks/useMemberFunction"
import { useStoreSelector } from "../store/useStore"
import { useEffect } from 'react'
import AppText from "./AppText"
import HeaderCard from "./HeaderCard"
import InnerNavigation from "./InnerNavigation"
import { useParams } from "react-router-dom"
import { ObjectPayload } from "../typings/general"
// const userpic: string = require('../assets/icons/userpic.svg').default
const userpic: string = require('../assets/images/no-avatar.png')
export type DataState = {
   data?: ObjectPayload;
}
export default (data: DataState) => {
   let newImg = data?.data?.avatar && data?.data?.avatar.length > 0 ? data?.data?.avatar : userpic
   return (
      <div className="PageHeaderWrapper">
         <div className='profile-inner mb-4'>
            <div className='imgContainer2'>
               <img src={newImg} />
            </div>
            {/* <div className='imgContainer2'><img src={userpic} /></div> */}
            <div className='myProfile line14'>
               <h1 className="headerH1"><AppText fontSize={'24px'} color="#19191B" fontWeight="700" letterSpacing={'0.15px'}>{data?.data?.name}</AppText></h1>

               {/* <p><AppText fontSize={'16px'} color="#61646B" fontWeight="500" letterSpacing={'0.25px'}>Email: {data?.data?.email}</AppText> </p> */}
               <span className={`marginBottom profileActive ${data?.data?.status === 1 ? "userActive" : data?.data?.status === 2 ? "userReview" : "userPending"}`}>{data?.data?.status === 1 ? "Active" : data?.data?.status === 2 ? "Suspended" : data?.data?.status === 3 ? "Under Review" : "Pending"}</span>

            </div>
         </div>
      </div>
   )
}