import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import Searchfilter, { DropdownState } from "../../components/Searchfilter"
import ProviderActive from "./ProviderTable"
import InnerNavigationFew from "../../components/InnerNavigationFew"
import AppTable from "../../components/AppTable"
import * as SVGIcon from '../../assets/svg'
import { ProviderAPIData } from "../../constants/mockData"
import { useNavigate } from "react-router-dom"
import { ActiveProviderStatus, ProviderStatus } from "../../constants/ProviderSubScreen"
import useProviderFunction from "../../hooks/useProviderFunction"
import { useStoreSelector } from "../../store/useStore"
import Pagination from "../../components/Pagination"
import { ObjectPayload } from "../../typings/general"


export default (props: { type: 'individual' | 'business' }) => {
   // console.log(props)
   const search = window.location.search;
   const accountType = new URLSearchParams(search)?.get('type');;
   const [dropMe, ShowDropMe] = useState<DropdownState>({ show: false })
   const [activePage, setActivePage] = useState(1)
   const [filter, setFilter] = useState<ObjectPayload>({ data1: 1 })
   const Navigation = useNavigate()
   const { fetchProviders, fetchProviderCount } = useProviderFunction()
   const { providerData, isLoading, providerTransactionCount } = useStoreSelector(['providerData', "isLoading", "providerTransactionCount"])

   useEffect(() => {
      if (accountType && accountType.length > 2) {
         fetchProviders(accountType as string, filter?.data1, 1, filter?.startDate, filter?.endDate)
         fetchProviderCount(accountType as string, filter?.data1, 1, filter?.startDate, filter?.endDate)
      }
   }, [accountType, filter?.data1])
   return (
      <AppLayout active="provider" onPress={() => dropMe.show && ShowDropMe({ show: false })}>
         <div className="">
            <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Service Providers</AppText>
            </div>
            <div className="">
               <Searchfilter placeHolder="Search with email"
                  onSearch={(e) => setFilter(d => ({ ...d, ...e }))} show={dropMe.show}
                  onFilterClick={() => ShowDropMe(state => ({ ...state, show: !state.show, data1: ActiveProviderStatus, title1: 'Status' }))} onSelect={(e) => {
                     setFilter(d => ({ ...d, ...e }))
                     ShowDropMe(state => ({ ...state, show: !state.show }))
                  }} />
            </div>
            <div>
               <InnerNavigationFew defaultActive="Active" titles={["Active", "Pending", "Under Review"]} onPress={(item) => {
                  let getStatus = item === "Active" ? 1 : item === "Pending" ? 0 : 2
                  fetchProviders(accountType as string, getStatus, 1, undefined, undefined, filter?.q)
                  fetchProviderCount(accountType as string, getStatus, 1, undefined, undefined)
               }} />
            </div>
            <div className="bg-white maxHeight">
               <div className="myTableHeight">
                  <AppTable
                     data={providerData}
                     isLoading={isLoading}
                     headers={["Name", "Date Registered", "Completed Sessions", "Status"]} tableTitle="">
                     {(providerData || []).map((item: any, i: any) => (
                        <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => Navigation(`/providers/${item?.auth_id}`)}>
                           <td className="tdclass4 tableGrid">{item?.name} <span className="tdclass2">ID:{item?.email}</span></td>
                           <td className="tdclass1">{new Date(item?.createdAt).toDateString()}</td>
                           <td className="tdclass3">{item?.completed_session}</td>
                           <td className="tdclass3">{<span className={`marginBottom profileActive ${item?.status === 1 ? "userActive" : item?.status === 2 ? "userReview" : "userPending"}`}>{item?.status === 1 ? "Active" : item?.status === 2 ? "Suspended" : "Pending"}</span>}</td>
                        </tr>
                     ))}

                  </AppTable>
               </div>
               <Pagination activePage={activePage} totalPage={providerTransactionCount?.total} onPress={(nextPage) => {
                  fetchProviders(accountType as string, filter?.data1, nextPage, filter?.startDate, filter?.endDate, filter?.q)
                  setActivePage(nextPage)
               }} />
            </div>
         </div>
      </AppLayout>

   )
}