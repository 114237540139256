import { useState, useEffect } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import { ObjectPayload } from "../../typings/general"
import { NavLink, useParams } from "react-router-dom"
import { useStoreSelector } from "../../store/useStore"
import useSessionFunction from "../../hooks/useSessionFunction"
import SingleSessionNav from "../../components/SingleSessionNav"
import { SessionProfileSubScreen, SessionProfileSubValues } from "../../constants/SessionSubScreen"
import SessionDetails from "./SessionDetails"
import SessionTools from "./SessionTools"
import Assessment from "./Assessment"
import SessionTreatment from "./SessionTreatment"
import SessionNote from "./SessionNote"
import { WordTemplate } from "./TheTemplate"
let FileSaver = require('file-saver');
const videochat: string = require('../../assets/icons/videochat.svg').default
const voicechat: string = require('../../assets/icons/voicechat.svg').default
const messagechat: string = require('../../assets/icons/messagechat.svg').default
const userpic: string = require('../../assets/images/no-avatar.png')

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
}
export default (data: DataState) => {
   const [screenTab, setScreenTab] = useState('')
   const screenContent = {
      [SessionProfileSubValues.Session]: SessionDetails,
      [SessionProfileSubValues.Assessment]: Assessment,
      [SessionProfileSubValues.Treatment]: SessionTreatment,
      [SessionProfileSubValues.ProgressNote]: SessionNote,
      [SessionProfileSubValues.Tools]: SessionTools,
      ['default']: SessionDetails,
   }
   const ScreenMounted = screenContent[screenTab] || screenContent['default']
   const params = useParams();
   const { fetchSingleSession, fetchSingleSessionAssessment, fetchSingleSessionNote, fetchSingleSessionTools, fetchSingleSessionTreatment } = useSessionFunction()
   const { singleSessionData, singleSessionAssData, singleSessionNote, singleSessionTools, singleSessionTreatment } = useStoreSelector(["singleSessionData", "singleSessionAssData", "singleSessionNote", "singleSessionTools", "singleSessionTreatment"])
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, data: Array<any>, status: number }>({ isLoading: false, data: [], status: 1 })
   useEffect(() => {
      fetchSingleSession(params?.ID as any)
      fetchSingleSessionAssessment(params?.ID as any)
      fetchSingleSessionNote(params?.ID as any)
      fetchSingleSessionTools(params?.ID as any)
      fetchSingleSessionTreatment(params?.ID as any)
   }, [])
   const exportData = () => {
      let data = WordTemplate(singleSessionAssData, singleSessionNote, singleSessionTools, singleSessionTreatment)

      let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, useData?.member_data?.name ? useData?.member_data?.name + '.doc' : "file.doc");

   }

   let useData = singleSessionData
   // console.log(useData)
   const MappArray = (data1: Array<any>, data2: Array<any>) => {
      if (!data1 || !data2.length) {
         return
      }
      let timeData = []
      for (let i = 0; i < data1.length; i++) {
         timeData.push(`${data1[i]} - ${data2[i]}`)
      } return timeData.join(", ")
   }

   const Qanswer = (data: any) => {
      const result = Array.isArray(data);
      let answer: any
      if (result) {
         let newData = []
         for (let i = 0; i < data.length; i++) {
            newData.push(data[i])
            answer = newData.join(", ")
         }
      } else {
         answer = data
      }

      return answer
   }

   let newUserImg = useData?.member_data?.avatar && useData?.member_data?.avatar.length > 0 ? data?.data?.avatar : userpic
   let newProviderImg = useData?.provider_data?.avatar && useData?.provider_data?.avatar.length > 0 ? useData?.provider_data?.avatar : userpic
   return (
      <AppLayout active="sessions">
         <div className="">
            <div className="mb-2">
               <div className="singleSessionNavHome">
                  <SingleSessionNav defaultActive="Session Details" titles={SessionProfileSubScreen} onPress={(item) => {
                     setScreenTab(item.value as any)
                  }} />
               </div>
            </div>
            <div className="row mb-4">
               <div className="col-12">
                  <div className="myTable w-100 mb-4">
                     <div className="">
                        <div className="row">
                           <div className="col-lg-6 col-12">
                              <div className="d-flex alignCenter">
                                 <div className="PageHeaderWrapper">
                                    <NavLink to={`/members/${useData?.member_id}`} className="textDecor">
                                       <div className='mb-4 mt-4 myNavDecor'>
                                          <div className='mb-4 mt-4 imageClassWidth'>
                                             <div className='imgContainer2 mb-2'>
                                                <img src={newUserImg} />
                                             </div>
                                          </div>
                                       </div>
                                    </NavLink>
                                 </div>
                                 <div className="sessionTableHead">
                                    <div className="mt20">
                                       <div className="d-flex marginBTM5">
                                          <AppText fontSize={'16px'} marginRight={'10px'} fontWeight="500" color="#61646B">Name: </AppText>
                                          <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.member_data?.name}</AppText>
                                       </div>
                                       <div className="d-flex marginBTM5">
                                          <AppText fontSize={'16px'} marginRight={'10px'} fontWeight="500" color="#61646B">Email: </AppText>
                                          <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.member_data?.email}</AppText>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-6 col-12">
                              <div className="d-flex alignCenter">
                                 <div className="PageHeaderWrapper">
                                    <NavLink to={`/providers/${useData?.provider_id}`} className="textDecor">
                                       <div className='mb-4 mt-4 myNavDecor'>
                                          <div className='mb-4 mt-4 imageClassWidth'>
                                             <div className='imgContainer2 mb-2'>
                                                <img src={newProviderImg} />
                                             </div>
                                          </div>
                                       </div>
                                    </NavLink>
                                 </div>
                                 <div className="sessionTableHead">
                                    <div className="mt20">
                                       <div className="d-flex marginBTM5">
                                          <AppText fontSize={'16px'} marginRight={'10px'} fontWeight="500" color="#61646B">Name: </AppText>
                                          <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.provider_data?.name}</AppText>
                                       </div>
                                       <div className="d-flex marginBTM5">
                                          <AppText fontSize={'16px'} marginRight={'10px'} fontWeight="500" color="#61646B">Email: </AppText>
                                          <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.provider_data?.email}</AppText>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {useData?.member_data ?
                              <div className="col-lg-6 mb-2">
                                 <div className="d-flex alignCenter">
                                    <div className="PageHeaderWrapper" style={{ marginLeft: '7%' }}>
                                       <button className="loginBtn2" type="button" onClick={(e) => {
                                          e.preventDefault()
                                          exportData()
                                       }}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Export Session Data</AppText></button>
                                    </div>
                                 </div>
                              </div> : ""}
                        </div>
                     </div>
                  </div>
               </div>
               {useData?.business_business_data || useData.provider_business_data ?
                  <div className="col-12">
                     <div className="myTable w-100 mb-4">
                        <div className="">
                           <div className="row">
                              {useData.provider_business_data ?
                                 <div className="col-lg-6 col-12">
                                    <div className="d-flex alignCenter">
                                       <div className="PageHeaderWrapper">
                                          {/* <h1 className="headerH1"><AppText fontSize={'18px'} color="#61646B" fontWeight="500" letterSpacing={'0.15px'}>Member</AppText></h1> */}
                                          <NavLink to={`/members/${useData?.provider_business_data?.auth_id}`} className="textDecor">
                                             <div className='mb-4 mt-4 myNavDecor'>
                                                <div className='mb-4 mt-4 imageClassWidth'>
                                                   <div className='imgContainer2 mb-2'>
                                                      <img src={useData?.provider_business_data?.avatar} />
                                                   </div>
                                                </div>
                                             </div>
                                          </NavLink>
                                       </div>
                                       <div className="sessionTableHead">
                                          <div className="mt20">
                                             <div className="d-flex marginBTM5">
                                                <AppText fontSize={'16px'} marginRight={'10px'} fontWeight="500" color="#61646B">Name: </AppText>
                                                <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.provider_business_data?.name}</AppText>
                                             </div>
                                             <div className="d-flex marginBTM5">
                                                <AppText fontSize={'16px'} marginRight={'10px'} fontWeight="500" color="#61646B">Email: </AppText>
                                                <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.provider_business_data?.email}</AppText>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div> : ''}
                              {useData.business_business_data ?
                                 <div className="col-lg-6 col-12">
                                    <div className="d-flex alignCenter">
                                       <div className="PageHeaderWrapper">
                                          {/* <h1 className="headerH1"><AppText fontSize={'18px'} color="#61646B" fontWeight="500" letterSpacing={'0.15px'}>Provider</AppText></h1> */}
                                          <NavLink to={`/providers/${useData?.business_business_data?.auth_id}`} className="textDecor">
                                             <div className='mb-4 mt-4 myNavDecor'>
                                                <div className='mb-4 mt-4 imageClassWidth'>
                                                   <div className='imgContainer2 mb-2'>
                                                      <img src={useData?.business_business_data?.avatar} />
                                                   </div>
                                                </div>
                                             </div>
                                          </NavLink>
                                       </div>
                                       <div className="sessionTableHead">
                                          <div className="mt20">
                                             <div className="d-flex marginBTM5">
                                                <AppText fontSize={'16px'} marginRight={'10px'} fontWeight="500" color="#61646B">Name: </AppText>
                                                <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.business_business_data?.name}</AppText>
                                             </div>
                                             <div className="d-flex marginBTM5">
                                                <AppText fontSize={'16px'} marginRight={'10px'} fontWeight="500" color="#61646B">Email: </AppText>
                                                <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.business_business_data?.email}</AppText>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div> : ''}
                           </div>
                        </div>
                     </div>
                  </div> : ''}

               <div className="">
                  <ScreenMounted />
               </div>

            </div>
         </div>
      </AppLayout>

   )
}