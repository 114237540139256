import { useNavigate } from "react-router-dom";
import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";
import { ObjectPayload } from "../typings/general";

export default () => {
   const navigate = useNavigate();
   const { setAlert, setIsLoading, setSettingData, setSettingKey } = useStoreSelector([
      "setAlert",
      "setIsLoading",
      "setSettingData",
      "setSettingKey"
   ]);
   const getSettings = async () => {
      //  helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/system-setting`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSettingData(sendRequest.data.data);
   };
   const getSettingKey = async () => {
      //  helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/system-setting?component=data-key`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSettingKey(sendRequest.data.data);
   };

   const newSettings = async (payload: ObjectPayload, onPress?: Function) => {
      //  setIsLoading(true);

      if (!payload || Object.keys(payload).length === 0) {
         return helpers.showToast("error", "Setting key is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/system-setting`,
            method: "POST", json: payload,
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSettings();
      helpers.showToast("success", "Setting was created successful");
      onPress && onPress();
   };

   const deleteSetting = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/system-setting/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSettings();
      helpers.showToast("success", "Setting was deleted successful");
      onPress && onPress();
   };

   return {
      getSettings,
      newSettings,
      deleteSetting,
      getSettingKey
   };
};
