import react, { useState } from 'react'
import { NavLink } from "react-router-dom"
import AppText from "./AppText"
import * as SVGIcon from '../assets/svg'
import { useStoreSelector } from '../store/useStore'
import ProfileHeader from './ProfileHeader'
import helpers from '../utils/helpers'
const notice: string = require('../assets/icons/notice.svg').default
// const profile: string = require('../assets/icons/profile.svg').default
const profile: string = require('../assets/images/no-avatar.png')
export type DropdownState = {
   show?: boolean;
   onSortClick?: Function;
   onSelect?: ((e: any) => void) | undefined
}

export default (props: DropdownState) => {
   const { userData } = useStoreSelector(["userData"])
   const [showD, setShowD] = useState(false)
   // console.log(userData)
   return (
      <div className="mainHeader">
         <div className="row oneRow">
            <div className="col-lg-8 col-12">
               <div className='helloName line05'>
                  <h1 className="headerH1"><AppText fontSize={'20px'} color="#0A0A0A" fontWeight="600" letterSpacing={'0.15px'}>Hello, {userData?.name}</AppText> <span className="userActive">Active</span></h1>
                  <p><AppText fontSize={'14px'} color="#757575" fontWeight="400" letterSpacing={'0.25px'}>Have a nice day</AppText></p>
               </div>
            </div>
            <div className="col-1 gohidden gohidden22">
               <div className='bell'>
                  <NavLink to="/transactions"><img src={notice} /></NavLink>
               </div>
            </div>
            <div className="col-3 gohidden gohidden22">
               <div className='go-profile'>
                  <div className='profile-inner'>
                     {/* <div className='imgContainer' style={{ borderRadius: '50%', overflow: 'hidden' }}><img src='https://msmtavatarstorage.blob.core.windows.net/profile-picture/6558a1177875b87e8a4c7081.jpg' /></div> */}
                     <div className='imgContainer' style={{ borderRadius: '50%', overflow: 'hidden' }}><img src={userData?.avatar ? userData?.avatar : profile} /></div>
                     <div className='myProfile line09'>
                        <h1 className="headerH1"><AppText fontSize={'20px'} color="#0A0A0A" fontWeight="600" letterSpacing={'0.15px'}>{userData?.name}</AppText></h1>

                        <p><AppText fontSize={'14px'} color="#757575" fontWeight="400" letterSpacing={'0.25px'}>{userData?.user_type ? helpers.toUpperCase(userData?.user_type) : 'Admin'}</AppText></p>
                        {/* <p><AppText fontSize={'14px'} color="#757575" fontWeight="400" letterSpacing={'0.25px'}>{userData?.user_type ? helpers.toUpperCase(userData?.user_type) : 'Admin'}</AppText>  <span className='profileDropdown cursor-pointer n-20' onClick={() => setShowD(!showD)}><SVGIcon.ArrowFaceDown /> </span></p> */}
                        {/* <div className='' style={{ position: 'relative' }}>
                           {showD &&
                              <ProfileHeader data1={[{ name: 'Settings', value: 'Settings' }, { name: 'Notifications', value: 'Notifications' }]} containerStyle={{ marginTop: '30px' }} onPress={props.onSelect} />
                           }
                        </div> */}

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}