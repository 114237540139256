import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import PageHeader from "../../components/PageHeader"
import AppTable from "../../components/AppTable"
import * as SVGIcon from '../../assets/svg'
import BorderNavigation from "../../components/BorderNavigation"
import InnerNavigation from "../../components/InnerNavigation"
import PageHeaderBalance from "../../components/PageHeaderBalance"
import { ProviderProfileSubScreen, ProviderProfileSubValues, ProviderStatus } from "../../constants/ProviderSubScreen"
import { useState, useEffect } from "react"
import ProviderProfile from "./ProviderProfile"
import ProviderSession from "./ProviderSession"
import SessionFilter from "../../components/SessionFilter"
import Searchfilter, { DropdownState } from "../../components/Searchfilter"
import ProviderSupport from "./ProviderSupport"
import ProviderService from "./ProviderService"
import ProviderSetting from "./ProviderSetting"
import ProviderContent from "./ProviderContent"
import ProviderFeedback from "./ProviderFeedback"
import ProviderEducation from "./ProviderEducation"
import useProviderFunction from "../../hooks/useProviderFunction"
import { useStoreSelector } from "../../store/useStore"
import { useParams } from "react-router-dom"
import ProviderTransaction from "./ProviderTransaction"
import ProviderAvailable from "./ProviderAvailable"
import ProviderDocument from "./ProviderDocument"
export default () => {
   const params = useParams();
   const [dropMe, ShowDropMe] = useState<DropdownState>({ show: false })
   const [screenTab, setScreenTab] = useState('')
   const [filter, setFilter] = useState({})
   const screenContent = {
      [ProviderProfileSubValues.profile]: ProviderProfile,
      [ProviderProfileSubValues.sessions]: ProviderSession,
      // [ProviderProfileSubValues.support]: ProviderSupport,
      [ProviderProfileSubValues.services]: ProviderService,
      // [ProviderProfileSubValues.settings]: ProviderSetting,
      // [ProviderProfileSubValues.content]: ProviderContent,
      [ProviderProfileSubValues.document]: ProviderDocument,
      // [ProviderProfileSubValues.feedback]: ProviderFeedback,
      [ProviderProfileSubValues.education]: ProviderEducation,
      [ProviderProfileSubValues.transaction]: ProviderTransaction,
      [ProviderProfileSubValues.availability]: ProviderAvailable,
      ['default']: ProviderProfile,
   }
   const ScreenMounted = screenContent[screenTab] || screenContent['default']

   const { fetchSingleProvider, fetchSingleProviderSession, fetchProviderSessionCount } = useProviderFunction()
   const { singleProviderData } = useStoreSelector(["singleProviderData"])
   useEffect(() => {
      fetchSingleProvider(params?.ID as any)
   }, [])
   let accountType = singleProviderData?.account_type ? singleProviderData?.account_type : 'individual'
   // console.log(singleProviderData)
   return (
      <AppLayout active="provider">
         <div className="position-relative">
            {/* <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Sessions</AppText>
            </div> */}
            <div className="">
               <PageHeader data={singleProviderData} />
               <PageHeaderBalance />
            </div>

            <div className="row mb-4 containerSticky">

               <div className="mb-3">
                  <AppText fontSize={'24px'} color="#19191B" fontWeight="700">{screenTab.charAt(0).toUpperCase() + screenTab.slice(1) || 'Profile'}</AppText>
               </div>
               {screenTab === ProviderProfileSubValues.sessions &&
                  <div>
                     <Searchfilter onSearch={(e) => {
                        setFilter(e)
                        fetchSingleProviderSession(params?.ID as any, accountType, e?.data1, 1, e?.startDate, e?.endDate, e?.q)
                     }} placeHolder="Search with booking ID" show={dropMe.show} title1="Status" data1={ProviderStatus}
                        onFilterClick={() => ShowDropMe(state => ({ ...state, show: !state.show, data1: ProviderStatus, title1: 'Status' }))} onSelect={(e) => {
                           setFilter(e)
                           ShowDropMe(state => ({ ...state, show: !state.show }))
                           fetchSingleProviderSession(params?.ID as any, accountType, e?.data1, 1, e?.startDate, e?.endDate)
                           fetchProviderSessionCount(params?.ID as any, accountType, e?.data1, 1, e?.startDate, e?.endDate)
                        }} />
                  </div>}
               <div className="col-12">
                  <div className="bg-white border-radius-20 pt-2">
                     <div className="row">
                        <div className="col-12 col-lg-3 col-xl-2 provider-sidebar">
                           <InnerNavigation defaultActive="profile" titles={ProviderProfileSubScreen} onPress={(item) => setScreenTab(item.value as any)} />
                        </div>
                        <div className="col-12 col-lg-9 col-xl-10">
                           <ScreenMounted filterData={filter} accountType={singleProviderData?.account_type} />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="mb-4">

            </div>
         </div>
      </AppLayout>

   )
}