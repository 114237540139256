import { Routes, RouteObject } from "react-router-dom"
import Settings from "../pages/settings/SettingsIndex"


export const SettingsRoute: RouteObject = {
   path: "/settings",
   children: [
      {
         path: "/settings",
         element: <Settings />
      },
   ]
}