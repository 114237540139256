import { useState, useEffect } from "react"
import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import { DoctorAppointment } from "../../constants/mockData";
import { useNavigate, useParams } from "react-router-dom";
import helpers from "../../utils/helpers";
import { useStoreSelector } from "../../store/useStore";
import SessionModal from "../../components/SessionModal";
import useProviderFunction from "../../hooks/useProviderFunction";
import Pagination from "../../components/Pagination";

export default () => {
   const params = useParams();
   const [activePage, setActivePage] = useState(1)
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, status: any, data: Array<any> }>({ isLoading: false, status: '', data: [] })
   // useEffect(() => {
   //    setDataArray(state => ({ ...state, isLoading: false, data: DoctorAppointment.filter(e => e.userId === params.ID) }))
   // }, [])
   const { fetchSingleProviderTransaction, fetchProviderTransactionCount } = useProviderFunction()
   const { singleProviderTransaction, isLoading, singleProviderTransactionCount } = useStoreSelector(["singleProviderTransaction", "isLoading", "singleProviderTransactionCount"])
   useEffect(() => {
      fetchSingleProviderTransaction(params?.ID as any)
      fetchProviderTransactionCount(params?.ID as any)
   }, [])
   return (
      <div>
         <div className="bg-white">
            <div className="myTableHeight">
               <AppTable data={singleProviderTransaction} isLoading={isLoading} headers={["Transaction", "Date", "Amount", "Status"]} tableTitle="">
                  {(singleProviderTransaction || []).map((item: any, i: any) => (
                     <tr className="table-tr cursor-hover" key={i}>
                        <td className="tdclass4 tableGrid">{helpers.replaceUnderDashToUpper(item?.transaction_type)}</td>
                        <td className="tdclass1">{new Date(item.createdAt).toDateString()} {helpers.get24HourTo12Hours((item.createdAt))}</td>
                        <td className="tdclass4">{helpers.getNairaSign() + helpers.formatMoney(item?.amount || 0)}</td>
                        <td className="tdclass3">{<span className={`marginBottom profileActive ${item?.status === 1 ? "userActive" : item?.status === 2 ? "userReview" : "userPending"}`}>{item?.status === 1 ? "credit" : item?.status === 2 ? "failed" : "debit"}</span>}</td>
                     </tr>
                  ))}
               </AppTable>
            </div>
            <Pagination activePage={activePage} totalPage={singleProviderTransactionCount?.total} onPress={(nextPage) => {
               fetchProviderTransactionCount(params?.ID as any, dataArray?.status, nextPage)
               setActivePage(nextPage)
            }} />
         </div>
      </div>
   )
}