import react from 'react'
import AppText from "./AppText"

interface CardProps {
   cardImg: string,
   cardTitle: string,
   cardNumber: number | string
}

export default (props: CardProps) => {
   return (
      <div className="col-2 col-20">
         <div className='dash-card'>
            <img src={props.cardImg} />
            <div className='line09 mt-4'>
               <p><AppText fontSize={'16px'} color="#61646B" fontWeight="500">{props.cardTitle}</AppText></p>
               <p><AppText fontSize={'20px'} color="#222B45" fontWeight="700">{props.cardNumber}</AppText></p>
            </div>
         </div>
      </div>
   )
}