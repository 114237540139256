import { useState, useEffect } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import { ObjectPayload } from "../../typings/general"
import { NavLink, useParams } from "react-router-dom"
import { useStoreSelector } from "../../store/useStore"
import useSessionFunction from "../../hooks/useSessionFunction"
import helpers from "../../utils/helpers"
import SingleSessionNav from "../../components/SingleSessionNav"
import AppTable from "../../components/AppTable"
const videochat: string = require('../../assets/icons/videochat.svg').default
const voicechat: string = require('../../assets/icons/voicechat.svg').default
const messagechat: string = require('../../assets/icons/messagechat.svg').default


export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
}
export default (data: DataState) => {
   // console.log(data)
   const params = useParams();
   const { fetchSingleSession } = useSessionFunction()
   const { singleSessionData } = useStoreSelector(["singleSessionData"])
   useEffect(() => {
      fetchSingleSession(params?.ID as any)
   }, [])
   // console.log(singleSessionData)
   let useData = singleSessionData


   const MappArray = (data1: Array<any>, data2: Array<any>) => {
      if (!data1 || !data2.length) {
         return
      }
      let timeData = []
      for (let i = 0; i < data1.length; i++) {
         timeData.push(`${data1[i]} - ${data2[i]}`)
      } return timeData.join(", ")
   }
   const MappArrayTime = (data1: Array<any>) => {
      if (!data1) {
         return
      }
      let timeData = []
      for (let i = 0; i < data1.length; i++) {
         timeData.push(`${data1[i].start_time} - ${data1[i].end_time}`)
      } return timeData.join(", ")
   }
   const MappArrayDate = (data1: Array<any>) => {
      if (!data1) {
         return
      }
      let dateData = []
      for (let i = 0; i < data1.length; i++) {
         dateData.push(`${data1[i].appt_date}`)
      } return dateData.join(", ")
   }

   const Qanswer = (data: any) => {
      const result = Array.isArray(data);
      let answer: any
      if (result) {
         let newData = []
         for (let i = 0; i < data.length; i++) {
            newData.push(data[i])
            answer = newData.join(", ")
         }
      } else {
         answer = data
      }

      return answer
   }
   return (
      <div className="mb-4">
         <div className="col-12">
            <div className="myTable w-100 mb-4" style={{ padding: '30px' }}>
               <div className="">
                  <div className="row mt-2">
                     <div className="col-12 mb-4">
                        <div className='row mt-4'>
                           {/* <div className='col-lg-4 col-md-6 col-12'>
                              <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Amount/hour</AppText>

                              <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {helpers.getNairaSign() + helpers.formatMoney(useData?.amount_per_hour || 0.00)}/hour</AppText>

                           </div> */}
                           <div className='col-lg-4 col-md-6 col-12'>
                              <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Grand Total</AppText>

                              <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {helpers.getNairaSign() + helpers.formatMoney(useData?.amount || 0.00)}</AppText>

                           </div>
                           <div className='col-lg-4 col-md-6 col-12'>
                              <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Booking Ref</AppText>

                              <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {useData?.booking_ref}</AppText>

                           </div>

                        </div>
                        <div className='row mt-4'>
                           <div className='col-4'>
                              <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Service Offer</AppText>
                              <div className="mt-2">
                                 <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}><span className="marginBottom">{helpers.toUpperCase(useData?.service_data?.service_offer || "Behavioral Therapist")}</span></AppText>
                              </div>

                           </div>
                           <div className='col-4'>
                              <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Category</AppText>
                              <div className='d-flex mt-2'>
                                 {(useData?.service_data?.service_category || []).map((item: any, i: any) => (
                                    <div className='d-flex' style={{ marginRight: '5px' }} key={i}>
                                       <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}><span className="marginBottom">{helpers.toUpperCase(item || "Behavioral Therapist")}</span></AppText>
                                    </div>
                                 ))}

                              </div>
                           </div>
                        </div>
                        <div className='row mt-4'>
                           <div className='col-4'>
                              <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Date</AppText>

                              {/* <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {new Date(useData?.appt_start_date[0]).toDateString()}</AppText> */}
                              <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {useData?.appt_date}</AppText>

                           </div>
                           <div className='col-4'>
                              <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Time</AppText>

                              <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {helpers.get24HourTo12Hours(useData?.appt_schedule)}</AppText>

                           </div>
                           <div className='col-4'>
                              <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Mode of Comm</AppText>
                              <div className='d-flex'>
                                 {useData?.comm_mode ?
                                    <div className='d-flex' style={{ marginRight: '5px' }}>
                                       <img width={'20px'} src={useData?.comm_mode === 'video' ? videochat : useData?.comm_mode === 'audio' ? voicechat : messagechat} />
                                       <AppText fontSize={'16px'} marginLeft={'5px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>{useData?.comm_mode}</AppText>
                                    </div>
                                    : ''}

                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
         {useData?.member_questionnaire && useData?.member_questionnaire.length > 0 &&
            <div className="col-12">
               <div className="myTable w-100 mb-4" style={{ padding: '30px' }}>
                  <div>
                     <div className='w-100'>
                        <div className='row'>
                           {(useData?.member_questionnaire || []).map((itemV: any, i: any) => (
                              <div className="col-lg-6 col-12 mb-4">
                                 <div className="paddin2">
                                    <div className="row">
                                       <div className="col-12 mb-2">
                                          <span className="go10">{itemV.title}</span>
                                          <AppText fontSize={'16px'} fontWeight="500" color="#61646B">{itemV?.question_header ? helpers.replaceUnderscore(itemV?.question_header) : helpers.replaceUnderscore(itemV?.question)}</AppText>
                                       </div>
                                       <div className="col-12">
                                          <span className="go10">Answer</span>
                                          <AppText fontSize={'16px'} fontWeight="400" color="#000">{Qanswer(itemV?.answer)}</AppText>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
            </div>}
         {useData?.member_rated && useData?.member_rated?.rating &&
            <div className="col-12">
               <div className="myTable w-100 mb-4" style={{ padding: '30px' }}>
                  <div>
                     <div className='w-100'>
                        <div className='row'>

                           <div className="col-lg-6 col-12 mb-4">
                              <div className="paddin2">
                                 <div className="row">
                                    <div className="col-12 mb-2">
                                       {/* <span className="go10">{useData?.member_rated?.rating}/star(s)</span> */}
                                       {
                                          useData?.member_rated?.rating === 1 ?
                                             <div className='row mt-4'>
                                                <div className='d-flex mb-2'>
                                                   <div className="myRatings starMargin mtTop0">
                                                      <span className="fa fa-star checked"></span>
                                                      <span className="fa fa-star"></span>
                                                      <span className="fa fa-star"></span>
                                                      <span className="fa fa-star"></span>
                                                      <span className="fa fa-star"></span>
                                                   </div>
                                                   <AppText fontSize={'18px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>{useData?.member_rated?.rating}</AppText>
                                                </div>
                                             </div>
                                             : useData?.member_rated?.rating === 2 ?
                                                <div className='row mt-4'>
                                                   <div className='d-flex mb-2'>
                                                      <div className="myRatings starMargin mtTop0">
                                                         <span className="fa fa-star checked"></span>
                                                         <span className="fa fa-star checked"></span>
                                                         <span className="fa fa-star"></span>
                                                         <span className="fa fa-star"></span>
                                                         <span className="fa fa-star"></span>
                                                      </div>
                                                      <AppText fontSize={'18px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>{useData?.member_rated?.rating}</AppText>
                                                   </div>
                                                </div>
                                                : useData?.member_rated?.rating === 3 ?
                                                   <div className='row mt-4'>
                                                      <div className='d-flex mb-2'>
                                                         <div className="myRatings starMargin mtTop0">
                                                            <span className="fa fa-star checked"></span>
                                                            <span className="fa fa-star checked"></span>
                                                            <span className="fa fa-star checked"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                         </div>
                                                         <AppText fontSize={'18px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>{useData?.member_rated?.rating}</AppText>
                                                      </div>
                                                   </div>
                                                   : useData?.member_rated?.rating === 4 ?
                                                      <div className='row mt-4'>
                                                         <div className='d-flex mb-2'>
                                                            <div className="myRatings starMargin mtTop0">
                                                               <span className="fa fa-star checked"></span>
                                                               <span className="fa fa-star checked"></span>
                                                               <span className="fa fa-star checked"></span>
                                                               <span className="fa fa-star checked"></span>
                                                               <span className="fa fa-star"></span>
                                                            </div>
                                                            <AppText fontSize={'18px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>{useData?.member_rated?.rating}</AppText>
                                                         </div>
                                                      </div>
                                                      : useData?.member_rated?.rating === 5 ?
                                                         <div className='row mt-4'>
                                                            <div className='d-flex mb-2'>
                                                               <div className="myRatings starMargin mtTop0">
                                                                  <span className="fa fa-star checked"></span>
                                                                  <span className="fa fa-star checked"></span>
                                                                  <span className="fa fa-star checked"></span>
                                                                  <span className="fa fa-star checked"></span>
                                                                  <span className="fa fa-star checked"></span>
                                                               </div>
                                                               <AppText fontSize={'18px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>{useData?.member_rated?.rating}</AppText>
                                                            </div>
                                                         </div> : ''
                                       }
                                       <AppText fontSize={'16px'} fontWeight="500" color="#61646B">{useData?.member_rated?.comment}</AppText>
                                    </div>
                                    <div className="col-12">
                                       <span className="go10">Was Provider on time?</span>
                                       <AppText fontSize={'16px'} fontWeight="400" color="#000">{useData?.member_rated?.provider_on_time === true ? "Yes" : "No"}</AppText>
                                    </div>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>}

      </div>

   )
}