import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import AppText from './AppText';
import { ObjectPayload } from '../typings/general';
import useAdminFunction from '../hooks/useAdminFunction';
const closebtn: string = require('../assets/icons/closebtn.svg').default


type DropdownItems = {
   name?: string;
   value?: any;
   onPress?: Function;
}
interface DropdownOptions {
   onPress?: ((e: DropdownItems) => void) | undefined;
   containerStyle?: CSSProperties;
   title1?: string;
   title2?: string;
   data1?: Array<DropdownItems>;
   data2?: Array<DropdownItems>;
}

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}
const RoleData = [
   { value: "view", label: "view" },
   { value: "update", label: "update" },
   { value: "delete", label: "delete" },
   { value: "create", label: "create" }
]

export const Role = (props: any) => {
   return (
      <div className='d-flex spaceRounded'>
         <div className='row'>
            {(props.RoleData || []).map((item: any, i: any) => (
               <div className="col-6">
                  <div className="form-check form-switch goML801">
                     <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={props?.mainRole?.includes(item?.value)} value={item.value} onChange={(e) => {
                        props.onPress(e)

                     }} />
                     <label> {item.label}</label>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}
export default (props: DataState) => {
   const { updateAdmin } = useAdminFunction()
   const [comState, setComState] = useState<{ data: any, role: Array<any> }>({ data: { name: props?.data?.name, email: props?.data?.email, phoneNumber: props?.data?.phone_number, gender: props?.data?.gender }, role: props?.data?.role_list })



   const handleCheck = (event: any) => {
      let permissions_array = comState.role || [];
      if (event.target.checked) {
         permissions_array.push(event.target.value)
         // permissions_array = [...permissions, event?.target?.value];
      } else {
         permissions_array = permissions_array.filter((e) => e != event.target.value)
         // permissions_array.splice(permissions.indexOf(event.target.value), 1);
      }
      // setPermissions(permissions_array);
      setComState(state => ({ ...state, role: permissions_array }))
   };
   return (
      <div style={{
         position: 'fixed', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)', width: '100%', height: '100%', zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '400px', ...props.containerStyle }}>
            {/* <div style={{ backgroundColor: '#fff', borderRadius: '20px', padding: '20px', width: '250px', boxShadow: '2px 1px 2px #eee', ...props.containerStyle }}> */}
            <div className='bg-white dropdownMainNew max600 dropdownMain22' style={{ marginTop: '10px' }}>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className='popupText1 mb-2 d-flex spaceRounded'>
                        <AppText marginBottom={'13px'} marginTop={'10px'} fontSize={'24px'} color='#19191B'>Update User</AppText>
                        <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '28px' }} className="fa fa-times m-color-red cursor-pointer cursor-hover" aria-hidden="true"></i>

                     </div>
                     <form method="post">
                        <div className="containerForm">
                           <div className='row'>
                              <div className='col-12 mb-2'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Name of user</AppText>
                                    <input className='my-form-control' defaultValue={comState?.data?.name} type="text" placeholder="e.g: Name of user" onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, name: e.target.value } }))
                                    }} />
                                 </div>
                              </div>
                              <div className='col-6 mb-2'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Email of user</AppText>
                                    <input className='my-form-control' defaultValue={comState?.data?.email} type="email" placeholder="e.g: admin@admin" onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, email: e.target.value } }))
                                    }} />
                                 </div>
                              </div>
                              <div className='col-6 mb-2'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Phone of user</AppText>
                                    <input className='my-form-control' defaultValue={comState?.data?.phoneNumber} type="tel" placeholder="e.g: 23470619xxxx" onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, phoneNumber: e.target.value } }))
                                    }} />
                                 </div>
                              </div>

                              <div className='col-6 mb-2'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Assign Permission</AppText>
                                    <Role RoleData={RoleData} mainRole={comState?.role} onPress={handleCheck} />

                                 </div>
                              </div>
                              <div className='col-6 mb-2'>
                                 <div className="w-100 mb-2">
                                    <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Gender</AppText>
                                    <select defaultValue={comState?.data?.gender} onChange={(e) => {
                                       setComState(state => ({ ...state, data: { ...state.data, gender: e.target.value } }))
                                    }}>
                                       <option value="">Select a gender</option>
                                       <option value="male">Male</option>
                                       <option value="female">Female</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                           <button className="loginBtn mt-4" type="button" onClick={(e) => {
                              e.preventDefault()
                              updateAdmin(comState?.data, comState?.role, props?.onPress, props?.data?.auth_id)
                           }}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Update User</AppText></button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}