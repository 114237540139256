import HeaderCard from "./HeaderCard"

export default () => {
   return (
      <div className="pageHeaderCards mb-4">
         <div className="row">
            <HeaderCard cardTitle="Open" cardNumber={'70'} />
            <HeaderCard cardTitle="Resoloved Tickets" cardNumber={'50'} />
         </div>
      </div>
   )
}