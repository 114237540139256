import AppText from "./AppText"
const userpic: string = require('../assets/icons/userpic.svg').default
export default () => {
   return (
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
         <div className='profile-inner'>
            <div className='imgContainer2 imgContainerChat'><img src={userpic} /></div>
            <div className='myProfile line14 mln-8'>
               <h1 className="headerH1 mb-n2"><AppText fontSize={'14px'} color="#000" fontWeight="600" letterSpacing={'0.15px'}>David Ojo</AppText></h1>
               <p> <AppText fontSize={'12px'} color="#000" fontWeight="600" letterSpacing={'0.25px'} opacity={'0.6'}>ID:7478478</AppText> </p>
            </div>
         </div>
         <p> <AppText fontSize={'12px'} color="#000" fontWeight="600" letterSpacing={'0.25px'} opacity={'0.6'}>12m</AppText> </p>
      </div>
   )
}