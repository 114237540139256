import { useState, useEffect } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import { ObjectPayload } from "../../typings/general"
import { NavLink, useParams } from "react-router-dom"
import { useStoreSelector } from "../../store/useStore"
import useSessionFunction from "../../hooks/useSessionFunction"
import helpers from "../../utils/helpers"
import SingleSessionNav from "../../components/SingleSessionNav"
import AppTable from "../../components/AppTable"
import InnerNavigation from "../../components/InnerNavigation"
import InnerNavigationFew from "../../components/InnerNavigationFew"
import AssessmentTab from "../../components/AssessmentTab"

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
}
export default (data: DataState) => {
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, data: Array<any>, status: number }>({ isLoading: false, data: [], status: 1 })
   const [dataArrayQ, setDataArrayQ] = useState<{ data: any }>({ data: [] })
   const params = useParams();
   const { fetchSingleSession, fetchSingleSessionTools } = useSessionFunction()
   const { singleSessionData, singleSessionTools } = useStoreSelector(["singleSessionData", "singleSessionTools"])
   useEffect(() => {
      fetchSingleSession(params?.ID as any)
      fetchSingleSessionTools(params?.ID as any)
   }, [])
   // console.log(singleSessionTools)
   let useData = singleSessionData
   const MappArray = (data1: Array<any>, data2: Array<any>) => {
      if (!data1 || !data2.length) {
         return
      }
      let timeData = []
      for (let i = 0; i < data1.length; i++) {
         timeData.push(`${data1[i]} - ${data2[i]}`)
      } return timeData.join(", ")
   }

   const ArrayMenu = () => {
      if (!singleSessionTools || !singleSessionTools.length) {
         return []
      }
      let newMyArray = []
      for (let i = 0; i < singleSessionTools.length; i++) {
         newMyArray.push(`${singleSessionTools[i]}`)
      } return newMyArray
   }


   const Qanswer = (data: any) => {
      const result = Array.isArray(data);
      let answer: any
      if (result) {
         let newData = []
         for (let i = 0; i < data.length; i++) {
            newData.push(data[i])
            answer = newData.join(", ")
         }
      } else {
         answer = data
      }

      return answer
   }
   // console.log(dataArrayQ)
   return (
      <div className="mb-4">
         <div className="col-12">
            <div className="myTable w-100 mb-4">
               <div style={{ padding: '30px' }}>

                  <div className="row">
                     <div className="col-12">
                        <div className="row">
                           <div className="col-lg-8 col-12">
                              <AppText fontSize={'20px'} fontWeight="500" color="#61646B">{dataArrayQ.data?.data_tab && dataArrayQ?.data?.data_tab.length > 0 ? helpers.replaceUnderDashToUpper(dataArrayQ?.data?.data_tab) : 'Tools'}</AppText>
                           </div>
                           <div className="col-lg-4 col-12">
                              <div className="innerNavigation">
                                 <select className="assessmentSelect" onChange={(e) => {
                                    let allData = singleSessionTools.find((item: any) => item.data_tab === e.target.value)
                                    // setDataArray(state => ({ ...state, isLoading: true }))
                                    setDataArrayQ({ data: allData })
                                 }}>
                                    <option>{`<<< Select >>> `}</option>
                                    {(singleSessionTools || []).map((item: any, i: any) => {
                                       return <option value={item?.data_tab}>{helpers.replaceUnderDashToUpper(item?.data_tab)}</option>
                                    })
                                    }
                                 </select>

                              </div>
                           </div>
                        </div>

                     </div>
                     <div className="col-12 mt-4">
                        <div className='w-100'>
                           <div className="row">
                              {dataArrayQ.data?.data && dataArrayQ.data?.data.length > 0 ?
                                 (dataArrayQ.data?.data || []).map((itemV: any, i: any) => (
                                    // console.log(itemV)
                                    itemV?.name && itemV?.answer ?
                                       <div className="col-lg-6 col-12 mb-4">
                                          <div className=" paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <span className="go10">{itemV.title}</span>
                                                   <AppText fontSize={'16px'} fontWeight="500" color="#61646B">{helpers.replaceUnderscore2(itemV?.name)}</AppText>
                                                </div>
                                                <div className="col-12">
                                                   <span className="go10">Answer</span>
                                                   <AppText fontSize={'16px'} fontWeight="400" color="#000">{Qanswer(itemV?.answer)}</AppText>
                                                </div>
                                             </div>
                                          </div>
                                       </div> : ''
                                 )) : <div className="container"><AppText fontSize={'16px'} fontWeight="500" color="#61646B">Select what you want to see</AppText></div>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>

   )
}