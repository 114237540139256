import { Routes, RouteObject } from "react-router-dom"
import SupportIndex from "../pages/support/SupportIndex"



export const SupportRoute: RouteObject = {
   path: "/supports",
   children: [
      {
         path: "/supports",
         element: <SupportIndex />
      },
      {
         path: "/supports/:ID",
         element: <SupportIndex />
      },
   ]
}