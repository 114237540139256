import { Routes, RouteObject } from "react-router-dom"
import TransactionIndex from "../pages/transactions/TransactionIndex"



export const TransactionRoute: RouteObject = {
   path: "/transactions",
   children: [
      {
         path: "/transactions",
         element: <TransactionIndex />
      },
      {
         path: "/transactions/:ID",
         element: <TransactionIndex />
      },
   ]
}