import { useNavigate } from "react-router-dom";
import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const { setAlert, setIsLoading, setSpecialtyData } = useStoreSelector([
      "setAlert",
      "setIsLoading",
      "setSpecialtyData",
   ]);
   const getSpecialty = async () => {
      //  helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-category`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSpecialtyData(sendRequest.data.data);
   };

   const newSpecialty = async (title: string, description?: string, onPress?: Function) => {
      //  setIsLoading(true);

      if (!title) {
         return helpers.showToast("error", "Specialty name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-category`,
            method: "POST",
            json: { name: title, description: description },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSpecialty();
      helpers.showToast("success", "Specialty was created successful");
      onPress && onPress();
   };

   const updateSpecialty = async (
      title: string,
      id: string,
      description?: string,
      onPress?: Function
   ) => {
      if (!title) {
         return helpers.showToast("error", "Specialty name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-category/${id}`,
            method: "PUT",
            json: { name: title, description: description },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSpecialty();
      helpers.showToast("success", "Specialty was created successful");
      onPress && onPress();
   };

   const createServices = async (
      data: {
         name: string;
         description?: string;
         goals?: string;
         method?: string;
         outcome?: string;
      },
      specialtyId: string,
      onPress?: Function
   ) => {
      if (!data?.name) {
         return helpers.showToast("error", "Specialty name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-offer`,
            method: "POST",
            json: {
               name: data?.name,
               service_category_id: specialtyId,
               description: data?.description,
               goal: data?.goals,
               methodology: data?.method,
               outcome: data?.outcome,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSpecialty();
      helpers.showToast("success", "Specialty was created successful");
      onPress && onPress();
   };
   const updateServices = async (
      data: {
         name: string;
         description?: string;
         goals?: string;
         method?: string;
         outcome?: string;
      },
      specialtyId: string,
      serviceId: string,
      onPress?: Function
   ) => {
      if (!data?.name) {
         return helpers.showToast("error", "Specialty name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-offer/${serviceId}`,
            method: "PUT",
            json: {
               name: data?.name,
               service_category_id: specialtyId,
               description: data?.description,
               goal: data?.goals,
               methodology: data?.method,
               outcome: data?.outcome,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSpecialty();
      helpers.showToast("success", "Specialty was updated successful");
      onPress && onPress();
   };

   const deleteSpecialty = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-category/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSpecialty();
      helpers.showToast("success", "Specialty was deleted successful");
      onPress && onPress();
   };
   const deleteService = async (id: string, onPress?: Function) => {
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-offer/${id}`,
            method: "DELETE",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSpecialty();
      helpers.showToast("success", "Service was deleted successful");
      onPress && onPress();
   };

   const updateSpecialtyStatus = async (
      title: string,
      status: any,
      id: string,
      onPress?: Function
   ) => {
      if (!title) {
         return helpers.showToast("error", "Specialty name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-category/${id}`,
            method: "PUT",
            json: { name: title, status: status },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSpecialty();
      helpers.showToast("success", "Specialty was updated successful");
      onPress && onPress();
   };

   const updateServicesStatus = async (
      name: string,
      specialtyId: string,
      serviceId: string,
      status: any,
      onPress?: Function
   ) => {
      if (!name) {
         return helpers.showToast("error", "Specialty name is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.specialty.getSpecialty}/operations/service-offer/${serviceId}`,
            method: "PUT",
            json: {
               name: name,
               service_category_id: specialtyId,
               status: status,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      getSpecialty();
      helpers.showToast("success", "Specialty was updated successful");
      onPress && onPress();
   };

   return {
      getSpecialty,
      newSpecialty,
      updateSpecialty,
      createServices,
      updateServices,
      updateSpecialtyStatus,
      updateServicesStatus,
      deleteSpecialty,
      deleteService,
   };
};
