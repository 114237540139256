import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface MemberSliceState {
  memberData?: ObjectPayload;
  setMemberData?: (value: ObjectPayload) => void;

  singleMemberData?: ObjectPayload;
  setSingleMemberData?: (value: ObjectPayload) => void;

  singleMemberCount?: ObjectPayload;
  setSingleMemberCount?: (value: ObjectPayload) => void;

  singleMemberSessionCount?: ObjectPayload;
  setSingleMemberSessionCount?: (value: ObjectPayload) => void;

  singleMemberTransactionCount?: ObjectPayload;
  setSingleMemberTransactionCount?: (value: ObjectPayload) => void;

  singleMemberSession?: ObjectPayload;
  setSingleMemberSession?: (value: ObjectPayload) => void;

  singleMemberTransaction?: ObjectPayload;
  setSingleMemberTransaction?: (value: ObjectPayload) => void;

  memberCount?: ObjectPayload;
  setMemberCount?: (value: ObjectPayload) => void;

  memberStatus?: ObjectPayload;
  setMemberStatus?: (value: ObjectPayload) => void;
}

export const memberStoreSlice: StoreStateCreator<MemberSliceState> = (
  set,
  get
) => ({
  memberData: [],
  setMemberData: (value: ObjectPayload) => set({ memberData: value }),

  singleMemberData: [],
  setSingleMemberData: (value: ObjectPayload) =>
    set({ singleMemberData: value }),

  setSingleMemberCount: (value: ObjectPayload) =>
    set({ singleMemberCount: value }),

  singleMemberSession: [],
  setSingleMemberSession: (value: ObjectPayload) =>
    set({ singleMemberSession: value }),

  singleMemberTransaction: [],
  setSingleMemberTransaction: (value: ObjectPayload) =>
    set({ singleMemberTransaction: value }),

  memberCount: {},
  setMemberCount: (value: ObjectPayload) => set({ memberCount: value }),

  singleMemberSessionCount: {},
  setSingleMemberSessionCount: (value: ObjectPayload) =>
    set({ singleMemberSessionCount: value }),

  singleMemberTransactionCount: {},
  setSingleMemberTransactionCount: (value: ObjectPayload) =>
    set({ singleMemberTransactionCount: value }),

  memberStatus: {},
  setMemberStatus: (value: ObjectPayload) => set({ memberStatus: value }),
});
