import * as SVGIcon from '../assets/svg'
import { useEffect, useState } from "react";
import { ProviderAPIData } from "../constants/mockData";
import { NavLink, useParams } from "react-router-dom";
import AppText from "./AppText";
import useMemberFunction from "../hooks/useMemberFunction";
import { useStoreSelector } from "../store/useStore";
import { ObjectPayload } from '../typings/general';
import helpers from '../utils/helpers';
const videochat: string = require('../assets/icons/videochat.svg').default
const voicechat: string = require('../assets/icons/voicechat.svg').default
const messagechat: string = require('../assets/icons/messagechat.svg').default
export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
}
export default (data: DataState) => {
   const params = useParams();
   const { fetchSingleMember } = useMemberFunction()
   useEffect(() => {
      fetchSingleMember(params?.ID as any)
   }, [])
   let useData = data?.data

   const MappArray = (data1: Array<any>, data2: Array<any>) => {
      if (!data1 || !data2.length) {
         return
      }
      let timeData = []
      for (let i = 0; i < data1.length; i++) {
         timeData.push(`${data1[i]} - ${data2[i]}`)
      } return timeData.join(", ")
   }

   const Qanswer = (data: any) => {
      const result = Array.isArray(data);
      let answer: any
      if (result) {
         let newData = []
         for (let i = 0; i < data.length; i++) {
            newData.push(data[i])
            answer = newData.join(", ")
         }
      } else {
         answer = data
      }

      return answer
   }
   return (
      // <div className="myTable w-100 mb-4">
      <div style={{
         position: 'fixed',
         overflow: 'auto',
         display: 'flex',
         justifyContent: 'center', alignItems: 'flex-start', width: '100%', height: '100%', zIndex: '999', backgroundColor: 'rgba(0,0,0,.3)', left: 0, top: 0,
      }}>

         <div className="dropdownLi dropdownMain max700">
            <div className="borderBottomProfile">
               <div className='d-flex spaceRounded'>
                  <div className="table-text go15mp"><span className={`marginBottom profileActive ${useData?.status === 1 ? "userActive" : useData?.status === 2 ? "userReview" : useData?.status === 3 ? "userCompleted" : useData?.status === 4 ? "userReview" : "userPending"}`}>{useData?.status === 1 ? "Paid" : useData?.status === 2 ? "Live" : useData?.status === 3 ? "Completed" : useData?.status === 4 ? "Cancelled" : "Upcoming Session"}</span></div>

                  <div>
                     {/* <button className="editServiceBtn d-flex" type="button" onClick={() => data.onPress && data.onPress()}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Close </AppText>
                     </button> */}
                     <i onClick={() => data.onPress && data.onPress()} style={{ fontSize: '28px' }} className="fa fa-times" aria-hidden="true"></i>
                  </div>
               </div>
               <div className="row mt-2">
                  <div className="col-12 mb-4">
                     <div className="greenBackgroundMagic">
                        <div className="d-flex borderRight alignCenter">
                           <div className="PageHeaderWrapper">
                              <h1 className="headerH1"><AppText fontSize={'18px'} color="#61646B" fontWeight="500" letterSpacing={'0.15px'}>Provider</AppText></h1>
                              <NavLink to={`/providers/${useData?.provider_id}`} className="textDecor">
                                 <div className='profile-inner mb-4 mt-4 smpr'>
                                    <div className='imgContainer2'><img src={useData?.provider_data?.avatar} /></div>
                                    <div className='myProfile line14 myMarginAutoTB'>
                                       <h1 className="headerH1"><AppText fontSize={'18px'} color="#000" fontWeight="700" letterSpacing={'0.15px'}>{useData?.provider_data?.name}</AppText></h1>
                                    </div>
                                 </div>
                              </NavLink>
                           </div>
                        </div>
                        <div className="d-flex alignCenter">
                           <div className="PageHeaderWrapper smpl">
                              <h1 className="headerH1"><AppText fontSize={'18px'} color="#61646B" fontWeight="500" letterSpacing={'0.15px'}>Member</AppText></h1>
                              <NavLink to={`/members/${useData?.member_id}`} className="textDecor">
                                 <div className='profile-inner mb-4 mt-4'>
                                    <div className='imgContainer2'><img src={useData?.member_data?.avatar} /></div>
                                    <div className='myProfile line14 myMarginAutoTB'>
                                       <h1 className="headerH1"><AppText fontSize={'18px'} color="#000" fontWeight="700" letterSpacing={'0.15px'}>{useData?.member_data?.name}</AppText></h1>

                                    </div>
                                 </div>
                              </NavLink>
                           </div>
                        </div>
                     </div>
                     <div className='row mt-4'>

                        <div className='col-lg-3 col-md-6 col-12'>
                           <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Amount/hour</AppText>

                           <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {helpers.getNairaSign() + helpers.formatMoney(useData?.amount_per_hour || 0.00)}/hour</AppText>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                           <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Grand Total</AppText>

                           <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {helpers.getNairaSign() + helpers.formatMoney(useData?.amount_booking_ref || 0.00)}</AppText>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                           <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Booking Ref</AppText>

                           <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {useData?.booking_ref}</AppText>

                        </div>

                     </div>
                     <div className='row mt-4'>
                        <div className='col-4'>
                           <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Service Offer</AppText>
                           <div className="mt-2">
                              <AppText fontSize={'10px'} fontWeight='400'><span className="marginBottom profileActive22 userActive">{helpers.toUpperCase(useData?.service_data?.service_offer || "Behavioral Therapist")}</span></AppText>
                           </div>

                        </div>
                        <div className='col-4'>
                           <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Category</AppText>
                           <div className='d-flex mt-2'>
                              {(useData?.service_data?.service_category || []).map((item: any, i: any) => (
                                 <div className='d-flex' style={{ marginRight: '5px' }} key={i}>
                                    <AppText fontSize={'10px'} fontWeight='400'><span className="marginBottom profileActive22 userReview">{helpers.toUpperCase(item || "Behavioral Therapist")}</span></AppText>
                                 </div>
                              ))}

                           </div>
                        </div>
                     </div>
                     <div className='row mt-4'>
                        <div className='col-4'>
                           <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Date and Time</AppText>

                           <AppText fontSize={'16px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}> {new Date(useData?.appt_schedule).toDateString() + ' ' + MappArray(useData?.appt_start_time, useData?.appt_end_time)}</AppText>

                        </div>
                        <div className='col-4'>
                           <AppText fontSize={'16px'} marginBottom={'10px'} color="#61646B" fontWeight="400" letterSpacing={'0.15px'}>Mode of Communication</AppText>
                           <div className='d-flex'>
                              {(useData?.comm_mode || []).map((item: any, i: any) => (
                                 <div className='d-flex' style={{ marginRight: '5px' }}>
                                    <img width={'20px'} src={item === 'video' ? videochat : item === 'audio' ? voicechat : messagechat} />
                                    <AppText fontSize={'16px'} marginLeft={'5px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>{item}</AppText>
                                 </div>
                              ))}

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {useData?.status === 3 &&
               <div className="mt27 borderBottomProfile">
                  <div className="mt20">
                     <div className='d-flex mb-2'>
                        <div className="myRatings starMargin mtTop0">
                           <span className="fa fa-star checked"></span>
                           <span className="fa fa-star checked"></span>
                           <span className="fa fa-star checked"></span>
                           <span className="fa fa-star checked"></span>
                           <span className="fa fa-star"></span>
                        </div>
                        <AppText fontSize={'18px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>4.0</AppText>
                     </div>
                     <div className='w-100'>
                        <div className="">
                           <div className=''>
                              <div className="d-flex spaceRounded borderBottomService">
                                 <div className='line14' style={{ width: '70%' }}>
                                    <AppText fontSize={'18px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>Question </AppText>
                                 </div>
                                 <div className='line14' style={{ width: '30%' }}>
                                    <AppText fontSize={'18px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>Answer </AppText>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='w-100'>
                        {(useData?.member_questionnaire || []).map((itemV: any, i: any) => (
                           <div className="d-flex spaceRounded">
                              <div className='qRestore'> <AppText width={'70%'} fontSize={'16px'} fontWeight="500" color="#61646B">{itemV?.question_header ? helpers.replaceUnderscore(itemV?.question_header) : helpers.replaceUnderscore(itemV?.question)}</AppText></div>
                              <AppText width={'30%'} fontSize={'16px'} fontWeight="400" color="#000">{Qanswer(itemV?.answer)}</AppText>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            }
         </div>

      </div>
   )
}