import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface ProviderSliceState {
  providerData?: ObjectPayload;
  setProviderData?: (value: ObjectPayload) => void;

  singleProviderData?: ObjectPayload;
  setSingleProviderData?: (value: ObjectPayload) => void;

  singleProviderCount?: ObjectPayload;
  setSingleProviderCount?: (value: ObjectPayload) => void;

  singleProviderSession?: ObjectPayload;
  setSingleProviderSession?: (value: ObjectPayload) => void;

  singleProviderTransaction?: ObjectPayload;
  setSingleProviderTransaction?: (value: ObjectPayload) => void;

  providerTransactionCount?: ObjectPayload;
  setProviderTransactionCount?: (value: ObjectPayload) => void;

  singleProviderSessionCount?: ObjectPayload;
  setSingleProviderSessionCount?: (value: ObjectPayload) => void;

  singleProviderTransactionCount?: ObjectPayload;
  setSingleProviderTransactionCount?: (value: ObjectPayload) => void;
}

export const providerStoreSlice: StoreStateCreator<ProviderSliceState> = (
  set,
  get
) => ({
  ProviderData: [],
  setProviderData: (value: ObjectPayload) => set({ providerData: value }),

  singleProviderData: [],
  setSingleProviderData: (value: ObjectPayload) =>
    set({ singleProviderData: value }),

  setSingleProviderCount: (value: ObjectPayload) =>
    set({ singleProviderCount: value }),

  setSingleProviderSession: (value: ObjectPayload) =>
    set({ singleProviderSession: value }),

  singleProviderTransaction: [],
  setSingleProviderTransaction: (value: ObjectPayload) =>
    set({ singleProviderTransaction: value }),

  providerTransactionCount: {},
  setProviderTransactionCount: (value: ObjectPayload) =>
    set({ providerTransactionCount: value }),

  singleProviderSessionCount: {},
  setSingleProviderSessionCount: (value: ObjectPayload) =>
    set({ singleProviderSessionCount: value }),

  singleProviderTransactionCount: {},
  setSingleProviderTransactionCount: (value: ObjectPayload) =>
    set({ singleProviderTransactionCount: value }),
});
