import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface SessionSliceState {
  sessionData?: ObjectPayload;
  setSessionData?: (value: ObjectPayload) => void;

  sessionPageCount?: ObjectPayload;
  setSessionPageCount?: (value: ObjectPayload) => void;

  singleSessionData?: ObjectPayload;
  setSingleSessionData?: (value: ObjectPayload) => void;

  singleSessionAssData?: ObjectPayload;
  setSingleSessionAssData?: (value: ObjectPayload) => void;

  singleSessionTreatment?: ObjectPayload;
  setSingleSessionTreatment?: (value: ObjectPayload) => void;

  singleSessionTools?: ObjectPayload;
  setSingleSessionTools?: (value: ObjectPayload) => void;

  singleSessionNote?: ObjectPayload;
  setSingleSessionNote?: (value: ObjectPayload) => void;
}

export const sessionStoreSlice: StoreStateCreator<SessionSliceState> = (
  set,
  get
) => ({
  sessionData: [],
  setSessionData: (value: ObjectPayload) => set({ sessionData: value }),
  singleSessionData: {},

  setSingleSessionData: (value: ObjectPayload) =>
    set({ singleSessionData: value }),

  singleSessionAssData: [],
  setSingleSessionAssData: (value: ObjectPayload) =>
    set({ singleSessionAssData: value }),
  singleSessionTreatment: [],
  setSingleSessionTreatment: (value: ObjectPayload) =>
    set({ singleSessionTreatment: value }),
  singleSessionTools: [],
  setSingleSessionTools: (value: ObjectPayload) =>
    set({ singleSessionTools: value }),

  singleSessionNote: [],
  setSingleSessionNote: (value: ObjectPayload) =>
    set({ singleSessionNote: value }),

  sessionPageCount: {},
  setSessionPageCount: (value: ObjectPayload) =>
    set({ sessionPageCount: value }),
});
