import { useState, useEffect } from "react"
import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import { DoctorAppointment } from "../../constants/mockData";
import { useNavigate, useParams } from "react-router-dom";
import helpers from "../../utils/helpers";
import useMemberFunction from "../../hooks/useMemberFunction";
import { useStoreSelector } from "../../store/useStore";
import SessionModal from "../../components/SessionModal";
import Pagination from "../../components/Pagination";

export default (props: any) => {
   let accountType = props?.accountType ? props?.accountType : 'individual'
   const Navigate = useNavigate()
   const params = useParams();
   const Navigation = useNavigate()
   const [activePage, setActivePage] = useState(1)
   const [sessionModal, setSessionModal] = useState({ status: false, data: {} })
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, status: any, data: Array<any> }>({ isLoading: false, status: '', data: [] })
   useEffect(() => {
      // setDataArray(state => ({ ...state, isLoading: false, data: DoctorAppointment.filter(e => e.userId === params.ID) }))
   }, [])
   const { fetchSingleMemberSession, fetchMemberSessionCount } = useMemberFunction()
   const { singleMemberSession, isLoading, singleMemberSessionCount } = useStoreSelector(["singleMemberSession", "isLoading", "singleMemberSessionCount"])
   useEffect(() => {
      fetchSingleMemberSession(params?.ID as any, accountType, props?.filterData?.data1)
      fetchMemberSessionCount(params?.ID as any, accountType, props?.filterData?.data1)
   }, [])
   return (
      <div>
         <div className="bg-white">
            <div className="myTableHeight">
               <AppTable data={singleMemberSession} isLoading={isLoading} headers={["Patient", "Date", "Time", "Mode of Comm.", "Booking ID", "Status"]} tableTitle="">
                  {(singleMemberSession || []).map((item: any, i: any) => (
                     // <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => setSessionModal({ status: true, data: item })}>
                     <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => Navigate(`/sessions/${item?.booking_id}`)}>
                        <td className="tdclass4 tableGrid">{item?.member_data?.name}</td>
                        <td className="tdclass1">{new Date(item?.createdAt).toDateString()}</td>
                        <td className="tdclass1">{helpers.get24HourTo12Hours((item?.createdAt))}</td>
                        <td className="tdclass3">{item?.comm_mode}</td>
                        <td className="tdclass4">{item?.booking_ref}</td>
                        <td className="tdclass3">{<span className={`marginBottom profileActive ${item?.status === 1 ? "userActive" : item?.status === 2 ? "userReview" : "userPending"}`}>{item?.status === 1 ? "Active" : item?.status === 2 ? "Suspended" : "Pending"}</span>}</td>
                     </tr>
                  ))}
               </AppTable>

            </div>
            {singleMemberSession && singleMemberSession.length > 0 ?
               <Pagination activePage={activePage} totalPage={singleMemberSessionCount?.total} onPress={(nextPage) => {
                  fetchMemberSessionCount(params?.ID as any, accountType, props?.filterData?.data1, nextPage)
                  setActivePage(nextPage)
               }} /> : ""}
         </div>
         {sessionModal.status &&
            <SessionModal data={sessionModal.data} onPress={() => setSessionModal({ status: false, data: {} })} />
         }
      </div>
   )
}