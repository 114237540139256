import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppText from "../../components/AppText";
import useProviderFunction from "../../hooks/useProviderFunction";
import { useStoreSelector } from "../../store/useStore";
import { AppAlertData } from "../../store/storeslice/alert-slice";
import helpers from "../../utils/helpers";
import { ObjectPayload } from "../../typings/general";

export default () => {
   const params = useParams();
   const { fetchSingleProvider } = useProviderFunction()
   const { singleProviderData, setAlert } = useStoreSelector(["singleProviderData", "setAlert"])
   useEffect(() => {
      //if the user data has not been loaded
      if (singleProviderData?.auth_id) return
      fetchSingleProvider(params?.ID as any)
   }, [])

   return (
      <div className="myTable w-100 mb-4">
         <div className="bookedTable">
            <div className="">
               <div className='pdlr'>
                  <div className="d-flex spaceRounded borderBottomService">
                     <div className='line14'>
                        <h1 className="headerH1 mb-n2"><AppText fontSize={'24px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>Specialties</AppText></h1>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container service-area mt-2">
               <div className="row">
                  {(singleProviderData?.service_data || []).map((item: any, i: number) => (
                     <React.Fragment key={i}>
                        <div className="col-12">
                           <h5>{item?.service_category}</h5>
                        </div>
                        {item?.service_offers?.map((jtem: ObjectPayload, idx: number) => {
                           return (
                              <div key={idx} className="col-12 col-md-6 col-lg-4">
                                 <div className="service-singleitem">
                                    <p className="service-namm">{jtem?.name}</p>
                                    <div className="status-row">
                                       <div>Status</div>
                                       <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={jtem?.status === 1 ? true : false} onClick={(e) => {
                                          e.preventDefault()
                                          setAlert({
                                             type: "info",
                                             title: "Confirmation",
                                             message: "Are you sure you want to update this service?",
                                             onCancel: () => { },
                                             onConfirm: () => { }
                                          } as AppAlertData)
                                       }}
                                       />
                                    </div>
                                 </div>
                              </div>
                           )
                        })}
                     </React.Fragment>
                  ))}
                  {/* <div className="pd14">
                  {(singleProviderData?.service_data || []).map((item: any, i: any) => (
                     (item?.service_offers || []).map((itemX: any, j: any) => (
                        <div className="row spaceRounded">
                           <div className="col-lg-3 col-3"><AppText fontSize={'16px'} fontWeight="500" color="#000">{helpers.toUpperCase(item?.service_category)}</AppText></div>
                           <div className="col-lg-3 col-3">
                              <AppText fontSize={'16px'} fontWeight="500" color="#000">{helpers.toUpperCase(itemX?.name)}</AppText>
                           </div>
                           <div className="col-lg-3 col-3">
                              <AppText fontSize={'16px'} fontWeight="500" color="#000">{helpers.getNairaSign() + helpers.formatMoney(itemX?.amount || 0.00)}/hour</AppText>
                           </div>
                           <div className="col-lg-3 col-3">
                              <div className="form-check form-switch goML801" style={{ float: 'right' }}>
                                 <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={itemX?.status === 1 ? true : false} onClick={(e) => {
                                    e.preventDefault()
                                    setAlert({
                                       type: "info",
                                       title: "Confirmation",
                                       message: "Are you sure you want to update this service?",
                                       onCancel: () => { },
                                       onConfirm: () => { }
                                    } as AppAlertData)
                                 }}
                                 />
                              </div>
                           </div>
                        </div>
                     ))
                  ))}
               </div> */}
               </div>
            </div>
         </div>
      </div>
   )
}