import { useState, useEffect } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import { ObjectPayload } from "../../typings/general"
import { NavLink, useParams } from "react-router-dom"
import { useStoreSelector } from "../../store/useStore"
import useSessionFunction from "../../hooks/useSessionFunction"
import helpers from "../../utils/helpers"
import SingleSessionNav from "../../components/SingleSessionNav"
import AppTable from "../../components/AppTable"
import InnerNavigation from "../../components/InnerNavigation"
import InnerNavigationFew from "../../components/InnerNavigationFew"
import AssessmentTab from "../../components/AssessmentTab"

export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
}

export const ArrayDrop = (props: any) => {
   // console.log(props)
   return (
      // <div></div>

      props?.data && props?.data.length > 0 ?
         (props?.data || []).map((treat: any, j: any) => (
            treat?.goal || treat?.addressed ?
               <div className="col-lg-6 col-12 mb-4">
                  <div className=" paddin">
                     <div className="row">
                        <div className="col-12 mb-4">
                           <span className="go10">Goal</span>
                           <AppText fontSize={'16px'} fontWeight="500" color="#61646B">{treat?.goal}</AppText>
                        </div>
                        <div className="col-12">
                           <span className="go10">Addressed</span>
                           <AppText fontSize={'16px'} fontWeight="400" color="#000">{treat?.addressed}</AppText>
                        </div>
                     </div>
                  </div>
               </div> : ''
         )) : ''


   )
}
export default (data: DataState) => {
   const params = useParams();
   const { fetchSingleSession, fetchSingleSessionNote } = useSessionFunction()
   const { singleSessionData, singleSessionNote } = useStoreSelector(["singleSessionData", "singleSessionNote"])
   useEffect(() => {
      fetchSingleSession(params?.ID as any)
      fetchSingleSessionNote(params?.ID as any)
   }, [params.ID])


   return (
      <div className="mb-4">
         <div className="col-12">
            <div className="myTable w-100 mb-4">
               <div style={{ padding: '30px' }}>

                  <div className="row">

                     <div className="col-12 mt-4">
                        <div className='w-100'>
                           <div className="row">
                              {singleSessionNote && singleSessionNote[0]?.data.length > 0 ?
                                 (singleSessionNote[0]?.data || []).map((itemV: any, i: any) => (
                                    // (itemV)
                                    // console.log(itemV)
                                    <div className="row">
                                       <div className="col-12">
                                          <div className="row">
                                             <ArrayDrop data={itemV.treatmentGoal} />
                                          </div>
                                       </div>
                                       <div className="col-lg-6 col-12 mb-4">
                                          <div className=" paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Assessment tool(s) used</AppText>
                                                         <span className='my-form-control'>{itemV?.assessment_tool}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-lg-6 col-12 mb-4">
                                          <div className=" paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Client’s Location</AppText>
                                                         <span className='my-form-control'>{itemV?.client_location}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-lg-6 col-12 mb-4">
                                          <div className=" paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Type of Session</AppText>
                                                         <span className='my-form-control'>{itemV?.type_of_session}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-lg-6 col-12 mb-4">
                                          <div className=" paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Attendance</AppText>
                                                         <span className='my-form-control'>{itemV?.attendance}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-lg-6 col-12 mb-4">
                                          <div className=" paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Group</AppText>
                                                         <span className='my-form-control'>{itemV?.group}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Appearance</AppText>
                                                         <span className='my-form-control'>{itemV?.appearance}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.appearance_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Manifest Behavior</AppText>
                                                         <span className='my-form-control'>{itemV?.manifest_behavior}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.manifest_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Speech</AppText>
                                                         <span className='my-form-control'>{itemV?.speech}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.speech_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Affect</AppText>
                                                         <span className='my-form-control'>{itemV?.affect}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.affect_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Mood (in Client's words) if applicable</AppText>
                                                         {/* <textarea className='my-form-control newTextarea' placeholder="e.g: Family Counselling">{itemV?.mood_applicable}</textarea> */}
                                                         <span className='my-form-control'>{itemV?.mood_applicable}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Thought processes</AppText>
                                                         <span className='my-form-control'>{itemV?.thought_processes}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.thought_processes_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Perceptual disturbances</AppText>
                                                         <span className='my-form-control'>{itemV?.perceptual_disturbances}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.perceptual_disturbances_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Attention</AppText>
                                                         <span className='my-form-control'>{itemV?.attention}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.attention_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Insight/judgement</AppText>
                                                         <span className='my-form-control'>{itemV?.insight_judgement}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.insight_judgement_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Reported behavior elsewhere</AppText>
                                                         <span className='my-form-control'>{itemV?.reported_behavior}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comment (if any)</AppText>
                                                         <span className='my-form-control'>{itemV?.reported_behavior_answer}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Comments: (include 1. What client, caretaker, and therapist discussed; 2. Therapist's clinical assessment; and 3. next step)</AppText>
                                                         {/* <textarea className='my-form-control newTextarea' placeholder="e.g: Family Counselling">{itemV?.therapist_comments}</textarea> */}
                                                         <span className='my-form-control'>{itemV?.therapist_comments}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Signature</AppText>
                                                         <span className='my-form-control'>{itemV?.signature}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-12 mb-4">
                                          <div className="paddin">
                                             <div className="row">
                                                <div className="col-12 mb-4">
                                                   <div className="containerForm p-l-0">
                                                      <div className="w-100 mb-2">
                                                         <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Date</AppText>
                                                         <span className='my-form-control'>{itemV?.date}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                    </div>

                                 )) : ''

                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>

   )
}