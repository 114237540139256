import { useState, useEffect } from "react"
import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import { DoctorAppointment } from "../../constants/mockData";
import { useParams } from "react-router-dom";
import helpers from "../../utils/helpers";
import useTransactionFunction from "../../hooks/useTransactionFunction";
import { useStoreSelector } from "../../store/useStore";
import Pagination from "../../components/Pagination";
import { AppAlertData } from "../../store/storeslice/alert-slice";
const ApproveBtn: string = require('../../assets/icons/approve.svg').default
const DeclineBtn: string = require('../../assets/icons/decline.svg').default
const serviceedit: string = require('../../assets/icons/trans.svg').default

export default (props: { filterData: any }) => {

   const [activePage, setActivePage] = useState(1)
   const { fetchDepositTransaction, fetchTransactionPageCount } = useTransactionFunction()
   const { transactionDepositData, isLoading, transactionPageCount, setAlert } = useStoreSelector(["transactionDepositData", "isLoading", "transactionPageCount", "setAlert"])
   useEffect(() => {
      fetchDepositTransaction(props?.filterData.data2)
      fetchTransactionPageCount(props?.filterData.data2)
   }, [props?.filterData.data2])

   return (
      <div>
         <div className="bg-white">
            <div className="myTableHeight">
               <AppTable data={transactionDepositData} isLoading={isLoading} headers={["Provider", "Date", "Time", "Amount", "Mode of Payment", "Status", "Action"]} tableTitle="">
                  {(transactionDepositData || []).map((item: any, i: any) => (
                     <tr className="table-tr cursor-hover" key={i}>
                        <td className="tdclass4 tableGrid">{item.name} <span className="tdclass2">ID:{item.email}</span></td>
                        <td className="tdclass1">{new Date(item.createdAt).toDateString()}</td>
                        <td className="tdclass1">{helpers.get24HourTo12Hours((item.createdAt))}</td>
                        <td className="tdclass4">{helpers.getNairaSign() + helpers.formatMoney(item?.amount || 0)}</td>
                        <td className="tdclass4">{item?.payment_method}</td>
                        <td className="tdclass3">{<span className={`marginBottom profileActive ${item?.status === 1 ? "userActive" : item?.status === 2 ? "userReview" : "userPending"}`}>{item?.status === 1 ? "Active" : item?.status === 2 ? "Suspended" : "Pending"}</span>}</td>
                        <td>
                           {item?.transaction_type === 'withdrawal' && item?.status === 0 ?
                              <div className="d-flex">
                                 <img className="cursor-pointer" src={ApproveBtn} style={{ marginRight: '5px' }} onClick={() => setAlert({
                                    type: "info",
                                    title: "Confirmation",
                                    message: "Are you sure you want to approve this request?",
                                    onCancel: () => { },
                                    onConfirm: () => { }
                                 } as AppAlertData)} />
                                 <img className="cursor-pointer" src={DeclineBtn} onClick={() => setAlert({
                                    type: "info",
                                    title: "Confirmation",
                                    message: "Are you sure you want to decline this request?",
                                    onCancel: () => { },
                                    onConfirm: () => { }
                                 } as AppAlertData)} />
                              </div>
                              : <img src={serviceedit} />}
                        </td>
                     </tr>
                  ))}
               </AppTable>
            </div>
            <Pagination activePage={activePage} totalPage={transactionPageCount?.total} onPress={(nextPage) => {
               fetchDepositTransaction(props?.filterData.data2, nextPage)
               setActivePage(nextPage)
            }} />

         </div>
      </div>
   )
}