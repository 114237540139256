import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import AppText from './AppText';


type DropdownItems = {
   name?: string;
   value?: any;
   onPress?: Function;
}
interface DropdownOptions {
   onPress?: ((e: DropdownItems) => void) | undefined;
   containerStyle?: CSSProperties;
   title1?: string;
   title2?: string;
   data1?: Array<DropdownItems>;
   data2?: Array<DropdownItems>;
   myDefault?: string;
}

// const DropdownRow = ({ name, onPress }: DropdownItems) => {
//    return (

//       <option>{name}</option>
//    )
// }
export default (props: DropdownOptions) => {
   let deff = props?.myDefault ? props?.myDefault : ""
   const [comState, setComState] = useState({ startDate: '', endDate: '', data1: '', data2: deff, q: '' })
   // console.log(props)
   const startDateref = React.createRef<HTMLInputElement>()
   const endDateref = React.createRef<HTMLInputElement>()
   return (
      <div style={{ position: 'absolute', left: 0, top: 0 }}>
         <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', width: '160px', boxShadow: '2px 1px 2px #eee', ...props.containerStyle }}>
            <form method="post" className='dropdownLi dropdownMain'>
               <div className="filterForm w-100">
                  <div className='row'>
                     <div className='col-6'>
                        <div className="w-100 mb-2">
                           <label onClick={() => startDateref && startDateref?.current?.showPicker()}>
                              <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'15px'}>From</AppText>
                              <span>{comState.startDate || 'yyyy/mm/dd'}</span>
                              <input style={{ visibility: 'hidden' }} ref={startDateref} type="date" placeholder="date" onChange={(e) => {
                                 setComState(state => ({ ...state, startDate: e.target.value }))
                              }} />
                           </label>
                        </div>
                     </div>
                     <div className='col-6'>
                        <div className="w-100 mb-2">
                           {/* <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>To</AppText>
                           <input name='start' style={{}} type="date" placeholder="date" onChange={(e) => {
                              setComState(state => ({ ...state, endDate: e.target.value }))
                           }} /> */}

                           <label onClick={() => endDateref && endDateref?.current?.showPicker()}>
                              <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'15px'}>To</AppText>
                              <span>{comState.endDate || 'yyyy/mm/dd'}</span>
                              <input style={{ visibility: 'hidden' }} ref={endDateref} type="date" placeholder="date" onChange={(e) => {
                                 setComState(state => ({ ...state, endDate: e.target.value }))
                              }} />
                           </label>
                        </div>
                     </div>
                     <div className='col-12'>
                        {props?.title2 ?
                           <div className="w-100 mb-2 mt-2">
                              <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>{props?.title2}</AppText>
                              <select onChange={(e) => { setComState(state => ({ ...state, data2: e.target.value })) }}>
                                 {props?.data2?.map((item, index) => <option value={item.value} key={index}>{item.name}</option>)}
                              </select>
                           </div> : ''}
                     </div>
                     <div className='col-12'>
                        {props?.title1 ?
                           <div className="w-100 mb-2 mt-2">
                              <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>{props?.title1}</AppText>
                              <select
                                 onChange={(e) => { setComState(state => ({ ...state, data1: e.target.value })) }}
                              // onChange={(e) => typeof props.onPress === 'function' && props.onPress(e.target.value as any)}
                              >
                                 {props?.data1?.map((item, index) => <option value={item.value} key={index}>{item.name}</option>)}
                              </select>
                           </div> : ''}
                     </div>
                  </div>
                  <button className="mt13 loginBtn borderRadius8" type="button" onClick={() => typeof props.onPress === 'function' && props.onPress(comState as any)}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Apply</AppText></button>
               </div>
            </form>
         </div>

      </div>
   )
}