import { ObjectPayload } from "../../typings/general";
import { StoreStateCreator } from "../useStore";

export interface TransactionSliceState {
  transactionData?: ObjectPayload;
  setTransactionData?: (value: ObjectPayload) => void;

  transactionPageCount?: ObjectPayload;
  setTransactionPageCount?: (value: ObjectPayload) => void;

  transactionDepositData?: ObjectPayload;
  setTransactionDepositData?: (value: ObjectPayload) => void;

  transactionWithdrawalData?: ObjectPayload;
  setTransactionWithdrawalData?: (value: ObjectPayload) => void;
}

export const transactionStoreSlice: StoreStateCreator<TransactionSliceState> = (
  set,
  get
) => ({
  transactionData: [],
  setTransactionData: (value: ObjectPayload) => set({ transactionData: value }),

  transactionDepositData: [],
  setTransactionDepositData: (value: ObjectPayload) =>
    set({ transactionDepositData: value }),

  transactionWithdrawalData: [],
  setTransactionWithdrawalData: (value: ObjectPayload) =>
    set({ transactionWithdrawalData: value }),

  transactionPageCount: {},
  setTransactionPageCount: (value: ObjectPayload) =>
    set({ transactionPageCount: value }),
});
