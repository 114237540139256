import { AppAlertData } from "../store/storeslice/alert-slice";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const {
      setIsLoading,
      setMemberData,
      setSingleMemberData,
      setSingleMemberCount,
      setSingleMemberSession,
      setSingleMemberTransaction,
      setMemberCount,
      setSingleMemberSessionCount,
      setSingleMemberTransactionCount,
      setMemberStatus,
      setAlert,
   } = useStoreSelector([
      "setIsLoading",
      "setMemberData",
      "setSingleMemberData",
      "setSingleMemberCount",
      "setSingleMemberSession",
      "setSingleMemberTransaction",
      "setMemberCount",
      "setSingleMemberSessionCount",
      "setSingleMemberTransactionCount",
      "setMemberStatus",
      "setAlert",
   ]);

   const updateUserStatus = async (id: string, status: string) => {
      //  setIsLoading(true);

      if (!status) {
         return setAlert({
            type: "error",
            title: "Missing field",
            message: "Please select a status",
            confirmName: "Ok",
         } as AppAlertData);
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.member.getmembers}/account-status`,
            method: "POST",
            json: { user_type: "member", auth_id: id, status: status },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      fetchSingleMember(id);
      helpers.showToast("success", "Updated successful");
   };

   const fetchMemberCount = async (
      type: string,
      status = 1,
      page = 1,
      startDate = "",
      endDate = ""
   ) => {
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.member.getmembers}?component=count&account_type=${type}&user_type=member&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setMemberCount(sendRequest.data.data);
   };
   const fetchMembers = async (
      type: string,
      status = 1,
      page = 1,
      startDate = "",
      endDate = "",
      q = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.member.getmembers}?account_type=${type}&user_type=member&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setMemberData(sendRequest.data.data);
   };
   const fetchSingleMember = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.member.getmembers}/${id}?user_type=member`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleMemberData(sendRequest.data.data);
   };

   const fetchSingleMemberCounter = async (id: string) => {
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.member.getmembers}/data-stat?component=member-profile-count&member_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      if (sendRequest && sendRequest.status_code !== 200) {
         return;
      }
      setSingleMemberCount(sendRequest.data?.data);
   };
   const fetchSingleMemberSession = async (
      id: string,
      type: string,
      status: "",
      page = 1,
      startDate = "",
      endDate = "",
      q = ""
   ) => {
      setIsLoading(true);
      if (status === undefined) {
         status = "";
      }
      //  console.log(status);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings?auth_id=${id}&account_type=${type}&user_type=member&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&q=${q}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleMemberSession(sendRequest.data?.data);
   };
   const fetchSingleMemberTransaction = async (id: string) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.member.getmembers}/wallet?auth_id=${id}&user_type=member`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleMemberTransaction(sendRequest.data?.data);
   };

   const fetchMemberSessionCount = async (
      id: string,
      type: string,
      status = "",
      page = 1,
      startDate = "",
      endDate = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.session.getsessions}/bookings?component=count&account_type=${type}&user_type=member&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&auth_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      setSingleMemberSessionCount(sendRequest.data.data[0]);
   };
   const fetchMemberTransactionCount = async (
      id: string,
      status = "",
      page = 1,
      startDate = "",
      endDate = ""
   ) => {
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.member.getmembers}/wallet?component=count&user_type=member&status=${status}&page=${page}&start_date=${startDate}&end_date=${endDate}&auth_id=${id}`,
            method: "GET",
         })
         .catch((e) => e);
      setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         return helpers.showErrorMessage(sendRequest);
      }
      //  console.log(sendRequest.data);
      setSingleMemberTransactionCount(sendRequest.data.data);
   };

   return {
      fetchMembers,
      fetchSingleMember,
      fetchSingleMemberCounter,
      fetchSingleMemberSession,
      fetchSingleMemberTransaction,
      fetchMemberCount,
      fetchMemberSessionCount,
      fetchMemberTransactionCount,
      updateUserStatus,
   };
};
