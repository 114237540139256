import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import PageHeader from "../../components/PageHeader"
import AppTable from "../../components/AppTable"
import * as SVGIcon from '../../assets/svg'
import BorderNavigation from "../../components/BorderNavigation"
import InnerNavigation from "../../components/InnerNavigation"
import PageHeaderBalance from "../../components/PageHeaderBalance"
import { useState } from "react"
import Searchfilter, { DropdownState } from "../../components/Searchfilter"
import { TransactionDepositStatus, TransactionProfileSubScreen, TransactionProfileSubValues, TransactionTypeStatus, TransactionWithdrawalStatus } from "../../constants/TransactionSubScreen"
import TransactionDeposit from "./TransactionDeposit"
import TransactionWithdrawal from "./TransactionWithdrawal"
import MemberBalance from "../../components/MemberBalance"
import Searchfilter2 from "../../components/Searchfilter2"
export default () => {
   const [dropMe, ShowDropMe] = useState<DropdownState>({ show: false })
   const [screenTab, setScreenTab] = useState('')
   const [filter, setFilter] = useState({})
   const screenContent = {
      [TransactionProfileSubValues.transaction]: TransactionDeposit,
      [TransactionProfileSubValues.withdrawals]: TransactionWithdrawal,
      ['default']: TransactionDeposit,
   }
   const ScreenMounted = screenContent[screenTab] || screenContent['default']
   // console.log(filter)
   return (
      <AppLayout active="transactions">
         <div className="helloMe">
            <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Transactions</AppText>
            </div>


            <div className="row mb-4">

               <div className="col-12 mb-3">
                  <div className="row">
                     <div className="col-lg-8 col-12 mb-2">
                        <div className="">
                           <MemberBalance />
                        </div>
                     </div>
                     <div className="col-lg-4 col-12 mb-2">
                        <Searchfilter2 show={dropMe.show} title1="Status" myDefault="Deposit" data1={TransactionDepositStatus} title2="Transaction Type" data2={TransactionTypeStatus}
                           onFilterClick={() => ShowDropMe(state => ({ ...state, show: !state.show, data1: TransactionDepositStatus, title1: 'Status', title2: "Transaction Type", data2: TransactionTypeStatus }))} onSelect={(e) => {
                              setFilter(e)
                              ShowDropMe(state => ({ ...state, show: !state.show }))

                           }} />
                     </div>
                  </div>
               </div>

               <div className="col-12">
                  <div className="bg-white border-radius-20 pt-2">
                     <div className="row">
                        {/* <div className="col-lg-2 col-12">
                           <InnerNavigation defaultActive="transaction" titles={TransactionProfileSubScreen} onPress={(item) => setScreenTab(item.value as any)} />
                        </div> */}
                        <div className="col-12">
                           <TransactionDeposit filterData={filter} />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="mb-4">

            </div>
         </div>
      </AppLayout>

   )
}