import { Routes, RouteObject } from "react-router-dom"
import FeedbackIndex from "../pages/feedback/FeedbackIndex"



export const FeedbackRoute: RouteObject = {
   path: "/feedbacks",
   children: [
      {
         path: "/feedbacks",
         element: <FeedbackIndex />
      },
   ]
}