import { useState, useEffect } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import PageHeader from "../../components/PageHeader"
import AppTable from "../../components/AppTable"
import * as SVGIcon from '../../assets/svg'
import BorderNavigation from "../../components/BorderNavigation"
import InnerNavigation from "../../components/InnerNavigation"
import PageHeaderBalance from "../../components/PageHeaderBalance"
import Searchfilter, { DropdownState } from "../../components/Searchfilter"
import { SessionStatus } from "../../constants/SessionSubScreen"
import helpers from "../../utils/helpers"
import { useStoreSelector } from "../../store/useStore"
import CreateManagement from "../../components/CreateManagement"
import useAdminFunction from "../../hooks/useAdminFunction"
import Pagination from "../../components/Pagination"
import CreateManagementUpdate from "../../components/CreateManagementUpdate"
import { AppAlertData } from "../../store/storeslice/alert-slice"
const serviceedit: string = require('../../assets/icons/serviceedit.svg').default
const servicedelete: string = require('../../assets/icons/servicedelete.svg').default
export default () => {
   const [dropMe, ShowDropMe] = useState<DropdownState>({ show: false })
   const [filter, setFilter] = useState({})

   const [adminModal, setAdminModal] = useState({ status: false, data: {} })
   const [editAdminModal, setEditAdminModal] = useState({ status: false, data: {} })
   const [activePage, setActivePage] = useState(1)
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, data: Array<any> }>({ isLoading: false, data: [] })
   const { fetchAdminList, fetchAdminCount, deleteAdmin } = useAdminFunction()
   const { adminData, isLoading, adminPageCount, setAlert } = useStoreSelector(["isLoading", "adminData", "adminPageCount", "setAlert"])
   useEffect(() => {
      fetchAdminList()
      fetchAdminCount()
   }, [])
   return (
      <AppLayout active="management">
         <div className="">
            <div className="mb-4 d-flex">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">User Management</AppText>
               <button className="loginBtn newServiceBtn" type="button" onClick={() => setAdminModal({ status: true, data: [] })}><AppText color="#fff" fontWeight="500" fontSize={'17px'} letterSpacing={'0.2px'}>Create User</AppText></button>
            </div>
            <div className="row mb-4 mt-4">
               <div className="col-12">
                  <div className="myTableHeight">
                     <AppTable data={adminData} isLoading={isLoading} headers={["Name", "Email", "Mobile", "Type", "Action"]} tableTitle="">
                        {(adminData || []).map((item: any, i: any) => (
                           <tr className="table-tr cursor-pointer cursor-hover" key={i}>
                              <td className="tdclass4 tableGrid">{item?.name}</td>
                              <td className="tdclass4">{item?.email}</td>
                              <td className="tdclass4">{item?.phone_number}</td>
                              <td className="tdclass4">{item?.user_type}</td>
                              <td className="d-flex">
                                 <div className="cursor-pointer" onClick={() => setEditAdminModal({ status: true, data: item })}>
                                    <img src={serviceedit} />
                                 </div>
                                 <div className="cursor-pointer" style={{ marginLeft: '15px' }}>
                                    <img src={servicedelete} onClick={(e) => {
                                       e.preventDefault()
                                       setAlert({
                                          type: "info",
                                          title: "Confirmation",
                                          message: "Are you sure you want to delete?",
                                          onCancel: () => { },
                                          onConfirm: () => { deleteAdmin(item?.auth_id) }
                                       } as AppAlertData)
                                    }} />
                                 </div>
                              </td>
                           </tr>
                        ))}
                     </AppTable>
                  </div>
                  <Pagination activePage={activePage} totalPage={adminPageCount?.total} onPress={(nextPage) => {
                     fetchAdminCount(nextPage)
                     setActivePage(nextPage)
                  }} />
               </div>
            </div>
            {adminModal.status &&
               <CreateManagement data={adminModal.data} onPress={() => setAdminModal({ status: false, data: {} })} />
            }
            {editAdminModal.status &&
               <CreateManagementUpdate data={editAdminModal.data} onPress={() => setEditAdminModal({ status: false, data: {} })} />
            }

         </div>
      </AppLayout>

   )
}