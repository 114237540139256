import logo from './logo.svg';
import './App.css';
import './assets/style/mainStyle.css'
import './assets/style/responsive.css'
import RouteComponent from "./routeList/index"
import AppUpdatePopup from './components/AppUpdatePopup';
import useAdminFunction from './hooks/useAdminFunction';
import { useStoreSelector } from './store/useStore';
import { useEffect } from 'react';
import { BrowserRouter, Route, Router, Routes, useNavigate } from 'react-router-dom';
import LoginIndex from './pages/login/LoginIndex';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default () => {
   const { userData } = useStoreSelector(["userData"])
   // getprofile()

   return (
      <div>
         {userData?.auth_id ?
            <RouteComponent /> :
            <BrowserRouter>
               <Routes><Route path="*" Component={() => <LoginIndex />} /></Routes>
            </BrowserRouter>}
         <AppUpdatePopup />
         <ToastContainer style={{ width: "auto" }} pauseOnHover newestOnTop />
      </div>
   )
}
