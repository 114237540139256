import React from "react";
import AppText from "./AppText"
import EmptyTable from "./EmptyTable";
import Loader from "./Loader";

interface AppTableProps {
   tableTitle?: string;
   headers?: string[];
   data?: Array<any>;
   isLoading?: boolean;
   children: React.ReactNode
}

export default (props: AppTableProps) => {

   return (
      <div className="myTable w-100 mb-4 myTable14">
         {props.isLoading ? <Loader position="relative" /> :
            (!props.data || props.data?.length === 0) ? <EmptyTable /> :
               <div>
                  {props.tableTitle &&
                     <div className="table-text"><AppText>{props.tableTitle}</AppText></div>}
                  <table className="w-100">
                     <thead className="bg-color-second table-head-pad">
                        {props.headers && props.headers.map((item, i) => <th key={i}>{item}</th>)}
                     </thead>
                     <tbody className="my-table-body newSticky">
                        {props.children}
                     </tbody>
                  </table>
               </div>
         }
      </div>

   )
}