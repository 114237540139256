import { KeyValueObject } from "../typings/general";

export const ProviderProfileSubValues = {
   profile: "profile",
   education: "education",
   sessions: "sessions",
   services: "services",
   document: "document",
   //   support: "support",
   //   feedback: "feedback",
   //   settings: "settings",
   transaction: "transaction",
   availability: "availability",
};

export const ProviderProfileSubScreen: KeyValueObject[] = [
   { name: "Profile", value: ProviderProfileSubValues.profile },
   { name: "Education", value: ProviderProfileSubValues.education },
   { name: "Documents", value: ProviderProfileSubValues.document },
   { name: "Services", value: ProviderProfileSubValues.services },
   { name: "Availability", value: ProviderProfileSubValues.availability },
   { name: "Sessions", value: ProviderProfileSubValues.sessions },
   //   { name: "Support", value: ProviderProfileSubValues.support },
   //   { name: "Feedback", value: ProviderProfileSubValues.feedback },
   { name: "Transaction", value: ProviderProfileSubValues.transaction },
   //   { name: "Settings", value: ProviderProfileSubValues.settings },
];

export const Status = {
   Upcoming: "Upcoming",
   Completed: "Completed",
   Cancelled: "Cancelled",
};

export const ProviderStatus: KeyValueObject[] = [
   { name: "Upcoming", value: Status.Upcoming },
   { name: "Completed", value: Status.Completed },
   { name: "Cancelled", value: Status.Cancelled },
];

export const ActiveStatus = {
   Active: "Active",
   Pending: "Pending",
   Under_Review: "Under Review",
};

export const ActiveProviderStatus: KeyValueObject[] = [
   { name: "Active", value: ActiveStatus.Active },
   { name: "Pending", value: ActiveStatus.Pending },
   { name: "Under Review", value: ActiveStatus.Under_Review },
];
