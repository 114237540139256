import { useEffect } from "react"
import useTransactionFunction from "../hooks/useTransactionFunction"
import { useStoreSelector } from "../store/useStore"
import HeaderCard from "./HeaderCard"
import helpers from "../utils/helpers"
import HeaderCard2 from "./HeaderCard2"

export default () => {
   const { fetchTransaction } = useTransactionFunction()
   const { transactionData, isLoading } = useStoreSelector(["transactionData", "isLoading"])
   useEffect(() => {
      fetchTransaction()
   }, [])
   return (
      <div className="pageHeaderCards mb-4">
         <div className="row">
            <HeaderCard2 cardTitle="Pending" cardNumber={helpers.getNairaSign() + helpers.formatMoney(transactionData?.withdraw_pending || 0.00)} />
            <HeaderCard2 cardTitle="Withdrawal" cardNumber={helpers.getNairaSign() + helpers.formatMoney(transactionData?.debit_amount || 0.00)} />
            <HeaderCard2 cardTitle="Credited" cardNumber={helpers.getNairaSign() + helpers.formatMoney(transactionData?.credit_amount || 0.00)} />
         </div>
      </div>
   )
}