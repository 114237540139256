import { Routes, RouteObject } from "react-router-dom"
import DashboardIndex from "../pages/dashboard/DashboardIndex"


export const DashboardRoute: RouteObject = {
   path: "/dashboard",
   children: [
      {
         path: "/dashboard",
         element: <DashboardIndex />
      },
   ]
}