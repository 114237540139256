import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import Searchfilter, { DropdownState } from "../../components/Searchfilter"
import AppTable from "../../components/AppTable"
import { useNavigate, useParams } from "react-router-dom"
import useMemberFunction from "../../hooks/useMemberFunction"
import { useStoreSelector } from "../../store/useStore"
import Pagination from "../../components/Pagination"
import { ObjectPayload } from "../../typings/general"


export default (props: { type: 'individual' | 'business' }) => {
   // console.log(props)
   const search = window.location.search;
   const accountType = new URLSearchParams(search)?.get("type");
   const [dropMe, ShowDropMe] = useState<DropdownState>({ show: false })
   const [activePage, setActivePage] = useState(1)
   const [filter, setFilter] = useState<ObjectPayload>({})
   const { fetchMembers, fetchMemberCount } = useMemberFunction()
   const { memberData, isLoading, memberCount } = useStoreSelector(['memberData', "isLoading", "memberCount"])
   const Navigation = useNavigate()

   useEffect(() => {
      if (accountType && accountType.length > 2) {
         fetchMembers(props.type as string)
         fetchMemberCount(props.type as string)
      }
   }, [props.type])
   return (
      <AppLayout active="member" onPress={() => {
         if (dropMe.show) ShowDropMe({ show: false, data1: [] })
      }}>
         <div className="">
            <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Members</AppText>
            </div>
            <div className="">
               <Searchfilter placeHolder="Search with email"
                  onSearch={(e) => {
                     setFilter(e)
                     fetchMembers(accountType as string, e?.data1, 1, e?.startDate, e?.endDate, e.q)
                  }}
                  title1="Status" data1={[{ name: 'Active', value: 'Active' }, { name: 'Suspended', value: 'Suspended' }]} show={dropMe.show} onFilterClick={() => ShowDropMe(state => ({ ...state, show: !state.show, title1: 'Status', data1: [{ name: 'Active', value: 'Active' }, { name: 'Suspended', value: 'Suspended' }] }))} onSelect={(e) => {
                     setFilter(e)
                     ShowDropMe(state => ({ ...state, show: !state.show }))
                     fetchMembers(accountType as string, e?.data1, 1, e?.startDate, e?.endDate, e?.q)
                     fetchMemberCount(accountType as string, e?.data1, 1, e?.startDate, e?.endDate)
                  }} />
            </div>
            <div className="bg-white">
               <div className="myTableHeight">
                  <AppTable
                     data={memberData || []}
                     isLoading={isLoading}
                     headers={["Name", "Date Registered", "Completed Sessions", "Status"]} tableTitle="">
                     {(memberData || []).map((item: any, i: any) => (
                        <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => Navigation(`/members/${item.auth_id}`)}>
                           <td className="tdclass4 tableGrid">{item.name} <span className="tdclass2">ID:{item.email}</span></td>
                           <td className="tdclass1">{new Date(item.createdAt).toDateString()}</td>
                           <td className="tdclass3">{item.sessions || 0}</td>
                           <td className="tdclass3">{<span className={`marginBottom profileActive ${item?.status === 1 ? "userActive" : item?.status === 2 ? "userReview" : "userPending"}`}>{item?.status === 1 ? "Active" : item?.status === 2 ? "Suspended" : "Pending"}</span>}</td>
                        </tr>))}
                  </AppTable>
                  <Pagination activePage={activePage} totalPage={memberCount?.total} onPress={(nextPage) => {
                     fetchMemberCount(props.type as string, filter?.data1, nextPage, filter?.startDate, filter?.endDate)
                     setActivePage(nextPage)
                  }} />
               </div>
            </div>
         </div>
      </AppLayout>
   )
}