import { useEffect, useState } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import AppUpdatePopup from "../../components/AppUpdatePopup"
import Cards from "../../components/Cards"
import useDashboardFunction from "../../hooks/useDashboardFunction"
import { CChart } from '@coreui/react-chartjs'
import { useStoreSelector } from "../../store/useStore"
import helpers from "../../utils/helpers"
import DashboardUserList from "../../components/DashboardUserList"
import { NavLink } from "react-router-dom"
import AppTable from "../../components/AppTable"
import { useNavigate } from "react-router-dom"

const dashboarduser: string = require('../../assets/icons/dashboarduser.svg').default
const dashboardincome: string = require('../../assets/icons/dashboardincome.svg').default
const dashboardongoing: string = require('../../assets/icons/dashboardongoing.svg').default
const dashboardsession: string = require('../../assets/icons/dashboardsession.svg').default
const dashboardprovider: string = require('../../assets/icons/dashboardprovider.svg').default

export default () => {
   const Navigate = useNavigate()
   const { dashboardData, dashboardSessionChart, dashboardWeeklyChart, dashboardUserChart, isLoading } = useStoreSelector(["dashboardData", "dashboardSessionChart", "dashboardWeeklyChart", "dashboardUserChart", "isLoading"])
   const { DashboardNumberCount, DashboardSessionChart, DashboardWeeklyChart, DashboardUserChart } = useDashboardFunction()
   const [sessionData, setSessionData] = useState<{ year: any, month: any }>({ year: helpers.getCurrentMonth().year, month: helpers.getCurrentMonth().month })
   useEffect(() => {
      DashboardNumberCount();
      DashboardSessionChart(sessionData.year, sessionData.month);
      DashboardWeeklyChart();
      DashboardUserChart();
   }, [])


   return (
      <AppLayout active="dashboard">
         <div className="">
            <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Dashboard</AppText>
            </div>
            <div className="dashboardCards">
               <div className="row">
                  <Cards cardImg={dashboarduser} cardTitle="Members" cardNumber={helpers.formatMoney(dashboardData?.member_count || 0)} />
                  <Cards cardImg={dashboardprovider} cardTitle="Providers" cardNumber={helpers.formatMoney(dashboardData?.provider_count || 0)} />
                  <Cards cardImg={dashboardsession} cardTitle="Completed Sessions" cardNumber={helpers.formatMoney(dashboardData?.completed_booking_count || 0)} />
                  <Cards cardImg={dashboardongoing} cardTitle="Ongoing Sessions" cardNumber={helpers.formatMoney(dashboardData?.ongoing_booking_count || 0)} />
                  <Cards cardImg={dashboardincome} cardTitle="Total Income" cardNumber={helpers.getNairaSign() + helpers.formatMoney(dashboardData?.income_balance || 0)} />
               </div>
            </div>
            {/* to be removed */}

            <div>
               <div className="row mt-4">
                  <div className="col-lg-8 col-12 pb-2">
                     <div className="myTable w-100 mb-4">
                        <div className="bookedTable">
                           <div className="table-text go15mp"><AppText>Sessions Booked</AppText></div>
                           <div className="dropDowns floaterRight pb-2">
                              <div className="dropDownsYear">
                                 <select defaultValue={helpers.getCurrentMonth().year} onChange={(e) => { setSessionData(state => ({ ...state, year: e.target.value })) }}>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                 </select>
                              </div>
                              <div className="dropDownsYear">
                                 <select defaultValue={helpers.getCurrentMonth().month} onChange={(e) => { setSessionData(state => ({ ...state, month: e.target.value })) }}>
                                    <option value="01">Jan</option>
                                    <option value="02">Feb</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">Aug</option>
                                    <option value="09">Sept</option>
                                    <option value="10">Oct</option>
                                    <option value="11">Nov</option>
                                    <option value="12">Dec</option>
                                 </select>
                              </div>
                              <div className="" style={{ marginRight: '55px' }}>
                                 <button className="loginBtn newServiceBtn" type="button" style={{ padding: '3px 8px', marginRight: '20px', backgroundColor: '#367FFF' }} onClick={() => { DashboardSessionChart(sessionData.year, sessionData.month); }}><AppText color="#fff" fontWeight="500" fontSize={'12px'} letterSpacing={'0.2px'}>Apply</AppText></button>
                              </div>
                           </div>
                        </div>
                        <div className="bookedGraph">
                           <CChart
                              type="bar"
                              height={150}

                              data={{
                                 labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
                                 datasets: [
                                    {
                                       label: '',
                                       backgroundColor: '#367FFF',
                                       data: helpers.generateChartNumber(dashboardSessionChart),
                                       borderRadius: 2
                                    },
                                 ],
                              }}
                              // labels="months"
                              options={{

                                 plugins: {
                                    legend: {
                                       display: false,
                                       labels: {
                                          //  color: getStyle('--cui-body-color'),
                                       }
                                    }
                                 },
                                 scales: {
                                    y: {
                                       grid: {
                                          // drawBorder: false, // <-- this removes y-axis line
                                          lineWidth: 0
                                       }
                                    },
                                    x: {
                                       grid: {
                                          // drawBorder: false,
                                          lineWidth: 0 // <-- this removes vertical lines between bars
                                       }
                                    }
                                 },
                              }}
                           />
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4 col-12">
                     <div className="myTable w-100 mb-4">
                        <div className="bookedTable">
                           <div className="table-text go15mp"><AppText fontSize={'14px'} fontWeight="400">This Week</AppText></div>
                        </div>
                        <div className="bookedGraph">
                           <CChart
                              type="bar"
                              height={150}
                              data={{
                                 labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
                                 datasets: [
                                    {
                                       label: '',
                                       backgroundColor: '#00DB8F',
                                       data: helpers.generateChartDays(dashboardWeeklyChart),
                                       // data: [12, 39, 10, 40, 39, 100, 4],
                                       borderRadius: 2
                                    },

                                 ],
                              }}
                              // labels="months"
                              options={{
                                 plugins: {
                                    legend: {
                                       display: false,
                                       labels: {
                                          // color: "#000",
                                       }
                                    }
                                 },
                                 scales: {
                                    y: {
                                       grid: {
                                          // drawBorder: false, // <-- this removes y-axis line
                                          lineWidth: 0
                                       }
                                    },
                                    x: {
                                       grid: {
                                          // drawBorder: false,
                                          lineWidth: 0 // <-- this removes vertical lines between bars
                                       }
                                    }
                                 },
                              }}
                           />
                        </div>
                     </div>
                  </div>

                  <div className="col-12">
                     <div className="myTable w-100 mb-4" style={{ overflow: 'hidden' }}>
                        <div className="bookedTable">
                           <div className="table-text go15mp"><AppText fontSize={'14px'} fontWeight="400">Upcoming sessions</AppText></div>
                           <div className="dropDowns floaterRight pb-2"><NavLink to="/sessions" style={{ textDecoration: 'none', color: '#367FFF', fontWeight: "500" }}><AppText fontSize={'14px'} fontWeight="400">See all</AppText></NavLink></div>
                        </div>
                        <div className="bookedGraph">
                           {/* <div className="row">
                              <div className="scrolling-wrapper scrollez">
                                 {(dashboardUserChart || []).map((item: any, i: any) => (
                                    <DashboardUserList data={item} />
                                 ))}
                              </div>
                           </div> */}

                           <div className="bg-white newHeight">
                              <div className="myTableHeight">
                                 <AppTable data={dashboardUserChart} isLoading={isLoading} headers={["Member", "Provider", "Amount Paid", "Date", "Time", "Booking ID", "Status"]} tableTitle="">
                                    {(dashboardUserChart || []).map((item: any, i: any) => (
                                       <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => Navigate(`/sessions/${item?.booking_id}`)}>
                                          {/* <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => setSessionModal({ status: true, data: item })}> */}
                                          <td className="tdclass4 tableGrid">{item?.member_data?.name}</td>
                                          <td className="tdclass4">{item?.provider_data?.name}</td>
                                          <td className="tdclass4">{helpers.getNairaSign() + helpers.formatMoney(item?.amount || 0.00)}</td>
                                          <td className="tdclass1">{new Date(item.createdAt).toDateString()}</td>
                                          <td className="tdclass1">{helpers.get24HourTo12Hours((item.createdAt))}</td>
                                          <td className="tdclass4">{item?.booking_ref}</td>
                                          <td className="tdclass3">{<span className={`marginBottom profileActive ${item?.status === 1 ? "userActive" : item?.status === 2 ? "userReview" : item?.status === 3 ? "userCompleted" : item?.status === 4 ? "userReview" : "userPending"}`}>{item?.status === 1 ? "Paid" : item?.status === 2 ? "Live" : item?.status === 3 ? "Completed" : item?.status === 4 ? "Cancelled" : "Pending"}</span>}</td>
                                       </tr>
                                    ))}
                                 </AppTable>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </AppLayout>

   )
}