import React from "react"
import { Properties as CSSProperties } from "csstype"

interface AppTexProps extends CSSProperties {
   children: any;
   fontWeight?: '300' | '400' | '500' | '600' | '700' | '800'
   color?: string;
   nclass?: string;
}

export default (props: AppTexProps) => (
   <div className={props?.nclass} style={props}>
      {props.children}
   </div>
)