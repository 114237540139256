import { Routes, RouteObject } from "react-router-dom"
import SessionIndex from "../pages/sessions/SessionIndex"
import SingleSession from "../pages/sessions/SingleSession"


export const SessionRoute: RouteObject = {
   path: "/sessions",
   children: [
      {
         path: "/sessions",
         element: <SessionIndex />
      },
      {
         path: "/sessions/:ID",
         element: <SingleSession />
      },
   ]
}