import AppTable from "../../components/AppTable";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppText from "../../components/AppText";
import useProviderFunction from "../../hooks/useProviderFunction";
import { useStoreSelector } from "../../store/useStore";
import helpers from "../../utils/helpers";

export default () => {
   const params = useParams();
   const { fetchSingleProvider } = useProviderFunction()
   const { singleProviderData, isLoading } = useStoreSelector(["singleProviderData", "isLoading"])
   useEffect(() => {
      //if the user data has not been loaded
      if (singleProviderData?.auth_id) return
      fetchSingleProvider(params?.ID as any)
   }, [])
   return (
      <div className="myTable w-100 mb-4 container">
         <div className="d-flex spaceRounded borderBottomService">
            <h1 className="headerH1 mb-n2"><AppText fontSize={'24px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>Educational Background</AppText></h1>
         </div>
         <div className="bookedTable">
            <div className="mt27 borderBottomProfile">
               {/* <AppText fontSize={'18px'} color="#000" fontWeight="500">Educational Background</AppText> */}
               <div className="mt20 educationTableHead">
                  <AppTable
                     data={singleProviderData?.education_level}
                     isLoading={isLoading}
                     headers={["Educational Level", "Degree Obtained", "School Name", "Year Obtained", "School Address"]} tableTitle="">
                     {(singleProviderData?.education_level || []).map((item: any, i: any) => (
                        <tr className="table-tr" key={i}>
                           <td className="tdclass4 tableGrid">{helpers.toUpperCase(item?.education_level)}</td>
                           <td className="tdclass1">{helpers.toUpperCase(item?.degree)}</td>
                           <td className="tdclass3">{helpers.toUpperCase(item?.school_name)}</td>
                           <td className="tdclass3">{item?.year}</td>
                           <td className="tdclass3">{helpers.toUpperCase(item?.school_address)}</td>
                        </tr>
                     ))}
                  </AppTable>
               </div>
            </div>
            <div className="mt27 borderBottomProfile">
               <AppText fontSize={'18px'} color="#000" fontWeight="500">Associations</AppText>
               <div className="mt20 educationTableHead with60">
                  <AppTable
                     data={singleProviderData?.medical_bg}
                     isLoading={isLoading}
                     headers={["Associations", "Country", "Member Number"]} tableTitle="">
                     {(singleProviderData.medical_bg || []).map((item: any, i: any) => (
                        <tr className="table-tr" key={i}>
                           <td className="tdclass4 tableGrid">{helpers.toUpperCase(item?.association_name)}</td>
                           <td className="tdclass1">{helpers.toUpperCase(item?.country)}</td>
                           <td className="tdclass1">{item?.member_id}</td>
                        </tr>
                     ))}
                  </AppTable>
               </div>
            </div>
         </div>
      </div>
   )
}