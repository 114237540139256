import React, { useState } from 'react'
import { Properties as CSSProperties } from 'csstype'
import AppText from './AppText';
import { ObjectPayload } from '../typings/general';
import useSpecialtyFunction from '../hooks/useSpecialtyFunction';
const closebtn: string = require('../assets/icons/closebtn.svg').default


type DropdownItems = {
   name?: string;
   value?: any;
   onPress?: Function;
}
interface DropdownOptions {
   onPress?: ((e: DropdownItems) => void) | undefined;
   containerStyle?: CSSProperties;
   title1?: string;
   title2?: string;
   data1?: Array<DropdownItems>;
   data2?: Array<DropdownItems>;
}
export type DataState = {
   data?: ObjectPayload;
   onPress?: Function;
   containerStyle?: CSSProperties;
}

// const DropdownRow = ({ name, onPress }: DropdownItems) => {
//    return (

//       <option>{name}</option>
//    )
// }
export default (props: DataState) => {
   const [nData, setNData] = useState({ name: '', description: '' })
   const { newSpecialty } = useSpecialtyFunction()
   return (
      <div style={{
         position: 'absolute', left: 0, top: 0,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.3)', width: '100%', height: '100%', zIndex: 999,
      }}>
         <div style={{ borderRadius: '20px', padding: '20px', width: '400px', ...props.containerStyle }}>
            {/* <div style={{ backgroundColor: '#fff', borderRadius: '20px', padding: '20px', width: '250px', boxShadow: '2px 1px 2px #eee', ...props.containerStyle }}> */}
            <div className='bg-white dropdownMainNew max400 dropdownMain22'>
               <div className="filterForm w-100">
                  <div className='popupClass1'>
                     <div className='popupText1 mb-2 d-flex spaceRounded'>
                        <AppText marginBottom={'13px'} marginTop={'10px'} fontSize={'20px'} color='#19191B'>Create Specialty</AppText>
                        {/* <AppText marginBottom={'13px'} marginTop={'10px'} fontSize={'24px'} color='#19191B'>Create Specialty
                           <img className='popImg' src={closebtn} /></AppText> */}
                        <i onClick={() => props.onPress && props.onPress()} style={{ fontSize: '20px', marginRight: '-25px' }} className="fa fa-times m-color-red cursor-pointer cursor-hover" aria-hidden="true"></i>
                     </div>
                     <form method="post">
                        <div className="containerForm">
                           <div className="w-100 mb-2">
                              <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Name of Specialty</AppText>
                              <input className='my-form-control' type="text" placeholder="e.g: Family Counselling" onChange={(e) => { setNData(state => ({ ...state, name: e.target.value })) }} />
                           </div>
                           <div className="w-100 mb-2">
                              <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Description</AppText>
                              <textarea className='textareaclass' onChange={(e) => {
                                 setNData(state => ({ ...state, description: e.target.value }))
                              }} placeholder='Note'></textarea>
                           </div>
                           <button className="loginBtn mt-4" type="button" onClick={(e) => {
                              e.preventDefault()
                              newSpecialty(nData?.name, nData?.description, props.onPress)
                           }}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Create</AppText></button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}