import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import PageHeader from "../../components/PageHeader"
import AppTable from "../../components/AppTable"
import * as SVGIcon from '../../assets/svg'
import BorderNavigation from "../../components/BorderNavigation"
import InnerNavigation from "../../components/InnerNavigation"
import { MemberProfileSubScreen, MemberProfileSubValues, MemberStatus } from "../../constants/MemberSubScreen"
import { useState, useEffect } from "react"
import MemberProfile from "./MemberProfile"
import SessionFilter from "../../components/SessionFilter"
import Searchfilter, { DropdownState } from "../../components/Searchfilter"
import MemberSupport from "./MemberSupport"
import MemberSetting from "./MemberSetting"
import MemberSession from "./MemberSession"
import useMemberFunction from "../../hooks/useMemberFunction"
import { useStoreSelector } from "../../store/useStore"
import { useParams } from "react-router-dom"
import PageHeadBalanceMember from "../../components/PageHeadBalanceMember"
import MemberTransaction from "./MemberTransaction"
export default () => {
   const params = useParams();
   const [dropMe, ShowDropMe] = useState<DropdownState>({ show: false })
   const [screenTab, setScreenTab] = useState('')
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, data: Array<any> }>({ isLoading: false, data: [] })
   const [filter, setFilter] = useState({})
   const screenContent = {
      [MemberProfileSubValues.profile]: MemberProfile,
      [MemberProfileSubValues.sessions]: MemberSession,
      // [MemberProfileSubValues.support]: MemberSupport,
      // [MemberProfileSubValues.settings]: MemberSetting,
      [MemberProfileSubValues.transaction]: MemberTransaction,
      ['default']: MemberProfile,
   }
   const ScreenMounted = screenContent[screenTab] || screenContent['default']

   const { fetchSingleMember, fetchSingleMemberSession, fetchMemberSessionCount } = useMemberFunction()
   const { singleMemberData } = useStoreSelector(['singleMemberData'])
   useEffect(() => {
      fetchSingleMember(params?.ID as any)
      setDataArray(state => ({ ...state, isLoading: false, data: singleMemberData || [] }))
   }, [])
   let accountType = singleMemberData?.account_type ? singleMemberData?.account_type : 'individual'
   return (
      <AppLayout active="member">
         <div className="">
            {/* <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Sessions</AppText>
            </div> */}
            <div className="">
               <PageHeader data={singleMemberData} />
               <PageHeadBalanceMember />
            </div>

            <div className="row mb-4">

               <div className="mb-3">
                  <AppText fontSize={'24px'} color="#19191B" fontWeight="700">{screenTab.charAt(0).toUpperCase() + screenTab.slice(1) || 'Profile'}</AppText>
               </div>
               {screenTab === MemberProfileSubValues.sessions &&
                  <div>
                     <Searchfilter onSearch={(e) => {
                        setFilter(e)
                        fetchSingleMemberSession(params?.ID as any, accountType, e?.data1, 1, e?.startDate, e?.endDate, e?.q)
                     }} placeHolder="Search with booking ID" show={dropMe.show} title1="Status" data1={MemberStatus}
                        onFilterClick={() => ShowDropMe(state => ({ ...state, show: !state.show, data1: MemberStatus, title1: 'Status' }))} onSelect={(e) => {
                           setFilter(e)
                           ShowDropMe(state => ({ ...state, show: !state.show }))
                           fetchSingleMemberSession(params?.ID as any, accountType, e?.data1, 1, e?.startDate, e?.endDate)
                           fetchMemberSessionCount(params?.ID as any, accountType, e?.data1, 1, e?.startDate, e?.endDate)
                        }} />
                  </div>}
               <div className="col-12">
                  <div className="bg-white border-radius-20 pt-2">
                     <div className="row">
                        <div className="col-lg-2 col-12">
                           <InnerNavigation defaultActive="profile" titles={MemberProfileSubScreen} onPress={(item) => setScreenTab(item.value as any)} />
                        </div>
                        <div className="col-lg-10 col-12">
                           <ScreenMounted filterData={filter} accountType={accountType} />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="mb-4">

            </div>
         </div>
      </AppLayout>

   )
}