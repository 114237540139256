import AppTable from "../../components/AppTable";
import * as SVGIcon from '../../assets/svg'
import { useEffect, useState } from "react";
import { ProviderAPIData } from "../../constants/mockData";
import { useParams } from "react-router-dom";
import AppText from "../../components/AppText";
import useProviderFunction from "../../hooks/useProviderFunction";
import { useStoreSelector } from "../../store/useStore";
import { ObjectPayload } from "../../typings/general";
// const plusme: string = require('../../assets/icons/plusme.svg').default
// const deleteme: string = require('../../assets/icons/deleteme.svg').default

export default () => {
   const params = useParams();
   const { fetchSingleProvider } = useProviderFunction()
   const { singleProviderData } = useStoreSelector(["singleProviderData"])
   useEffect(() => {
      if (singleProviderData?.auth_id) return
      fetchSingleProvider(params?.ID as any)
   }, [])

   let GetItemAvailability = (day: number): ObjectPayload[] => singleProviderData?.provider_availability?.filter((e: ObjectPayload) => e?.av_day === day)

   return (
      <div className="myTable w-100 mb-4 container">
         <div className="d-flex spaceRounded borderBottomService">
            <div className='line14'>
               <h1 className="headerH1 mb-n2"><AppText fontSize={'24px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>Documents</AppText></h1>
            </div>
         </div>
         <div className="bookedTable">
            <div className="row borderBottomProfile">
               {[
                  { label: "Sunday", value: 0 },
                  { label: "Monday", value: 1 },
                  { label: "Tuesday", value: 2 },
                  { label: "Wednesday", value: 3 },
                  { label: "Thursday", value: 4 },
                  { label: "Friday", value: 5 },
                  { label: "Saturday", value: 6 },
               ].map((item, idx) => {
                  let filterItem = GetItemAvailability(item.value)
                  return (
                     <div key={idx} className="col-lg-4 col-12 mb-4">
                        <div className="">
                           <div className="d-flex spaceRounded mb00">
                              <div className='line14'>
                                 <h1 className="headerH1 mb-2"><AppText fontSize={'24px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>{item.label}</AppText></h1>
                              </div>
                           </div>
                        </div>
                        <div className="">
                           {filterItem?.length ? filterItem?.map((jtem, idv) => (
                              <div key={idv} className="d-flex spaceRounded">
                                 <AppText fontSize={'16px'} fontWeight="500" color="#000">{jtem?.start_time + ' - ' + jtem?.end_time}</AppText>
                              </div>
                           )) : 'No time set'}
                        </div>
                     </div>
                  )
               })}
            </div>
         </div>
      </div>
   )
}