import { NavLink, useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from 'react'
import { EmailLoginIcon } from "../../assets/svg"
import AppText from "../../components/AppText"
import useAdminFunction from "../../hooks/useAdminFunction"
import { useStoreSelector } from "../../store/useStore"
import UpdateLoginPassword from "../../components/UpdateLoginPassword"
const Message: string = require('../../assets/icons/message.svg').default
const Password: string = require('../../assets/icons/Password.svg').default
const loginloader = require('../../assets/images/loginloader.gif')

export default () => {
   // let navigate = useNavigate()
   // const { isLoading } = useStoreSelector(["isLoading"])
   const { login, getprofile } = useAdminFunction()
   const [loginData, setLoginData] = useState({ email: '', password: '' })
   const urlPage = Object.values(useParams())[0]?.split('/')
   const [newPassword, setNewPassword] = useState({ status: false, data: {}, isLoading: false })
   const [passwordShown, setPasswordShown] = useState(false);
   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };


   useEffect(() => {
      if (urlPage && urlPage.length) {
         getprofile()
      }
   }, [])
   return (
      <div className="loginBackground">
         <div className="loginForm">
            <AppText textAlign="center" fontSize={'40px'} fontWeight="500" color="#19191B" marginBottom={'57px'} textTransform="uppercase">Login</AppText>
            <form method="post">
               <div className="containerForm">
                  <div className="w-100 mb-2">
                     <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Email</AppText>
                     <img className="icon" src={Message} />
                     <input type="text" placeholder="Email" onChange={(e) => setLoginData(edata => ({ ...edata, email: e.target.value.trim() }))} required />
                  </div>
                  <div className="w-100">
                     <AppText fontSize={'16px'} fontWeight="400" color="#000" marginBottom={'10px'}>Password</AppText>
                     <img className="icon" src={Password} />
                     <input name="password" className="form-control form-control-user"
                        id="exampleInputPassword" type={passwordShown ? "text" : "password"} placeholder="Enter Password" onChange={(e) => setLoginData(edata => ({ ...edata, password: e.target.value.trim() }))} required />
                     <span onClick={togglePasswordVisiblity} className={passwordShown ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}></span>
                  </div>
                  <button className="mt57 loginBtn" disabled={newPassword.isLoading || false} type="button" onClick={() => login(loginData.email, loginData.password, setNewPassword)}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>{newPassword.isLoading ? "Processing..." : 'Login'} </AppText></button>
               </div>
            </form>
         </div>
         {newPassword.status &&
            <UpdateLoginPassword onPress={() => setNewPassword({ status: false, data: {}, isLoading: false })} data={loginData} />
         }
      </div>
   )
}