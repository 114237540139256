import { useState, useEffect } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import ServiceInner from "../../components/ServiceInner"
import SpecialtyPopup from "../../components/SpecialtyPopup"
import SpecialtyService from "../../components/SpecialtyService"
import useSpecialtyFunction from "../../hooks/useSpecialtyFunction"
import { useStoreSelector } from "../../store/useStore"
import SpecialtyUpdate from "../../components/SpecialtyUpdate"
import AppUpdatePopup from "../../components/AppUpdatePopup"
import { AppAlertData } from "../../store/storeslice/alert-slice"
import SpecialtyServiceUpdate from "../../components/SpecialtyServiceUpdate"
const serviceedit: string = require('../../assets/icons/serviceedit.svg').default
const servicedelete: string = require('../../assets/icons/servicedelete.svg').default
const serviceplus: string = require('../../assets/icons/serviceplus.svg').default
const servicearrowdown: string = require('../../assets/icons/servicearrowdown.svg').default
const trans: string = require('../../assets/icons/arrownew.svg').default
export default () => {
   const [newSpecialty, setNewSpecialty] = useState(false)
   const [updateSpecialty, setUpdateSpecialty] = useState({ status: false, data: {} })
   const [newService, setNewService] = useState({ status: false, data: {} })
   const [updateService, setUpdateService] = useState({ status: false, data: {} })
   const [comState, setComState] = useState<{ show: boolean, serviceCatId: string, data: Array<any> }>({ show: false, serviceCatId: '', data: [] })
   const { getSpecialty, updateSpecialtyStatus, updateServicesStatus, deleteService, deleteSpecialty } = useSpecialtyFunction()
   const { specialtyData, setAlert } = useStoreSelector(["specialtyData", "setAlert"])
   useEffect(() => {
      getSpecialty()
   }, [])

   return (
      <AppLayout active="services">
         <div className="">
            <div className="mb-4 d-flex">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Services</AppText>
               <button className="loginBtn newServiceBtn" type="button" onClick={(e) => setNewSpecialty(true)}><AppText color="#fff" fontWeight="500" fontSize={'17px'} letterSpacing={'0.2px'}>Create Specialty</AppText></button>
            </div>
            <div className="row mb-4">
               <div className="col-12">
                  <div className="bg-white border-radius-20 pt-2 pb-2" style={{ minHeight: '200px' }}>
                     {(specialtyData || []).map((item: any, i: any) => (
                        <div className="row mt-4 mb-4 alignDivCenter" key={i}>
                           <div className="col-8">
                              <div className="d-flex ml16">
                                 <button className="editServiceBtn d-flex" onClick={(e) => setComState(state => ({ ...state, serviceCatId: item?.service_category_id, show: !comState.show, data: item?.service_offer_data }))}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>{item?.name} </AppText>
                                    {comState.serviceCatId && comState.show && comState.serviceCatId === item?.service_category_id ?
                                       <img src={trans} /> :
                                       <img src={servicearrowdown} />}
                                 </button>
                              </div>
                           </div>
                           <div className="col-2">
                              <div className="d-flex">
                                 <button className="editServiceBtn d-flex" type="button" onClick={() => setNewService({ status: true, data: item })}><AppText color="#fff" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>Create Service </AppText>
                                    <img src={serviceplus} />
                                 </button>
                              </div>
                           </div>
                           <div className="col-2">
                              <div className="d-flex myspaceRounded">
                                 <div className="form-check form-switch goML801">
                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={item?.status === 1 ? true : false} onClick={(e) => {
                                       e.preventDefault()
                                       setAlert({
                                          type: "info",
                                          title: "Confirmation",
                                          message: "Are you sure you want to update this service?",
                                          onCancel: () => { },
                                          onConfirm: () => {
                                             let nStatus = item?.status === 1 ? '2' : '1';
                                             updateSpecialtyStatus(item?.name, nStatus, item?.service_category_id)
                                          }
                                       } as AppAlertData)
                                    }} />
                                 </div>
                                 <div className="cursor-pointer cursor-hover">
                                    <img src={serviceedit} onClick={() => setUpdateSpecialty({ status: true, data: item })} />
                                 </div>
                                 <div className="cursor-pointer cursor-hover">
                                    <img src={servicedelete} onClick={(e) => {
                                       e.preventDefault()
                                       setAlert({
                                          type: "info",
                                          title: "Confirmation",
                                          message: "Are you sure you want to delete?",
                                          onCancel: () => { },
                                          onConfirm: () => { deleteSpecialty(item?.service_category_id) }
                                       } as AppAlertData)
                                    }} />
                                 </div>
                              </div>
                           </div>
                           <div className="mt-4">
                              {comState.serviceCatId && comState.show && comState.serviceCatId === item?.service_category_id &&
                                 (item?.service_offer_data || []).map((itemX: any, j: any) => (
                                    <div className="row mb-4" key={j}>
                                       <div className="col-8">
                                          <div className="d-flex ml16">
                                             <AppText color="#000" fontWeight="500" fontSize={'16px'} letterSpacing={'0.2px'}>{itemX?.name} </AppText>

                                          </div>
                                       </div>
                                       <div className="col-2"></div>
                                       <div className="col-2">
                                          <div className="d-flex myspaceRounded">
                                             <div className="form-check form-switch goML801">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={itemX?.status === 1 ? true : false}
                                                   onClick={(e) => {
                                                      e.preventDefault()
                                                      setAlert({
                                                         type: "info",
                                                         title: "Confirmation",
                                                         message: "Are you sure you want to turn off this service?",
                                                         onCancel: () => { },
                                                         onConfirm: () => {
                                                            let nStatus = itemX?.status === 1 ? '2' : '1';
                                                            // updateSpecialtyStatus(item?.name, nStatus, item?.service_category_id)
                                                            updateServicesStatus(itemX?.name, item?.service_category_id, itemX?.service_offer_id, nStatus)
                                                         }
                                                      } as AppAlertData)
                                                   }} />
                                             </div>
                                             <div className="cursor-pointer cursor-hover">
                                                <img src={serviceedit} onClick={() => setUpdateService({ status: true, data: itemX })} />
                                             </div>
                                             <div className="cursor-pointer cursor-hover">
                                                <img src={servicedelete} onClick={(e) => {
                                                   e.preventDefault()
                                                   setAlert({
                                                      type: "info",
                                                      title: "Confirmation",
                                                      message: "Are you sure you want to delete?",
                                                      onCancel: () => { },
                                                      onConfirm: () => { deleteService(itemX?.service_offer_id) }
                                                   } as AppAlertData)
                                                }} />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                           </div>
                        </div>
                     ))}

                     {newSpecialty &&
                        <SpecialtyPopup onPress={() => setNewSpecialty(false)} />
                     }
                     {updateSpecialty.status &&
                        <SpecialtyUpdate onPress={() => setUpdateSpecialty({ status: false, data: {} })} data={updateSpecialty.data} />
                     }
                     {updateService.status &&
                        <SpecialtyServiceUpdate onPress={() => setUpdateService({ status: false, data: {} })} data={updateService.data} />
                     }
                     {newService.status &&
                        <SpecialtyService onPress={() => setNewService({ status: false, data: {} })} data={newService?.data} />
                     }

                  </div>
               </div>
            </div>
         </div>
      </AppLayout>

   )
}