import { KeyValueObject } from "../typings/general";

export const TransactionProfileSubValues = {
  transaction: "transaction",
  withdrawals: "withdrawals",
};

export const TransactionProfileSubScreen: KeyValueObject[] = [
  { name: "Transactions", value: TransactionProfileSubValues.transaction },
  { name: "Withdrawals", value: TransactionProfileSubValues.withdrawals },
];

export const DepositStatus = {
  Today: "Today",
  All: "All",
  Pending: "Pending",
  Approved: "Approved",
  Declined: "Declined",
};
export const TransactionDepositStatus: KeyValueObject[] = [
  { name: "All", value: DepositStatus.All },
  { name: "Today", value: DepositStatus.Today },
  { name: "Pending", value: DepositStatus.Pending },
  { name: "Approved", value: DepositStatus.Approved },
  { name: "Declined", value: DepositStatus.Declined },
];

export const WithdrawalStatus = {
  All: "All",
  Pending: "Pending",
  Approved: "Approved",
  Declined: "Declined",
};
export const TransactionWithdrawalStatus: KeyValueObject[] = [
  { name: "All", value: WithdrawalStatus.All },
  { name: "Pending", value: WithdrawalStatus.Pending },
  { name: "Approved", value: WithdrawalStatus.Approved },
  { name: "Declined", value: WithdrawalStatus.Declined },
];

export const TypeStatus = {
  Withdrawal: "withdrawal",
  Deposit: "deposit",
  BookingPayment: "booking-payment",
};
export const TransactionTypeStatus: KeyValueObject[] = [
  { name: "Deposit", value: TypeStatus.Deposit },
  { name: "Withdrawal", value: TypeStatus.Withdrawal },
  { name: "Booking Payment", value: TypeStatus.BookingPayment },
];
