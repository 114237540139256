import AppTable from "../../components/AppTable";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppText from "../../components/AppText";
import useProviderFunction from "../../hooks/useProviderFunction";
import { useStoreSelector } from "../../store/useStore";
import helpers from "../../utils/helpers";

export default () => {
   const params = useParams();
   const { fetchSingleProvider, updateUserStatus } = useProviderFunction()
   const { singleProviderData, isLoading } = useStoreSelector(["singleProviderData", "isLoading"])
   useEffect(() => {
      //if the user data has not been loaded
      if (singleProviderData?.auth_id) return
      fetchSingleProvider(params?.ID as any)
   }, [])
   return (
      <div className="myTable w-100 mb-4 container">
         <div className="d-flex spaceRounded borderBottomService">
            <div className='line14'>
               <h1 className="headerH1 mb-n2"><AppText fontSize={'24px'} color="#000" fontWeight="500" letterSpacing={'0.15px'}>Documents</AppText></h1>
            </div>
         </div>
         <div className="bookedTable">
            <div className="mt27 borderBottomProfile">
               <div className="mt20 educationTableHead with60">
                  <AppTable
                     data={singleProviderData.document_upload}
                     isLoading={isLoading}
                     headers={["#", "Type", "Action"]} tableTitle="">
                     {(singleProviderData.document_upload || []).map((item: any, i: any) => (
                        <tr className="table-tr" key={i}>
                           <td className="tdclass1"><img height="15px" width="15px" src={item?.url} /></td>
                           <td className="tdclass4 tableGrid">{helpers.toUpperCase(helpers.replaceUnderDash(item?.document_type))}</td>
                           <td className="tdclass1"><a href={item?.url} target="_blank">View</a></td>
                        </tr>
                     ))}
                  </AppTable>
               </div>
            </div>
         </div>
      </div>
   )
}