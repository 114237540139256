import { useState } from "react";
import AppText from "./AppText";

interface PageNavigation {
   titles: string[];
   onPress?: ((e: string) => void) | undefined;
   defaultActive?: string
}
export default (props: PageNavigation) => {
   const [activeTab, setActiveTab] = useState(props.defaultActive || '')
   return (
      <div className="innerNavigation2">
         <ul>
            {props.titles.map((item, i) => <li key={i} className={`cursor-pointer ${activeTab === item ? 'borderActive' : ''}`}
               onClick={() => {
                  setActiveTab(item)
                  typeof props.onPress === 'function' && props.onPress(item)
               }
               }>
               <AppText fontSize={'16px'} fontFamily="'Work Sans', sans-serif" color="#000" fontWeight="500">{item}</AppText></li>)}
         </ul>
      </div>
   )
}