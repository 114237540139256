import { useState, useEffect } from "react"
import AppLayout from "../../components/AppLayout"
import AppText from "../../components/AppText"
import PageHeader from "../../components/PageHeader"
import AppTable from "../../components/AppTable"
import * as SVGIcon from '../../assets/svg'
import BorderNavigation from "../../components/BorderNavigation"
import InnerNavigation from "../../components/InnerNavigation"
import PageHeaderBalance from "../../components/PageHeaderBalance"
import Searchfilter, { DropdownState } from "../../components/Searchfilter"
import { SessionStatus } from "../../constants/SessionSubScreen"
import helpers from "../../utils/helpers"
import SessionModal from "../../components/SessionModal"
import useSessionFunction from "../../hooks/useSessionFunction"
import { useStoreSelector } from "../../store/useStore"
import Pagination from "../../components/Pagination"
import { useNavigate } from "react-router-dom"
export default () => {
   const Navigate = useNavigate()
   const [dropMe, ShowDropMe] = useState<DropdownState>({ show: false })
   const [filter, setFilter] = useState({ data1: '', startDate: '', endDate: '', q: '' })
   const [activePage, setActivePage] = useState(1)
   const [sessionModal, setSessionModal] = useState({ status: false, data: {} })
   const [dataArray, setDataArray] = useState<{ isLoading: boolean, status: any, data: Array<any> }>({ isLoading: false, status: '', data: [] })
   const { fetchSession, fetchSessionCount } = useSessionFunction()
   const { sessionData, isLoading, sessionPageCount } = useStoreSelector(["sessionData", "isLoading", "sessionPageCount"])
   useEffect(() => {
      fetchSession()
      fetchSessionCount()
   }, [])
   // console.log(sessionPageCount[0])
   return (
      <AppLayout active="sessions">
         <div className="">
            <div className="mb-3">
               <AppText fontSize={'24px'} color="#19191B" fontWeight="700">Sessions</AppText>
            </div>
            <div className="row mb-4">
               <div className="col-12">
                  {/* <PageHeaderBalance /> */}
                  <div>
                     <Searchfilter placeHolder="Seach with booking ID" onSearch={(e) => {
                        setFilter(e)
                        fetchSession(e?.data1, e?.startDate, e?.endDate, e.q)
                     }} show={dropMe.show} title1="Status" data1={SessionStatus}
                        onFilterClick={() => ShowDropMe(state => ({ ...state, show: !state.show, data1: SessionStatus, title1: 'Status' }))} onSelect={(e) => {
                           setFilter(e)
                           ShowDropMe(state => ({ ...state, show: !state.show }))
                           fetchSession(e?.data1, e?.startDate, e?.endDate, e?.q)
                           fetchSessionCount(e?.data1, 1, e?.startDate, e?.endDate, e?.q)
                        }
                        } />
                  </div>
                  <div className="bg-white newHeight">
                     <div className="myTableHeight">
                        <AppTable data={sessionData} isLoading={isLoading} headers={["Member", "Provider", "Amount Paid", "Date", "Time", "Booking ID", "Status"]} tableTitle="">
                           {(sessionData || []).map((item: any, i: any) => (
                              <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => Navigate(`/sessions/${item?.booking_id}`)}>
                                 {/* <tr className="table-tr cursor-pointer cursor-hover" key={i} onClick={() => setSessionModal({ status: true, data: item })}> */}
                                 <td className="tdclass4 tableGrid">{item?.member_data?.name}</td>
                                 <td className="tdclass4">{item?.provider_data?.name}</td>
                                 <td className="tdclass4">{helpers.getNairaSign() + helpers.formatMoney(item?.amount || 0.00)}</td>
                                 <td className="tdclass1">{new Date(item.createdAt).toDateString()}</td>
                                 <td className="tdclass1">{helpers.get24HourTo12Hours((item.createdAt))}</td>
                                 {/* <td className="tdclass3">{(item.comm_mode || []).map((data: any, i: any) => (<span>{i !== 0 && ', '}{data}</span>))}</td> */}
                                 <td className="tdclass4">{item?.booking_ref}</td>
                                 <td className="tdclass3">{<span className={`marginBottom profileActive ${item?.status === 1 ? "userActive" : item?.status === 2 ? "userReview" : item?.status === 3 ? "userCompleted" : item?.status === 4 ? "userReview" : "userPending"}`}>{item?.status === 1 ? "Paid" : item?.status === 2 ? "Live" : item?.status === 3 ? "Completed" : item?.status === 4 ? "Cancelled" : "Pending"}</span>}</td>
                              </tr>
                           ))}
                        </AppTable>
                     </div>
                     <Pagination activePage={activePage} totalPage={sessionPageCount?.total} onPress={(nextPage) => {
                        fetchSession(filter?.data1, '', '', '', nextPage)
                        setActivePage(nextPage)
                     }} />
                  </div>
               </div>
            </div>
            {sessionModal.status &&
               <SessionModal data={sessionModal.data} onPress={() => setSessionModal({ status: false, data: {} })} />
            }
            <div className="mb-4">

            </div>
         </div>
      </AppLayout>

   )
}