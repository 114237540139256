import { KeyValueObject } from "../typings/general";

export const SupportProfileSubValues = {
  member: "member",
  provider: "provider",
};

export const SupportProfileSubScreen: KeyValueObject[] = [
  { name: "Member", value: SupportProfileSubValues.member },
  { name: "Provider", value: SupportProfileSubValues.provider },
];

export const Status = {
  Ongoing: "Ongoing",
  Resolved: "Resolved",
};
export const SupportStatus: KeyValueObject[] = [
  { name: "Ongoing", value: Status.Ongoing },
  { name: "Resolved", value: Status.Resolved },
];
